import {IOneCdata} from "../types/models";

const demoData: IOneCdata = {
    "clinics": [
        {
            "uid": "f679444a-22b7-11df-8618-002618dcef2c",
            "name": "Центральная клиника"
        },
        {
            "uid": "66abf7b4-2ff9-11df-8625-002618dcef2c",
            "name": "Третий центр"
        }
    ],
    "employees": {
        "2eb1f97b-6a3c-11e9-936d-1856809fe650": {
            "name": "Юрий",
            "surname": "Безногов",
            "middleName": "Сергеевич",
            "clinic": "Центральная клиника",
            "inSchedule": true,
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "specialties": {
                "ce182405-5065-11e4-8cb1-c80aa974ec9e": {
                    "name": "Хирургия"
                }
            },
            "services": {
                "dc58bfa1-65b4-11e9-936d-1856809fe650": {
                    "title": "Повторная консультация хирурга "
                },
                "dc58bfa3-65b4-11e9-936d-1856809fe650": {
                    "title": "Удаление электроножом ганглия"
                },
                "dc58bfb4-65b4-11e9-936d-1856809fe650": {
                    "title": "Склеротерапия наружных геморроидальных узлов при хроническом наружном геморрое 1-2 ст. без учета стоимости лекарственного средства"
                },
                "dc58bfae-65b4-11e9-936d-1856809fe650": {
                    "title": "Перевязка неинфицированной раны большая"
                },
                "dc58bfb8-65b4-11e9-936d-1856809fe650": {
                    "title": "Повторная консультация маммолога "
                },
                "5210c9dc-65a2-11e9-936d-1856809fe650": {
                    "title": "Наложение косметических швов на одну нить без учета анестезии"
                },
                "dc58bfaf-65b4-11e9-936d-1856809fe650": {
                    "title": "Перевязка инфицированной раны малая"
                },
                "dc58bfb0-65b4-11e9-936d-1856809fe650": {
                    "title": "Перевязка инфицированной раны большая"
                },
                "dc58bfb2-65b4-11e9-936d-1856809fe650": {
                    "title": "Пункционная микросклеротерапия без учета стоимости лекарственного средства"
                },
                "dc58bfa2-65b4-11e9-936d-1856809fe650": {
                    "title": "Удаление скальпелем ганглия"
                },
                "dc58bfa5-65b4-11e9-936d-1856809fe650": {
                    "title": "Удаление вросшего ногтя без учета анестезии"
                },
                "dc58bfa7-65b4-11e9-936d-1856809fe650": {
                    "title": "Лечение панариция (паранихия) без учета анестезии"
                },
                "dc58bfb3-65b4-11e9-936d-1856809fe650": {
                    "title": "Микросклеротерапия без учета стоимости лекарственного средства"
                },
                "dc58bfb1-65b4-11e9-936d-1856809fe650": {
                    "title": "Консультация хирурга-флеболога"
                },
                "dc58bfb7-65b4-11e9-936d-1856809fe650": {
                    "title": "Первичная консультация маммолога "
                },
                "dc58bfa8-65b4-11e9-936d-1856809fe650": {
                    "title": "Лечение фурункула, абсцесса без учета анестезии"
                },
                "dc58bfaa-65b4-11e9-936d-1856809fe650": {
                    "title": "Удаление инородного тела без учета анестезии"
                },
                "dc58bfad-65b4-11e9-936d-1856809fe650": {
                    "title": "Перевязка неинфицированной раны малая"
                },
                "dc58bfa6-65b4-11e9-936d-1856809fe650": {
                    "title": "Лечение вросшего ногтя в фазе воспаления без учета анестезии"
                },
                "dc58bfa0-65b4-11e9-936d-1856809fe650": {
                    "title": "Первичная консультация хирурга "
                }
            }
        },
        "99868528-0928-11dc-93d1-0004614ae652": {
            "name": "Дмитрий",
            "surname": "Денисов",
            "middleName": "Алексеевич",
            "clinic": "Центральная клиника",
            "inSchedule": true,
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "specialties": {
                "e866ea50-093d-11e2-87b2-002618dcef2c": {
                    "name": "Лабораторные исследования"
                },
                "ce182405-5065-11e4-8cb1-c80aa974ec9e": {
                    "name": "Хирургия"
                },
                "91d8a3f0-d7cf-11e1-bab2-1803736d59cd": {
                    "name": "Терапия"
                }
            },
            "services": {
                "1e86b945-0b31-11e4-87f8-002618dcef2c": {
                    "title": "Паллестезиометрия"
                },
                "eb20edcd-3ee0-11de-b0dd-0050bf5d92cb": {
                    "title": "Повторная консультация"
                },
                "1056e9f7-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Реовазография сосудов конечностей"
                },
                "3252cea6-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Остеоденситометрия длинных трубчатых костей"
                },
                "e43985b0-6a46-11e4-8810-002618dcef2c": {
                    "title": "Сеанс лечения устнановкой Planmeca Sovereign "
                },
                "1e86b94d-0b31-11e4-87f8-002618dcef2c": {
                    "title": "Термометрия"
                },
                "3252ceb1-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Электрокардиография"
                },
                "eb20edcc-3ee0-11de-b0dd-0050bf5d92cb": {
                    "title": "Первичная консультация"
                },
                "5210c9cc-65a2-11e9-936d-1856809fe650": {
                    "title": "Первичная консультация терапевта "
                },
                "37382f1e-d6dd-11e1-b361-1803736d59cd": {
                    "title": "Снимок челюсти"
                },
                "5210c9ce-65a2-11e9-936d-1856809fe650": {
                    "title": "Вызов на дом терапевта"
                },
                "1e86b953-0b31-11e4-87f8-002618dcef2c": {
                    "title": "Холодовая проба"
                },
                "1e86b93b-0b31-11e4-87f8-002618dcef2c": {
                    "title": "Динамометрия"
                },
                "1056e9fe-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Биомикроскопия переднего отрезка глаза"
                },
                "dc58bfe2-65b4-11e9-936d-1856809fe650": {
                    "title": "Массаж 30 мин "
                },
                "8e045f06-da18-11e1-bab2-1803736d59cd": {
                    "title": "Прогревание свечой"
                },
                "5210c9cd-65a2-11e9-936d-1856809fe650": {
                    "title": "Повторная консультация терапевта "
                },
                "dc58bfe4-65b4-11e9-936d-1856809fe650": {
                    "title": "Массаж лица 15 минут"
                },
                "91d8a3f1-d7cf-11e1-bab2-1803736d59cd": {
                    "title": "Электрофарез"
                },
                "dc58bfe3-65b4-11e9-936d-1856809fe650": {
                    "title": "Массаж 60 минут"
                }
            }
        },
        "e7005e6f-65c9-11e9-936d-1856809fe650": {
            "name": "Семен",
            "surname": "Малоухов",
            "middleName": "Семенович",
            "clinic": "Центральная клиника",
            "inSchedule": true,
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "specialties": {
                "ca04032a-9f39-11e6-8221-985fd33a0f52": {
                    "name": "Сурдология"
                },
                "eebe3e98-233d-11e2-9496-1803736d59cd": {
                    "name": "Оториноларингология"
                }
            },
            "services": {
                "ca040339-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Удаление серной пробки из одного уха"
                },
                "ca040335-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Подбор слухового аппарата"
                },
                "ce96f496-2340-11e2-9496-1803736d59cd": {
                    "title": "Промывание гайморовых пазух"
                },
                "ca04033a-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Туалет одного уха"
                },
                "ca04033c-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Санация лакун небных миндалин"
                },
                "ca04034f-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Туалет носа с использованием ЛОР-установки EUROPA-MODULA"
                },
                "ca040354-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Осмотр врача-оториноларинголога в процессе лечения"
                },
                "1056e9f4-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Исследование функции вестибулярного аппарата"
                },
                "dc58bfd9-65b4-11e9-936d-1856809fe650": {
                    "title": "Назальный душ"
                },
                "ce96f497-2340-11e2-9496-1803736d59cd": {
                    "title": "Осмотр отоларинголога"
                },
                "ca04032b-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Консультация врача-сурдолога"
                },
                "ca040338-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Продувание слуховых труб по Политцеру"
                },
                "ca04033b-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Туалет уха с введением лекарственных препаратов в наружный слуховой проход"
                },
                "ca04032c-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Консультация врача-оториноларинголога"
                },
                "ca040337-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Пневмомассаж барабанных перепонок"
                },
                "ca04032e-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Импедансометрия"
                },
                "ca04032f-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Консультация врача-оториноларинголога повторная"
                },
                "ca040333-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Снятие тональной аудиограммы"
                },
                "ca040334-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Аудиометрия"
                },
                "ca040336-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Настройка слухового аппарата"
                }
            }
        },
        "e7005e6d-65c9-11e9-936d-1856809fe650": {
            "name": "Наталья",
            "surname": "Красильникова",
            "middleName": "Семеновна",
            "clinic": "Центральная клиника",
            "inSchedule": true,
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "specialties": {
                "ce1823fd-5065-11e4-8cb1-c80aa974ec9e": {
                    "name": "Дерматология и косметология"
                }
            },
            "services": {
                "dc58bff5-65b4-11e9-936d-1856809fe650": {
                    "title": " Липомассаж тела на аппарате LPG Cellu M6"
                },
                "dc58bff4-65b4-11e9-936d-1856809fe650": {
                    "title": "Лифтинг массаж лица на аппарате LPG Cellu M6"
                },
                "dc58bff3-65b4-11e9-936d-1856809fe650": {
                    "title": "Маска по типу кожи"
                },
                "dc58bff2-65b4-11e9-936d-1856809fe650": {
                    "title": "Маска альгинатная"
                },
                "dc58bff1-65b4-11e9-936d-1856809fe650": {
                    "title": "Антивозрастная программа "
                },
                "dc58bfed-65b4-11e9-936d-1856809fe650": {
                    "title": " Мезотерапия Mesoline tight 5 мл"
                },
                "dc58bfea-65b4-11e9-936d-1856809fe650": {
                    "title": "Чистка лица ручная  "
                },
                "dc58bfe9-65b4-11e9-936d-1856809fe650": {
                    "title": "Ультразвуковая чистка лица "
                },
                "dc58bff0-65b4-11e9-936d-1856809fe650": {
                    "title": "Уход за жирной кожей, склонной к воспалениям"
                },
                "dc58bfec-65b4-11e9-936d-1856809fe650": {
                    "title": "Мезотерапия Эмбриобласт 3мл (Филорга, Франция)"
                },
                "dc58bfee-65b4-11e9-936d-1856809fe650": {
                    "title": "Уход за чувствительной кожей, склонной к покраснению и развитию купероза"
                },
                "5210c9db-65a2-11e9-936d-1856809fe650": {
                    "title": "Пункция новообразований "
                },
                "dc58bfe8-65b4-11e9-936d-1856809fe650": {
                    "title": "Чистка лица комбинированная (ультразвуковая + ручная)"
                },
                "dc58bfe6-65b4-11e9-936d-1856809fe650": {
                    "title": "Пилинг ретиноевый (желтый) крем и гель"
                },
                "dc58bfe5-65b4-11e9-936d-1856809fe650": {
                    "title": "Пилинг TCA 15% (трихлоруксусная кислота)"
                },
                "dc58bfe7-65b4-11e9-936d-1856809fe650": {
                    "title": "Пилинг миндальный"
                },
                "dc58bfd3-65b4-11e9-936d-1856809fe650": {
                    "title": "Соскоб клеща демодекс"
                },
                "dc58bfd2-65b4-11e9-936d-1856809fe650": {
                    "title": "Соскоб материала на анализ (кожа,ногти)"
                },
                "dc58bfd1-65b4-11e9-936d-1856809fe650": {
                    "title": "Забор мазка на анализ"
                },
                "dc58bfcf-65b4-11e9-936d-1856809fe650": {
                    "title": "Первичный консультация дерматовенеролога "
                },
                "dc58bfef-65b4-11e9-936d-1856809fe650": {
                    "title": "Уход за сухой обезвоженной кожей"
                },
                "dc58bfd0-65b4-11e9-936d-1856809fe650": {
                    "title": "Повторная консультация дерматовенеролога "
                },
                "dc58bfeb-65b4-11e9-936d-1856809fe650": {
                    "title": "Мезотерапия Dermaheal SR  2.5 мл"
                }
            }
        },
        "eab46ee9-94b1-11e3-87ec-002618dcef2c": {
            "name": "Валентина",
            "surname": "Сенникова",
            "middleName": "Семёновна",
            "clinic": "Третий центр",
            "inSchedule": true,
            "clinicUid": "66abf7b4-2ff9-11df-8625-002618dcef2c",
            "specialties": {
                "5a2c7f78-9fd1-11e3-9481-1078d2d69bb4": {
                    "name": "Эндоскопия"
                },
                "5210c9c1-65a2-11e9-936d-1856809fe650": {
                    "name": "Кардиология"
                }
            },
            "services": {
                "5210c9d5-65a2-11e9-936d-1856809fe650": {
                    "title": "Электрокардиограмма"
                },
                "5210c9d4-65a2-11e9-936d-1856809fe650": {
                    "title": "Повторная консультация кардиолога "
                },
                "5210c9d3-65a2-11e9-936d-1856809fe650": {
                    "title": "Первичная консультация кардиолога "
                }
            }
        },
        "22582080-c3d7-11e9-9380-50af73235947": {
            "name": "Елена",
            "surname": "Никонова",
            "middleName": "Павловна",
            "clinic": "",
            "clinicUid": "",
            "inSchedule": true,
            "specialties": {
                "ce1823fc-5065-11e4-8cb1-c80aa974ec9e": {
                    "name": "Гинекология"
                }
            },
            "services": {
                "f73371dd-57eb-11ec-9bc9-c03eba27318f": {
                    "title": "Первичная консультация гинеколога",
                    "personalDuration": 2820
                }
            }
        },
        "9e8b672a-9975-11e3-87ec-002618dcef2c": {
            "name": "Юлия",
            "surname": "Скрипко",
            "middleName": "Александровна",
            "clinic": "Центральная клиника",
            "inSchedule": true,
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "specialties": {
                "5a2c7f7a-9fd1-11e3-9481-1078d2d69bb4": {
                    "name": "Неврология"
                }
            },
            "services": {
                "e22de3a5-6fc1-11e9-936d-1856809fe650": {
                    "title": "Массаж при заболеваниях центральной нервной системы"
                },
                "5210c9d0-65a2-11e9-936d-1856809fe650": {
                    "title": "Повторная консультация невролога "
                },
                "5210c9cf-65a2-11e9-936d-1856809fe650": {
                    "title": "Первичная консультация невролога"
                },
                "6773dc51-67dc-11e9-936d-1856809fe650": {
                    "title": "Реабилитация больного, перенесшего острое нарушение мозгового кровообращения"
                },
                "5210c9d2-65a2-11e9-936d-1856809fe650": {
                    "title": "Паравертебральная блокада"
                },
                "5210c9d1-65a2-11e9-936d-1856809fe650": {
                    "title": "Рефлексотерапия при заболеваниях ЦНС"
                }
            }
        },
        "6cf96534-3870-11e2-87ba-002618dcef2c": {
            "name": "Ирина",
            "surname": "Ковалёва",
            "middleName": "Михайловна",
            "clinic": "Центральная клиника",
            "inSchedule": true,
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "specialties": {
                "5210c9c1-65a2-11e9-936d-1856809fe650": {
                    "name": "Кардиология"
                }
            },
            "services": {
                "5210c9d5-65a2-11e9-936d-1856809fe650": {
                    "title": "Электрокардиограмма"
                },
                "5210c9d3-65a2-11e9-936d-1856809fe650": {
                    "title": "Первичная консультация кардиолога "
                },
                "5210c9d4-65a2-11e9-936d-1856809fe650": {
                    "title": "Повторная консультация кардиолога "
                },
                "6773dc52-67dc-11e9-936d-1856809fe650": {
                    "title": "Школа для больных с сердечной недостаточностью"
                }
            }
        },
        "ac30e13a-3087-11dc-8594-005056c00008": {
            "name": "Евгения",
            "surname": "Барбышева",
            "middleName": "Петровна",
            "clinic": "Центральная клиника",
            "inSchedule": true,
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "specialties": {
                "785d8a6e-5c72-11dd-8423-005056c00008": {
                    "name": "Офтальмология"
                }
            },
            "services": {
                "1056ea05-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Тонометрия"
                },
                "1e86b944-0b31-11e4-87f8-002618dcef2c": {
                    "title": "Исследование объема аккомодации"
                },
                "1056ea06-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Исследование периметрии"
                },
                "1e86b94a-0b31-11e4-87f8-002618dcef2c": {
                    "title": "Рефрактометрия"
                },
                "1e86b94c-0b31-11e4-87f8-002618dcef2c": {
                    "title": "Скиаскопия"
                },
                "eb20edce-3ee0-11de-b0dd-0050bf5d92cb": {
                    "title": "Биомикроскопия "
                },
                "eb20edcf-3ee0-11de-b0dd-0050bf5d92cb": {
                    "title": "Компьютерная периметрия (центр)"
                },
                "1056e9dd-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Биомикроскопия сред глаза"
                },
                "1056ea04-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Биомикроскопия переднего отрезка и хрусталика глаза"
                },
                "3813e2ac-69d5-11e9-936d-1856809fe650": {
                    "title": "Первичная консультация офтальмолога"
                },
                "1056e9de-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Цветоощущение"
                },
                "eb20edcd-3ee0-11de-b0dd-0050bf5d92cb": {
                    "title": "Повторная консультация"
                },
                "1056e9d3-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Спирометрия"
                },
                "1e86b93c-0b31-11e4-87f8-002618dcef2c": {
                    "title": "Исследование бинокулярного зрения"
                },
                "1056e9d1-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Исследование поля зрения"
                },
                "1056e9d0-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "ЭНМГ"
                },
                "eb20edcc-3ee0-11de-b0dd-0050bf5d92cb": {
                    "title": "Первичная консультация",
                    "personalDuration": 1800
                },
                "6d9fb0ca-f27c-11e5-827e-40167e631f2e": {
                    "title": "Изготовление очков"
                },
                "1056e9cd-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Офтальмоскопия глазного дна"
                },
                "3813e2ad-69d5-11e9-936d-1856809fe650": {
                    "title": "Повторная консультация офтальмолога"
                },
                "8c9d2b7b-b3cf-11de-8172-001583078ee5": {
                    "title": "Тренировка отрицательной аккомодации "
                },
                "1056e9d2-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Исследование остроты зрения"
                },
                "3252ceae-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Спирометрия с бронходилятационной пробой"
                },
                "22d1b486-b34b-11de-8171-001583078ee5": {
                    "title": "Исследование цветового зрения"
                },
                "22d1b485-b34b-11de-8171-001583078ee5": {
                    "title": "Подбор очков сложной коррекции "
                },
                "19dc630a-d9c4-11e7-82a6-40167e631f2e": {
                    "title": "Курс массажа век 5 сеансов"
                },
                "13d1be68-c715-11df-86cd-002618dcef2c": {
                    "title": "УЗД Хрусталика"
                },
                "22d1b484-b34b-11de-8171-001583078ee5": {
                    "title": "Подбор очков (простая сферическая коррекция)"
                },
                "a0230570-3ef7-11de-8086-001485c0d477": {
                    "title": "Массаж век"
                }
            }
        },
        "eab46ee6-94b1-11e3-87ec-002618dcef2c": {
            "name": "Мария",
            "surname": "Филатова",
            "middleName": "Сергеевна",
            "clinic": "Третий центр",
            "inSchedule": true,
            "clinicUid": "66abf7b4-2ff9-11df-8625-002618dcef2c",
            "specialties": {
                "37382f1d-d6dd-11e1-b361-1803736d59cd": {
                    "name": "Стоматология"
                }
            },
            "services": {
                "ad5134a7-42f3-11e4-8253-0cd29229b33b": {
                    "title": "Закрытый («мягкий») синуслифтинг"
                },
                "ad5134a5-42f3-11e4-8253-0cd29229b33b": {
                    "title": "Пластика альвеолярного гребня челюсти (подготовительная операция для установки и"
                },
                "ad51349c-42f3-11e4-8253-0cd29229b33b": {
                    "title": "Периостеотомия"
                },
                "6eac2b59-3104-11e3-8b75-80c16e5c9fe3": {
                    "title": "Распломбировка одного корневого канала"
                },
                "df314a38-e476-11e2-bce7-80c16e5c9fe3": {
                    "title": "Пломбирование зуба"
                },
                "6eac2b58-3104-11e3-8b75-80c16e5c9fe3": {
                    "title": "Первичный прием стоматолога"
                },
                "ad5134a4-42f3-11e4-8253-0cd29229b33b": {
                    "title": "Секвестрэктомия"
                },
                "df314a36-e476-11e2-bce7-80c16e5c9fe3": {
                    "title": "Лечение кариеса"
                },
                "a3324608-d6de-11e1-b361-1803736d59cd": {
                    "title": "Лечение пульпита"
                }
            }
        },
        "ac30e139-3087-11dc-8594-005056c00008": {
            "name": "Ксения",
            "surname": "Иванова",
            "middleName": "Андреевна",
            "clinic": "Центральная клиника",
            "inSchedule": true,
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "specialties": {
                "e866ea50-093d-11e2-87b2-002618dcef2c": {
                    "name": "Лабораторные исследования"
                },
                "3252ceb7-fc4b-11e3-87f5-002618dcef2c": {
                    "name": "Диагностика"
                }
            },
            "services": {
                "3252ceac-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "УЗИ органов малого таза"
                },
                "1056e9e4-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "УЗИ органов брюшной полости и почек"
                },
                "1056e9e2-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Рентгенография придаточных пазух носа"
                },
                "1056e9f8-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "УЗИ щитовидной железы"
                },
                "3252cea4-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "УЗИ почек и мочевыделительной системы"
                },
                "1e86b948-0b31-11e4-87f8-002618dcef2c": {
                    "title": "Рентгенографическое исследование околоносовых пазух"
                },
                "1056e9d6-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Рентгенография грудной клетки в двух проекциях"
                },
                "1056e9e5-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Рентгенография трубчатых костей"
                },
                "1056e9f6-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "УЗИ брюшной полости"
                },
                "1056e9e0-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "УЗИ почек и мочевыводящих путей"
                },
                "1056e9db-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "УЗИ внутренних органов"
                },
                "d08a79a1-6b43-11e9-936d-1856809fe650": {
                    "title": "УЗИ глазного яблока"
                },
                "3252cead-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "УЗИ  органов-мишеней"
                },
                "3252ceb6-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "УЗИ молочных желез"
                },
                "1e86b949-0b31-11e4-87f8-002618dcef2c": {
                    "title": "Рентгенография суставов, позвоночника"
                },
                "3252cea5-fc4b-11e3-87f5-002618dcef2c": {
                    "title": "Рентгенография длинных трубчатых костей "
                },
                "1e86b932-0b31-11e4-87f8-002618dcef2c": {
                    "title": "a-HCV-IgG"
                }
            }
        },
        "d273f6d8-b037-11e8-82b2-40167e631f2e": {
            "name": "Елена",
            "surname": "Крылатова",
            "middleName": "Геннадиевна",
            "clinic": "Центральная клиника",
            "inSchedule": true,
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "specialties": {
                "ce182402-5065-11e4-8cb1-c80aa974ec9e": {
                    "name": "Ортопедия и травматология"
                },
                "ca04032a-9f39-11e6-8221-985fd33a0f52": {
                    "name": "Сурдология"
                }
            },
            "services": {
                "dc58bfb9-65b4-11e9-936d-1856809fe650": {
                    "title": "Первичная консультация травматолога "
                },
                "dc58bfbe-65b4-11e9-936d-1856809fe650": {
                    "title": "Наложение полиуретановой повязки малой (кисть-предплечье, стопа-голень) без учета расходного материала"
                },
                "dc58bfbf-65b4-11e9-936d-1856809fe650": {
                    "title": "Наложение полиуретановой повязки большой (голень-бедро, плечо-предплечье, корсет) без учета расходного материала"
                },
                "dc58bfc0-65b4-11e9-936d-1856809fe650": {
                    "title": "Наложение повязки Турбокаст (без стоимости повязки)"
                },
                "dc58bfbc-65b4-11e9-936d-1856809fe650": {
                    "title": "Наложение гипсовой повязки малой (кисть-предплечье, стопа-голень) без учета расходного материала"
                },
                "dc58bfce-65b4-11e9-936d-1856809fe650": {
                    "title": "Первичная хирургическая обработка раны"
                },
                "dc58bfbd-65b4-11e9-936d-1856809fe650": {
                    "title": "Наложение гипсовой повязки большой (голень-бедро, плечо-предплечье, корсет) без учета расходного материала"
                },
                "dc58bfcb-65b4-11e9-936d-1856809fe650": {
                    "title": "Местная анестезия без учета лекарственного средства"
                },
                "dc58bfcc-65b4-11e9-936d-1856809fe650": {
                    "title": "Закрытая репозиция отломков при переломах"
                },
                "dc58bfcd-65b4-11e9-936d-1856809fe650": {
                    "title": "Закрытое вправление вывихов в суставах"
                },
                "ca040353-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Осмотр врача-сурдолога в процессе лечения"
                },
                "ca040333-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Снятие тональной аудиограммы"
                },
                "ca04033a-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Туалет одного уха"
                },
                "ca04032b-9f39-11e6-8221-985fd33a0f52": {
                    "title": "Консультация врача-сурдолога"
                },
                "dc58bfba-65b4-11e9-936d-1856809fe650": {
                    "title": "Повторная консультация травматолога "
                }
            }
        }
    },
    "nomenclature": {
        "df314a38-e476-11e2-bce7-80c16e5c9fe3": {
            "name": "Пломбирование зуба",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Стоматология",
            "specialtyUid": "37382f1d-d6dd-11e1-b361-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                }
            }
        },
        "df314a36-e476-11e2-bce7-80c16e5c9fe3": {
            "name": "Лечение кариеса",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Стоматология",
            "specialtyUid": "37382f1d-d6dd-11e1-b361-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                }
            }
        },
        "8e045f06-da18-11e1-bab2-1803736d59cd": {
            "name": "Прогревание свечой",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "270"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "91d8a3f1-d7cf-11e1-bab2-1803736d59cd": {
            "name": "Электрофарез",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "270"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "eb20edcd-3ee0-11de-b0dd-0050bf5d92cb": {
            "name": "Повторная консультация",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "900"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "a3324608-d6de-11e1-b361-1803736d59cd": {
            "name": "Лечение пульпита",
            "typeOfItem": "Услуга",
            "duration": 5400,
            "specialty": "Стоматология",
            "specialtyUid": "37382f1d-d6dd-11e1-b361-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 000"
                }
            }
        },
        "eb20edcc-3ee0-11de-b0dd-0050bf5d92cb": {
            "name": "Первичная консультация",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "900"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "60bed4a6-a4a8-11ea-95ac-54bef7811d01": {
            "name": "Вакцинация от эпидемического паротита",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Вакцинации",
            "specialtyUid": "70769f2c-8fa7-11ea-82cc-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "60bed484-a4a8-11ea-95ac-54bef7811d01": {
            "name": "Вакцинация от гриппа",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Вакцинации",
            "specialtyUid": "70769f2c-8fa7-11ea-82cc-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ce96f497-2340-11e2-9496-1803736d59cd": {
            "name": "Осмотр отоларинголога",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Оториноларингология",
            "specialtyUid": "eebe3e98-233d-11e2-9496-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "270"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "4e6af6f6-c4b7-11e9-93b7-50af7323357f": {
            "name": "Пребывание в стационаре, сутки, обычная палата",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Стационар",
            "specialtyUid": "4e6af6f4-c4b7-11e9-93b7-50af7323357f",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                }
            }
        },
        "6773dc51-67dc-11e9-936d-1856809fe650": {
            "name": "Реабилитация больного, перенесшего острое нарушение мозгового кровообращения",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Неврология",
            "specialtyUid": "5a2c7f7a-9fd1-11e3-9481-1078d2d69bb4",
            "artNumber": "В05.023.01",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "450"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "450"
                }
            }
        },
        "dc58bff5-65b4-11e9-936d-1856809fe650": {
            "name": " Липомассаж тела на аппарате LPG Cellu M6",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                }
            }
        },
        "dc58bff4-65b4-11e9-936d-1856809fe650": {
            "name": "Лифтинг массаж лица на аппарате LPG Cellu M6",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 200"
                }
            }
        },
        "dc58bff3-65b4-11e9-936d-1856809fe650": {
            "name": "Маска по типу кожи",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                }
            }
        },
        "dc58bff1-65b4-11e9-936d-1856809fe650": {
            "name": "Антивозрастная программа ",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                }
            }
        },
        "dc58bfee-65b4-11e9-936d-1856809fe650": {
            "name": "Уход за чувствительной кожей, склонной к покраснению и развитию купероза",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                }
            }
        },
        "dc58bfec-65b4-11e9-936d-1856809fe650": {
            "name": "Мезотерапия Эмбриобласт 3мл (Филорга, Франция)",
            "typeOfItem": "Услуга",
            "duration": 5400,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "6 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "6 500"
                }
            }
        },
        "dc58bfeb-65b4-11e9-936d-1856809fe650": {
            "name": "Мезотерапия Dermaheal SR  2.5 мл",
            "typeOfItem": "Услуга",
            "duration": 5400,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 000"
                }
            }
        },
        "dc58bfe8-65b4-11e9-936d-1856809fe650": {
            "name": "Чистка лица комбинированная (ультразвуковая + ручная)",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                }
            }
        },
        "dc58bfe7-65b4-11e9-936d-1856809fe650": {
            "name": "Пилинг миндальный",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                }
            }
        },
        "dc58bfe4-65b4-11e9-936d-1856809fe650": {
            "name": "Массаж лица 15 минут",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                }
            }
        },
        "dc58bfd9-65b4-11e9-936d-1856809fe650": {
            "name": "Назальный душ",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Оториноларингология",
            "specialtyUid": "eebe3e98-233d-11e2-9496-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "dc58bfd3-65b4-11e9-936d-1856809fe650": {
            "name": "Соскоб клеща демодекс",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "330"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "330"
                }
            }
        },
        "dc58bfd1-65b4-11e9-936d-1856809fe650": {
            "name": "Забор мазка на анализ",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "330"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "330"
                }
            }
        },
        "dc58bfd0-65b4-11e9-936d-1856809fe650": {
            "name": "Повторная консультация дерматовенеролога ",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "550"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "550"
                }
            }
        },
        "dc58bfce-65b4-11e9-936d-1856809fe650": {
            "name": "Первичная хирургическая обработка раны",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Ортопедия и травматология",
            "specialtyUid": "ce182402-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "1 800"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                }
            }
        },
        "dc58bfcc-65b4-11e9-936d-1856809fe650": {
            "name": "Закрытая репозиция отломков при переломах",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Ортопедия и травматология",
            "specialtyUid": "ce182402-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 000"
                }
            }
        },
        "dc58bfcb-65b4-11e9-936d-1856809fe650": {
            "name": "Местная анестезия без учета лекарственного средства",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Ортопедия и травматология",
            "specialtyUid": "ce182402-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                }
            }
        },
        "dc58bfca-65b4-11e9-936d-1856809fe650": {
            "name": "Снятие скоб",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Ортопедия и травматология",
            "specialtyUid": "ce182402-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                }
            }
        },
        "dc58bfc0-65b4-11e9-936d-1856809fe650": {
            "name": "Наложение повязки Турбокаст (без стоимости повязки)",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Ортопедия и травматология",
            "specialtyUid": "ce182402-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 100"
                }
            }
        },
        "3813e2ad-69d5-11e9-936d-1856809fe650": {
            "name": "Повторная консультация офтальмолога",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                }
            }
        },
        "dc58bfbf-65b4-11e9-936d-1856809fe650": {
            "name": "Наложение полиуретановой повязки большой (голень-бедро, плечо-предплечье, корсет) без учета расходного материала",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Ортопедия и травматология",
            "specialtyUid": "ce182402-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 100"
                }
            }
        },
        "dc58bfbd-65b4-11e9-936d-1856809fe650": {
            "name": "Наложение гипсовой повязки большой (голень-бедро, плечо-предплечье, корсет) без учета расходного материала",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Ортопедия и травматология",
            "specialtyUid": "ce182402-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                }
            }
        },
        "dc58bfbc-65b4-11e9-936d-1856809fe650": {
            "name": "Наложение гипсовой повязки малой (кисть-предплечье, стопа-голень) без учета расходного материала",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Ортопедия и травматология",
            "specialtyUid": "ce182402-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                }
            }
        },
        "dc58bfbb-65b4-11e9-936d-1856809fe650": {
            "name": "Вызов на дом травматолога",
            "typeOfItem": "Услуга",
            "duration": 7200,
            "specialty": "Ортопедия и травматология",
            "specialtyUid": "ce182402-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 750"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 750"
                }
            }
        },
        "dc58bfba-65b4-11e9-936d-1856809fe650": {
            "name": "Повторная консультация травматолога ",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Ортопедия и травматология",
            "specialtyUid": "ce182402-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                }
            }
        },
        "dc58bfb9-65b4-11e9-936d-1856809fe650": {
            "name": "Первичная консультация травматолога ",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Ортопедия и травматология",
            "specialtyUid": "ce182402-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "dc58bfb8-65b4-11e9-936d-1856809fe650": {
            "name": "Повторная консультация маммолога ",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": false,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                }
            }
        },
        "dc58bfb5-65b4-11e9-936d-1856809fe650": {
            "name": "Первичная консультация нейрохирурга ",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": false,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "dc58bfe6-65b4-11e9-936d-1856809fe650": {
            "name": "Пилинг ретиноевый (желтый) крем и гель",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 800"
                }
            }
        },
        "dc58bfb4-65b4-11e9-936d-1856809fe650": {
            "name": "Склеротерапия наружных геморроидальных узлов при хроническом наружном геморрое 1-2 ст. без учета стоимости лекарственного средства",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "10 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "10 000"
                }
            }
        },
        "dc58bfb2-65b4-11e9-936d-1856809fe650": {
            "name": "Пункционная микросклеротерапия без учета стоимости лекарственного средства",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                }
            }
        },
        "dc58bfae-65b4-11e9-936d-1856809fe650": {
            "name": "Перевязка неинфицированной раны большая",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                }
            }
        },
        "dc58bfaa-65b4-11e9-936d-1856809fe650": {
            "name": "Удаление инородного тела без учета анестезии",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                }
            }
        },
        "dc58bfa8-65b4-11e9-936d-1856809fe650": {
            "name": "Лечение фурункула, абсцесса без учета анестезии",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "4 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "4 000"
                }
            }
        },
        "dc58bfb3-65b4-11e9-936d-1856809fe650": {
            "name": "Микросклеротерапия без учета стоимости лекарственного средства",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "7 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "7 000"
                }
            }
        },
        "dc58bfa7-65b4-11e9-936d-1856809fe650": {
            "name": "Лечение панариция (паранихия) без учета анестезии",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "4 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "4 000"
                }
            }
        },
        "dc58bfa6-65b4-11e9-936d-1856809fe650": {
            "name": "Лечение вросшего ногтя в фазе воспаления без учета анестезии",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "3 600"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "4 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "4 000"
                }
            }
        },
        "dc58bfa5-65b4-11e9-936d-1856809fe650": {
            "name": "Удаление вросшего ногтя без учета анестезии",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                }
            }
        },
        "dc58bfa3-65b4-11e9-936d-1856809fe650": {
            "name": "Удаление электроножом ганглия",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "4 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "4 000"
                }
            }
        },
        "dc58bfa2-65b4-11e9-936d-1856809fe650": {
            "name": "Удаление скальпелем ганглия",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 000"
                }
            }
        },
        "dc58bfa1-65b4-11e9-936d-1856809fe650": {
            "name": "Повторная консультация хирурга ",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "10%",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                }
            }
        },
        "dc58bfa0-65b4-11e9-936d-1856809fe650": {
            "name": "Первичная консультация хирурга ",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "5210c9d9-65a2-11e9-936d-1856809fe650": {
            "name": "Первичная консультация онколога ",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Онкология",
            "specialtyUid": "ce182401-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "5210c9d5-65a2-11e9-936d-1856809fe650": {
            "name": "Электрокардиограмма",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Кардиология",
            "specialtyUid": "5210c9c1-65a2-11e9-936d-1856809fe650",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                }
            }
        },
        "5210c9d4-65a2-11e9-936d-1856809fe650": {
            "name": "Повторная консультация кардиолога ",
            "typeOfItem": "Услуга",
            "duration": 2700,
            "specialty": "Кардиология",
            "specialtyUid": "5210c9c1-65a2-11e9-936d-1856809fe650",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "550"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "550"
                }
            }
        },
        "5210c9d3-65a2-11e9-936d-1856809fe650": {
            "name": "Первичная консультация кардиолога ",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Кардиология",
            "specialtyUid": "5210c9c1-65a2-11e9-936d-1856809fe650",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                }
            }
        },
        "3813e2ac-69d5-11e9-936d-1856809fe650": {
            "name": "Первичная консультация офтальмолога",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                }
            }
        },
        "dc58bfb6-65b4-11e9-936d-1856809fe650": {
            "name": "Повторная консультация нейрохирурга ",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                }
            }
        },
        "5210c9d2-65a2-11e9-936d-1856809fe650": {
            "name": "Паравертебральная блокада",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Неврология",
            "specialtyUid": "5a2c7f7a-9fd1-11e3-9481-1078d2d69bb4",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "5210c9d1-65a2-11e9-936d-1856809fe650": {
            "name": "Рефлексотерапия при заболеваниях ЦНС",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Неврология",
            "specialtyUid": "5a2c7f7a-9fd1-11e3-9481-1078d2d69bb4",
            "artNumber": "A21.23.002",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "5210c9d0-65a2-11e9-936d-1856809fe650": {
            "name": "Повторная консультация невролога ",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Неврология",
            "specialtyUid": "5a2c7f7a-9fd1-11e3-9481-1078d2d69bb4",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "550"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "550"
                }
            }
        },
        "5210c9cf-65a2-11e9-936d-1856809fe650": {
            "name": "Первичная консультация невролога",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Неврология",
            "specialtyUid": "5a2c7f7a-9fd1-11e3-9481-1078d2d69bb4",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                }
            }
        },
        "5210c9ce-65a2-11e9-936d-1856809fe650": {
            "name": "Вызов на дом терапевта",
            "typeOfItem": "Услуга",
            "duration": 7200,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 350"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 350"
                }
            }
        },
        "5210c9cd-65a2-11e9-936d-1856809fe650": {
            "name": "Повторная консультация терапевта ",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "550"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "550"
                }
            }
        },
        "dc58bfc9-65b4-11e9-936d-1856809fe650": {
            "name": "Снятие швов",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Замещение костного дефект",
            "specialtyUid": "ad5134a8-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "5210c9cc-65a2-11e9-936d-1856809fe650": {
            "name": "Первичная консультация терапевта ",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                }
            }
        },
        "60bed4a4-a4a8-11ea-95ac-54bef7811d01": {
            "name": "Вакцинация от краснухи",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Вакцинации",
            "specialtyUid": "70769f2c-8fa7-11ea-82cc-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "6eac2b5b-3104-11e3-8b75-80c16e5c9fe3": {
            "name": "Временная пломба",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Стоматология",
            "specialtyUid": "37382f1d-d6dd-11e1-b361-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                }
            }
        },
        "37382f1e-d6dd-11e1-b361-1803736d59cd": {
            "name": "Снимок челюсти",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Стоматология",
            "specialtyUid": "37382f1d-d6dd-11e1-b361-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "6eac2b58-3104-11e3-8b75-80c16e5c9fe3": {
            "name": "Первичный прием стоматолога",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Стоматология",
            "specialtyUid": "37382f1d-d6dd-11e1-b361-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                }
            }
        },
        "e43985b0-6a46-11e4-8810-002618dcef2c": {
            "name": "Сеанс лечения устнановкой Planmeca Sovereign ",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Стоматология",
            "specialtyUid": "37382f1d-d6dd-11e1-b361-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                }
            }
        },
        "1e86b953-0b31-11e4-87f8-002618dcef2c": {
            "name": "Холодовая проба",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1e86b952-0b31-11e4-87f8-002618dcef2c": {
            "name": "Холестерин",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1e86b951-0b31-11e4-87f8-002618dcef2c": {
            "name": "Фибриноген",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1e86b94f-0b31-11e4-87f8-002618dcef2c": {
            "name": "Транспептидаза",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1e86b94e-0b31-11e4-87f8-002618dcef2c": {
            "name": "Тетикулоциты",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "dc58bfb1-65b4-11e9-936d-1856809fe650": {
            "name": "Консультация хирурга-флеболога",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "1e86b94c-0b31-11e4-87f8-002618dcef2c": {
            "name": "Скиаскопия",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "225"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "225"
                }
            }
        },
        "60bed49f-a4a8-11ea-95ac-54bef7811d01": {
            "name": "Вакцинация от туберкулеза (БЦЖ)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Вакцинации",
            "specialtyUid": "70769f2c-8fa7-11ea-82cc-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "dc58bff2-65b4-11e9-936d-1856809fe650": {
            "name": "Маска альгинатная",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                }
            }
        },
        "1e86b94b-0b31-11e4-87f8-002618dcef2c": {
            "name": "РФМК",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1e86b94d-0b31-11e4-87f8-002618dcef2c": {
            "name": "Термометрия",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1e86b947-0b31-11e4-87f8-002618dcef2c": {
            "name": "ПТИ",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1e86b944-0b31-11e4-87f8-002618dcef2c": {
            "name": "Исследование объема аккомодации",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "60bed49d-a4a8-11ea-95ac-54bef7811d01": {
            "name": "Вакцинация от кори",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Вакцинации",
            "specialtyUid": "70769f2c-8fa7-11ea-82cc-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1e86b943-0b31-11e4-87f8-002618dcef2c": {
            "name": "Натрий",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1e86b942-0b31-11e4-87f8-002618dcef2c": {
            "name": "Микроскопия мокроты",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1e86b940-0b31-11e4-87f8-002618dcef2c": {
            "name": "Креатинин",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "60bed4a5-a4a8-11ea-95ac-54bef7811d01": {
            "name": "Вакцинация от пневмококковой инфекции",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Вакцинации",
            "specialtyUid": "70769f2c-8fa7-11ea-82cc-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1e86b93a-0b31-11e4-87f8-002618dcef2c": {
            "name": "Гормональный статус",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1e86b938-0b31-11e4-87f8-002618dcef2c": {
            "name": "Глюкоза",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "1e86b937-0b31-11e4-87f8-002618dcef2c": {
            "name": "ВИЧ",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1e86b936-0b31-11e4-87f8-002618dcef2c": {
            "name": "АЧТВ",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1e86b934-0b31-11e4-87f8-002618dcef2c": {
            "name": "Аллергодиагностика in vitro, in vivo",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1e86b932-0b31-11e4-87f8-002618dcef2c": {
            "name": "a-HCV-IgG",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1e86b931-0b31-11e4-87f8-002618dcef2c": {
            "name": "a-Hbcor IgM",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "eb20edce-3ee0-11de-b0dd-0050bf5d92cb": {
            "name": "Биомикроскопия ",
            "typeOfItem": "Услуга",
            "duration": 3000,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "A03.26.001",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "450"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                }
            }
        },
        "210cf9d3-fd07-11e3-87f5-002618dcef2c": {
            "name": "Прием врача-уролога при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "14",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "210cf9d4-fd07-11e3-87f5-002618dcef2c": {
            "name": "Прием врача-хирурга при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "15",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "210cf9d1-fd07-11e3-87f5-002618dcef2c": {
            "name": "Прием врача-психиатра при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "12",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "108"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                }
            }
        },
        "210cf9cf-fd07-11e3-87f5-002618dcef2c": {
            "name": "Прием врача-отоларинолог при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "10",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "210cf9ca-fd07-11e3-87f5-002618dcef2c": {
            "name": "Прием врача-нарколога при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "05",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "108"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                }
            }
        },
        "210cf9c9-fd07-11e3-87f5-002618dcef2c": {
            "name": "Прием врача-инфекциониста при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "04",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "3252ceb6-fc4b-11e3-87f5-002618dcef2c": {
            "name": "УЗИ молочных желез",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Диагностика",
            "specialtyUid": "3252ceb7-fc4b-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "3252ceb4-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Бактериологическое и цитологическое исследование",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "72"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "80"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "80"
                }
            }
        },
        "3252ceb2-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Биохимический анализ крови",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "81"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "90"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "90"
                }
            }
        },
        "e22de3a5-6fc1-11e9-936d-1856809fe650": {
            "name": "Массаж при заболеваниях центральной нервной системы",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Неврология",
            "specialtyUid": "5a2c7f7a-9fd1-11e3-9481-1078d2d69bb4",
            "artNumber": "A21.23.001",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                }
            }
        },
        "3252ceb1-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Электрокардиография",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "270"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "210cf9c6-fd07-11e3-87f5-002618dcef2c": {
            "name": "Прием врача-аллерголога при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "01",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "1e86b933-0b31-11e4-87f8-002618dcef2c": {
            "name": "HbsAg",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "3252ceab-fc4b-11e3-87f5-002618dcef2c": {
            "name": "При наличии свинца в аэрозоле:",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "81"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "90"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "90"
                }
            }
        },
        "dc58bff0-65b4-11e9-936d-1856809fe650": {
            "name": "Уход за жирной кожей, склонной к воспалениям",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                }
            }
        },
        "3252cea9-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Количественное содержание а1-антитрипсина",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "72"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "80"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "80"
                }
            }
        },
        "3252cea5-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Рентгенография длинных трубчатых костей ",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лучевая диагностика",
            "specialtyUid": "3252ceb8-fc4b-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "166,5"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "185"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "185"
                }
            }
        },
        "3252cea2-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Время кровотечения",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "45"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                }
            }
        },
        "210cf9cc-fd07-11e3-87f5-002618dcef2c": {
            "name": "Прием врача-окулиста при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "07",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "1056ea07-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Медь в крови",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "54"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "60"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "60"
                }
            }
        },
        "1056ea06-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Исследование периметрии",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "180"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "dc58bfa9-65b4-11e9-936d-1856809fe650": {
            "name": "Забор материала (отделяемого из раны) на посев",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "1056ea05-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Тонометрия",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "3252ceaf-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Исследование переднего отрезка глаза (для медосмотра)",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "1056ea04-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Биомикроскопия переднего отрезка и хрусталика глаза",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "1056ea03-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Ртуть в моче",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "5210c9d8-65a2-11e9-936d-1856809fe650": {
            "name": "Оценка жировых отложений в организме",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Эндокринология",
            "specialtyUid": "ce182406-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "1056e9fd-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Мочевина, креатинин крови",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "72"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "80"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "80"
                }
            }
        },
        "1056e9fb-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Анализ мочи на ртуть",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "54"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "60"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "60"
                }
            }
        },
        "1e86b92f-0b31-11e4-87f8-002618dcef2c": {
            "name": "Прием врача-рентгенолога при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "17",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "1056e9f7-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Реовазография сосудов конечностей",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "270"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "dc58bfd2-65b4-11e9-936d-1856809fe650": {
            "name": "Соскоб материала на анализ (кожа,ногти)",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "330"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "330"
                }
            }
        },
        "1056e9f6-fc4b-11e3-87f5-002618dcef2c": {
            "name": "УЗИ брюшной полости",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Диагностика",
            "specialtyUid": "3252ceb7-fc4b-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "108"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                }
            }
        },
        "1056e9f1-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Мазок из зева и носа на наличие патогенного стафилококка ",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "1056e9ef-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Исследования на гельминтозы ",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "27"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "30"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "30"
                }
            }
        },
        "1056e9ee-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Исследования на носительство возбудителей кишечных инфекций и серологическое обс",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "81"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "90"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "90"
                }
            }
        },
        "210cf9ce-fd07-11e3-87f5-002618dcef2c": {
            "name": "Прием врача-ортопеда при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "09",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "1056e9ea-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Аудиометрия",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "dc58bfe2-65b4-11e9-936d-1856809fe650": {
            "name": "Массаж 30 мин ",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                }
            }
        },
        "1056e9e8-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Определение роста и веса (для медосмотра)",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "63"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "70"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "70"
                }
            }
        },
        "1056e9e6-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Активность холинэстеразы",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "45"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                }
            }
        },
        "1056e9e3-fc4b-11e3-87f5-002618dcef2c": {
            "name": "РВГ",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "180"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "1056e9e2-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Рентгенография придаточных пазух носа",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лучевая диагностика",
            "specialtyUid": "3252ceb8-fc4b-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "1056e9e1-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Цистоскопия",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "1056e9dd-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Биомикроскопия сред глаза",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "108"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                }
            }
        },
        "1056e9db-fc4b-11e3-87f5-002618dcef2c": {
            "name": "УЗИ внутренних органов",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Диагностика",
            "specialtyUid": "3252ceb7-fc4b-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "108"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                }
            }
        },
        "60bed49b-a4a8-11ea-95ac-54bef7811d01": {
            "name": "Вакцинация от дифтерии",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Вакцинации",
            "specialtyUid": "70769f2c-8fa7-11ea-82cc-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1056e9da-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Эритроциты с базофильной зернистостью",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "45"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                }
            }
        },
        "3252cea8-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Определение фтора в моче",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "45"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                }
            }
        },
        "5210c9d6-65a2-11e9-936d-1856809fe650": {
            "name": "Первичная консультация эндокринолога ",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Эндокринология",
            "specialtyUid": "ce182406-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                }
            }
        },
        "1e86b94a-0b31-11e4-87f8-002618dcef2c": {
            "name": "Рефрактометрия",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "1056e9d9-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Карбоксигемоглобин",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "1056e9d8-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Специфическая аллергодиагностика",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "1056e9d7-fc4b-11e3-87f5-002618dcef2c": {
            "name": "ГГТП",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "5210c9d7-65a2-11e9-936d-1856809fe650": {
            "name": "Повторная консультация эндокринолога ",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Эндокринология",
            "specialtyUid": "ce182406-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "550"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "550"
                }
            }
        },
        "1056e9d6-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Рентгенография грудной клетки в двух проекциях",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лучевая диагностика",
            "specialtyUid": "3252ceb8-fc4b-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "216"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "240"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "240"
                }
            }
        },
        "1056ea02-fc4b-11e3-87f5-002618dcef2c": {
            "name": "КП мочи",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "1056e9d5-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Ретикулоциты",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "45"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                }
            }
        },
        "1056e9d4-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Базофильная зернистость эритроцитов",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "72"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "80"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "80"
                }
            }
        },
        "1e86b93b-0b31-11e4-87f8-002618dcef2c": {
            "name": "Динамометрия",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1056e9cb-fc4b-11e3-87f5-002618dcef2c": {
            "name": "АСТ",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "63"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "70"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "70"
                }
            }
        },
        "70769f34-8fa7-11ea-82cc-40167e631f2e": {
            "name": "Вакцинация от полиомелита (живая)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Вакцинации",
            "specialtyUid": "70769f2c-8fa7-11ea-82cc-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "dc58bfea-65b4-11e9-936d-1856809fe650": {
            "name": "Чистка лица ручная  ",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 600"
                }
            }
        },
        "70769f31-8fa7-11ea-82cc-40167e631f2e": {
            "name": "Вакцинация от гемофильной инфекции",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Вакцинации",
            "specialtyUid": "70769f2c-8fa7-11ea-82cc-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "dc58bfb0-65b4-11e9-936d-1856809fe650": {
            "name": "Перевязка инфицированной раны большая",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "3e0f7819-4fb8-11e9-82be-40167e631f2e": {
            "name": "Справка 082/у Медкомиссия для выезжающих за границу",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": true,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                }
            }
        },
        "1056e9f5-fc4b-11e3-87f5-002618dcef2c": {
            "name": "ФГДС",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "180"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "3e0f7816-4fb8-11e9-82be-40167e631f2e": {
            "name": "Медицинское заключение на оружие (форма N 002-О/у)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": true,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 500"
                }
            }
        },
        "4da80b48-4fa2-11e9-82be-40167e631f2e": {
            "name": "Справка 989н на работу с использованием сведений составляющих государственную тайну",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": true,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                }
            }
        },
        "3e0f7818-4fb8-11e9-82be-40167e631f2e": {
            "name": "Медкомиссия водителей (форма №003-В/у) категории \"С\", \"D\", \"СЕ\", \"DE\", \"Tm\", \"Тb\"",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": true,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 600"
                }
            }
        },
        "1056e9f0-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Мазки на гонорею",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "171"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "190"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "190"
                }
            }
        },
        "1056e9ec-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Исследование крови на сифилис",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "210cf9cd-fd07-11e3-87f5-002618dcef2c": {
            "name": "Прием врача-онколога при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "08",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "6773dc52-67dc-11e9-936d-1856809fe650": {
            "name": "Школа для больных с сердечной недостаточностью",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Кардиология",
            "specialtyUid": "5210c9c1-65a2-11e9-936d-1856809fe650",
            "artNumber": "В04.015.002",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "0c289c3f-4ee6-11e9-82be-40167e631f2e": {
            "name": "Справка в бассейн (Взрослые)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": true,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                }
            }
        },
        "dc58bfe5-65b4-11e9-936d-1856809fe650": {
            "name": "Пилинг TCA 15% (трихлоруксусная кислота)",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 000"
                }
            }
        },
        "0c289c3c-4ee6-11e9-82be-40167e631f2e": {
            "name": "Справка в бассейн по форме 083_4_89 (Взрослые)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": true,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                }
            }
        },
        "1056e9e5-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Рентгенография трубчатых костей",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лучевая диагностика",
            "specialtyUid": "3252ceb8-fc4b-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "222df5d1-4964-11e9-82be-40167e631f2e": {
            "name": "Справка 001-ГС/у Медосмотр при поступлении на госслужбу",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": true,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                }
            }
        },
        "1056e9eb-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Исследование вестибулярного анализатора",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "222df5d0-4964-11e9-82be-40167e631f2e": {
            "name": "Справка 086/у для поступающих в учебные заведения",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": true,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                }
            }
        },
        "19dc630a-d9c4-11e7-82a6-40167e631f2e": {
            "name": "Курс массажа век 5 сеансов",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "0"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "0"
                }
            }
        },
        "ad5134c8-42f3-11e4-8253-0cd29229b33b": {
            "name": "Изготовление гипсовых моделей",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Ортодонтия",
            "specialtyUid": "ad5134c5-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "750"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "750"
                }
            }
        },
        "ad5134c7-42f3-11e4-8253-0cd29229b33b": {
            "name": "Консультация врача ортодонта",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Ортодонтия",
            "specialtyUid": "ad5134c5-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                }
            }
        },
        "ad5134c4-42f3-11e4-8253-0cd29229b33b": {
            "name": "Перебазировка съемного протеза клиническим путем",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Съемное протезирование",
            "specialtyUid": "ad5134c1-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "4 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "4 500"
                }
            }
        },
        "ad5134be-42f3-11e4-8253-0cd29229b33b": {
            "name": "Вкладка культевая",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Несъемное протезирование",
            "specialtyUid": "ad5134bc-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "6 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "6 000"
                }
            }
        },
        "3252ceb0-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Исследование мочи",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "54"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "60"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "60"
                }
            }
        },
        "1056e9fe-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Биомикроскопия переднего отрезка глаза",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "270"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ad5134bb-42f3-11e4-8253-0cd29229b33b": {
            "name": "Снятие металлической коронки",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Ортопедическая стоматология",
            "specialtyUid": "ad5134b4-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "dc58bfa4-65b4-11e9-936d-1856809fe650": {
            "name": "Гистологическое исследование удаленного материала",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "5210c9db-65a2-11e9-936d-1856809fe650": {
            "name": "Пункция новообразований ",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Онкология",
            "specialtyUid": "ce182401-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                }
            }
        },
        "ad5134b6-42f3-11e4-8253-0cd29229b33b": {
            "name": "Восковое моделирование",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Ортопедическая стоматология",
            "specialtyUid": "ad5134b4-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 100"
                }
            }
        },
        "3e0f7817-4fb8-11e9-82be-40167e631f2e": {
            "name": "Медкомиссия водителей (форма №003-В/у) категории \"М\", \"А\", \"А1\", \"В\", В1\", \"ВЕ\"",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": true,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 500"
                }
            }
        },
        "ad5134b0-42f3-11e4-8253-0cd29229b33b": {
            "name": "Двучелюстное шинирование с резиновой тягой при переломе челюстных костей",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Замещение костного дефект",
            "specialtyUid": "ad5134a8-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 500"
                }
            }
        },
        "1056e9ce-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Психологическое тестирование",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "5210c9da-65a2-11e9-936d-1856809fe650": {
            "name": "Повторная консультация онколога ",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Онкология",
            "specialtyUid": "ce182401-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                }
            }
        },
        "ad5134a3-42f3-11e4-8253-0cd29229b33b": {
            "name": "Лечение альвеолита \r\n(1 посещение)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                }
            }
        },
        "1e86b93e-0b31-11e4-87f8-002618dcef2c": {
            "name": "Кальций",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ad5134a1-42f3-11e4-8253-0cd29229b33b": {
            "name": "Хирургическое лечение перикоронарита",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 500"
                }
            }
        },
        "ad5134b2-42f3-11e4-8253-0cd29229b33b": {
            "name": "Остановка луночкого кровотечения",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Замещение костного дефект",
            "specialtyUid": "ad5134a8-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "1e86b945-0b31-11e4-87f8-002618dcef2c": {
            "name": "Паллестезиометрия",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ad5134a0-42f3-11e4-8253-0cd29229b33b": {
            "name": "Обнажение коронки ретенированного зуба (в т.ч по ортодонтическим показаниям)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                }
            }
        },
        "1056e9f9-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Тельца Гейнца",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "ad5134aa-42f3-11e4-8253-0cd29229b33b": {
            "name": "Замещение костного дефекта или заполнение полости биоактивным материалом",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Замещение костного дефект",
            "specialtyUid": "ad5134a8-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "ad51349e-42f3-11e4-8253-0cd29229b33b": {
            "name": "Удаление ретенционной кисты",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 400"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 400"
                }
            }
        },
        "ad51349d-42f3-11e4-8253-0cd29229b33b": {
            "name": "Удаление радикуляционной кисты через лунку",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                }
            }
        },
        "1056e9d3-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Спирометрия",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "180"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "ad51349b-42f3-11e4-8253-0cd29229b33b": {
            "name": "Удаление стенки коронки зуба",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "1056e9d2-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Исследование остроты зрения",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "180"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "ad513496-42f3-11e4-8253-0cd29229b33b": {
            "name": "Удаление постоянного зуба сложное (с разделением корней, с отслаиванием слизисто",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                }
            }
        },
        "70769f3b-8fa7-11ea-82cc-40167e631f2e": {
            "name": "Вакцинация от полиомелита (инактивированная)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Вакцинации",
            "specialtyUid": "70769f2c-8fa7-11ea-82cc-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "60bed495-a4a8-11ea-95ac-54bef7811d01": {
            "name": "Вакцинация от столбняка",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Вакцинации",
            "specialtyUid": "70769f2c-8fa7-11ea-82cc-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "dc58bfad-65b4-11e9-936d-1856809fe650": {
            "name": "Перевязка неинфицированной раны малая",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                }
            }
        },
        "1e86b949-0b31-11e4-87f8-002618dcef2c": {
            "name": "Рентгенография суставов, позвоночника",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Лучевая диагностика",
            "specialtyUid": "3252ceb8-fc4b-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "ad513494-42f3-11e4-8253-0cd29229b33b": {
            "name": "Удаление постоянного зуба простое",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 600"
                }
            }
        },
        "ad51349a-42f3-11e4-8253-0cd29229b33b": {
            "name": "Альвеолотомия (в области удаленного зуба)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 000"
                }
            }
        },
        "ad513490-42f3-11e4-8253-0cd29229b33b": {
            "name": "Аппликация твердых тканей зуба реминерализирующими и фторосодержащими препапр",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "ad51348d-42f3-11e4-8253-0cd29229b33b": {
            "name": "Наложение девитализирующей пасты",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "350"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "350"
                }
            }
        },
        "ad51348c-42f3-11e4-8253-0cd29229b33b": {
            "name": "Извлечение фиксированного инородного тела из одного корневого канала",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "900"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "900"
                }
            }
        },
        "1056e9cf-fc4b-11e3-87f5-002618dcef2c": {
            "name": "ЭЭГ",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "153"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "170"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "170"
                }
            }
        },
        "ad51348a-42f3-11e4-8253-0cd29229b33b": {
            "name": "Постановка временной корневой пломбы",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                }
            }
        },
        "210cf9d5-fd07-11e3-87f5-002618dcef2c": {
            "name": "Прием врача-эндокринолога при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "16",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "1e86b946-0b31-11e4-87f8-002618dcef2c": {
            "name": "Протромбиновое время",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ad513489-42f3-11e4-8253-0cd29229b33b": {
            "name": "Пломбирование одного корневого канала термопластифицированной гуттаперчей",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "ad513488-42f3-11e4-8253-0cd29229b33b": {
            "name": "Обтурация одного корневого канала гуттаперчей методом латеральной конденсации",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 200"
                }
            }
        },
        "3252cea4-fc4b-11e3-87f5-002618dcef2c": {
            "name": "УЗИ почек и мочевыделительной системы",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Диагностика",
            "specialtyUid": "3252ceb7-fc4b-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "108"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                }
            }
        },
        "1e86b950-0b31-11e4-87f8-002618dcef2c": {
            "name": "Тромбоциты",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "270"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ad51348e-42f3-11e4-8253-0cd29229b33b": {
            "name": "Удаление зубных отложений в области одного зуба с использованием ультразвука",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "ad513491-42f3-11e4-8253-0cd29229b33b": {
            "name": "Удаление старой пломбы",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                }
            }
        },
        "ad513487-42f3-11e4-8253-0cd29229b33b": {
            "name": "Подготовка к пломбированию одного корневого канала с применением средств механич",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ad513486-42f3-11e4-8253-0cd29229b33b": {
            "name": "Препарирование и фиксация стекловолоконного штифта в корневом канале",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "1056e9ca-fc4b-11e3-87f5-002618dcef2c": {
            "name": "АЛТ",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "36"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "40"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "40"
                }
            }
        },
        "ad513485-42f3-11e4-8253-0cd29229b33b": {
            "name": "Препарирование и фиксация анкерного штифта в корневом канале",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "ad513484-42f3-11e4-8253-0cd29229b33b": {
            "name": "Восстановление коронковой части зуба под дальнейшее ортопедическое лечение",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 100"
                }
            }
        },
        "dc58bfcd-65b4-11e9-936d-1856809fe650": {
            "name": "Закрытое вправление вывихов в суставах",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Ортопедия и травматология",
            "specialtyUid": "ce182402-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 000"
                }
            }
        },
        "13d1be68-c715-11df-86cd-002618dcef2c": {
            "name": "УЗД Хрусталика",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "270"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "60bed49a-a4a8-11ea-95ac-54bef7811d01": {
            "name": "Вакцинация от столбняка (с уменьшенным содержанием антигенов)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Вакцинации",
            "specialtyUid": "70769f2c-8fa7-11ea-82cc-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ad51349f-42f3-11e4-8253-0cd29229b33b": {
            "name": "Цистэктомия с резекцией корня",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "5 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "5 500"
                }
            }
        },
        "ad51348f-42f3-11e4-8253-0cd29229b33b": {
            "name": "Проведение процедуры air-flow в области одного зуба",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "ad513483-42f3-11e4-8253-0cd29229b33b": {
            "name": "Винир, изготовленный прямым методом из композита",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "18 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "18 000"
                }
            }
        },
        "ad513495-42f3-11e4-8253-0cd29229b33b": {
            "name": "Удаление постоянного зуба средней степени сложности",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                }
            }
        },
        "1056ea01-fc4b-11e3-87f5-002618dcef2c": {
            "name": "АЛК или КП в моче",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "58,5"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "65"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "65"
                }
            }
        },
        "ad513481-42f3-11e4-8253-0cd29229b33b": {
            "name": "Восстановление формы зуба при отсутствии твердых тканей на½ коронки зуба после э",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                }
            }
        },
        "3252ceac-fc4b-11e3-87f5-002618dcef2c": {
            "name": "УЗИ органов малого таза",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Диагностика",
            "specialtyUid": "3252ceb7-fc4b-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "162"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "180"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "180"
                }
            }
        },
        "ad51349c-42f3-11e4-8253-0cd29229b33b": {
            "name": "Периостеотомия",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                }
            }
        },
        "ad51347c-42f3-11e4-8253-0cd29229b33b": {
            "name": "Полировка пломбы",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "4e6af6f5-c4b7-11e9-93b7-50af7323357f": {
            "name": "Пребывание в стационаре, сутки, VIP-палата",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Стационар",
            "specialtyUid": "4e6af6f4-c4b7-11e9-93b7-50af7323357f",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 200"
                }
            }
        },
        "dc58bfef-65b4-11e9-936d-1856809fe650": {
            "name": "Уход за сухой обезвоженной кожей",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                }
            }
        },
        "ad513480-42f3-11e4-8253-0cd29229b33b": {
            "name": "Постановка временной пломбы",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "ad5134b7-42f3-11e4-8253-0cd29229b33b": {
            "name": "Диагностические модели из гипса",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Ортопедическая стоматология",
            "specialtyUid": "ad5134b4-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1056e9d0-fc4b-11e3-87f5-002618dcef2c": {
            "name": "ЭНМГ",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "1e86b935-0b31-11e4-87f8-002618dcef2c": {
            "name": "Анализ кала на яйца гельминтов",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ad513477-42f3-11e4-8253-0cd29229b33b": {
            "name": "Наложение коффердама",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                }
            }
        },
        "ad513476-42f3-11e4-8253-0cd29229b33b": {
            "name": "Наложение кламмерного коффердама",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                }
            }
        },
        "ad513498-42f3-11e4-8253-0cd29229b33b": {
            "name": "Удаление ретенированного, дистопированного зуба с глубокой остеотомией",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "3 000"
                }
            }
        },
        "1056e9e0-fc4b-11e3-87f5-002618dcef2c": {
            "name": "УЗИ почек и мочевыводящих путей",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Диагностика",
            "specialtyUid": "3252ceb7-fc4b-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "108"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                }
            }
        },
        "1e86b93f-0b31-11e4-87f8-002618dcef2c": {
            "name": "Калий",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ad513474-42f3-11e4-8253-0cd29229b33b": {
            "name": "Трепанация зуба, искусственной коронки",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 200"
                }
            }
        },
        "ad513475-42f3-11e4-8253-0cd29229b33b": {
            "name": "Депофорез одного корневого канала (1 сеанс)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                }
            }
        },
        "ad513470-42f3-11e4-8253-0cd29229b33b": {
            "name": "Коррекция режущего края (1 зуб)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                }
            }
        },
        "ad51347b-42f3-11e4-8253-0cd29229b33b": {
            "name": "Постановка одной пломбы из светоотверждаемого материала при поверхностном и среднем кариесе I и V класса по Блеку",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 600"
                }
            }
        },
        "ad51346f-42f3-11e4-8253-0cd29229b33b": {
            "name": "Профилактическое запечатывание фиссур герметиком (1 зуб)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "3252ceb3-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Содержание в сыворотке крови глюкозы",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "67,5"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "75"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "75"
                }
            }
        },
        "df314a33-e476-11e2-bce7-80c16e5c9fe3": {
            "name": "Анастезия",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Стоматология",
            "specialtyUid": "37382f1d-d6dd-11e1-b361-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "450"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "450"
                }
            }
        },
        "1056e9fa-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Метгемоглобин",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "45"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                }
            }
        },
        "ad513473-42f3-11e4-8253-0cd29229b33b": {
            "name": "Диатермокоагуляция одного десневого сосочка, содержимого одного канала",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ad51346d-42f3-11e4-8253-0cd29229b33b": {
            "name": "Отбеливание внутриканальное 1 зуба (1 сеанс)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                }
            }
        },
        "ad513460-42f3-11e4-8253-0cd29229b33b": {
            "name": "Проводниковая анестезия",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Общие виды работ",
            "specialtyUid": "ad513456-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                }
            }
        },
        "ad51345f-42f3-11e4-8253-0cd29229b33b": {
            "name": "Инфильтрационная анестезия",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Общие виды работ",
            "specialtyUid": "ad513456-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                }
            }
        },
        "ad513462-42f3-11e4-8253-0cd29229b33b": {
            "name": "Диатермокоагуляция гипертрофированного зубодесневого сосочка",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Общие виды работ",
            "specialtyUid": "ad513456-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "1056e9de-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Цветоощущение",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "1e86b939-0b31-11e4-87f8-002618dcef2c": {
            "name": "Гормональный профиль",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ad513497-42f3-11e4-8253-0cd29229b33b": {
            "name": "Удаление ретенированного дистопированного зуба",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 200"
                }
            }
        },
        "ad513479-42f3-11e4-8253-0cd29229b33b": {
            "name": "Постановка одной пломбы из стеклоиномерного цемента по I и V классу по Блеку",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                }
            }
        },
        "ad513471-42f3-11e4-8253-0cd29229b33b": {
            "name": "Определение индекса гигиены, индекса КПУ",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "ad513464-42f3-11e4-8253-0cd29229b33b": {
            "name": "Инъекция лекарственного средства",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Общие виды работ",
            "specialtyUid": "ad513456-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "ad5134a7-42f3-11e4-8253-0cd29229b33b": {
            "name": "Закрытый («мягкий») синуслифтинг",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "20 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "20 000"
                }
            }
        },
        "ad51345e-42f3-11e4-8253-0cd29229b33b": {
            "name": "Аппликационная анестезия",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Общие виды работ",
            "specialtyUid": "ad513456-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                }
            }
        },
        "6d9fb0ca-f27c-11e5-827e-40167e631f2e": {
            "name": "Изготовление очков",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "1 800"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                }
            }
        },
        "1056e9f4-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Исследование функции вестибулярного аппарата",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Оториноларингология",
            "specialtyUid": "eebe3e98-233d-11e2-9496-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "148,5"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "165"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "165"
                }
            }
        },
        "1e86b930-0b31-11e4-87f8-002618dcef2c": {
            "name": "Прием врача-терапевта профпатолога при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "17",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "117"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "130"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "130"
                }
            }
        },
        "ad51345a-42f3-11e4-8253-0cd29229b33b": {
            "name": "Консультация кандидата мед. наук с определением стоимости лечения",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Общие виды работ",
            "specialtyUid": "ad513456-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "320"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "320"
                }
            }
        },
        "1056e9d1-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Исследование поля зрения",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "180"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "ad5134a6-42f3-11e4-8253-0cd29229b33b": {
            "name": "Открытый синуслифтинг (операция по поднятию дна верхнечелюстной пазухи) без стои",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "40 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "40 000"
                }
            }
        },
        "ad513459-42f3-11e4-8253-0cd29229b33b": {
            "name": "Консультация врача высшей квалификационной категории",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Общие виды работ",
            "specialtyUid": "ad513456-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ad513458-42f3-11e4-8253-0cd29229b33b": {
            "name": "Консультация первичного пациента с определением плана и стоимости лечения",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Общие виды работ",
            "specialtyUid": "ad513456-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1056e9df-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Пульсоксиметрия",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "180"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "ad513478-42f3-11e4-8253-0cd29229b33b": {
            "name": "Профессиональное отбеливание",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "9 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "9 000"
                }
            }
        },
        "ca040358-9f39-11e6-8221-985fd33a0f52": {
            "name": "Услуги по ремонту вкладышей и корпусов, в т.ч. замена трубочек вкладышей и фильтров внутриушных моделей",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "315"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "350"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "350"
                }
            }
        },
        "ca040357-9f39-11e6-8221-985fd33a0f52": {
            "name": "Изготовление корпусов внутриушных и внутриканальных слуховых аппаратов",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "225"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                }
            }
        },
        "eb20edcf-3ee0-11de-b0dd-0050bf5d92cb": {
            "name": "Компьютерная периметрия (центр)",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "630"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                }
            }
        },
        "ad513468-42f3-11e4-8253-0cd29229b33b": {
            "name": "Осмотр (снятие швов, перевязка)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Общие виды работ",
            "specialtyUid": "ad513456-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "ca040355-9f39-11e6-8221-985fd33a0f52": {
            "name": "Отоакустическая эмиссия",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "360"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                }
            }
        },
        "dc58bfb7-65b4-11e9-936d-1856809fe650": {
            "name": "Первичная консультация маммолога ",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "ad5134b1-42f3-11e4-8253-0cd29229b33b": {
            "name": "Удаление подкожных (атерома, липома и др.) доброкачественных образований лица, ш",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Замещение костного дефект",
            "specialtyUid": "ad5134a8-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                }
            }
        },
        "3252cea7-fc4b-11e3-87f5-002618dcef2c": {
            "name": "ЩФ",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "ad51347f-42f3-11e4-8253-0cd29229b33b": {
            "name": "Наложение лечебной прокладки",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                }
            }
        },
        "ca040354-9f39-11e6-8221-985fd33a0f52": {
            "name": "Осмотр врача-оториноларинголога в процессе лечения",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Оториноларингология",
            "specialtyUid": "eebe3e98-233d-11e2-9496-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "157,5"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "175"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "175"
                }
            }
        },
        "6eac2b5a-3104-11e3-8b75-80c16e5c9fe3": {
            "name": "Бюгельный протез",
            "typeOfItem": "Услуга",
            "duration": 4800,
            "specialty": "Стоматология",
            "specialtyUid": "37382f1d-d6dd-11e1-b361-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "35 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "35 000"
                }
            }
        },
        "ad513499-42f3-11e4-8253-0cd29229b33b": {
            "name": "Гемисекция (удаление одного из корней зуба)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                }
            }
        },
        "ca040353-9f39-11e6-8221-985fd33a0f52": {
            "name": "Осмотр врача-сурдолога в процессе лечения",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "157,5"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "175"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "175"
                }
            }
        },
        "ad5134a5-42f3-11e4-8253-0cd29229b33b": {
            "name": "Пластика альвеолярного гребня челюсти (подготовительная операция для установки и",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                }
            }
        },
        "210cf9d0-fd07-11e3-87f5-002618dcef2c": {
            "name": "Прием врача-офтальмолога при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "11",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "60bed49c-a4a8-11ea-95ac-54bef7811d01": {
            "name": "Вакцинация от дифтерии (с уменьшенным содержанием антигенов)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Вакцинации",
            "specialtyUid": "70769f2c-8fa7-11ea-82cc-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ca040351-9f39-11e6-8221-985fd33a0f52": {
            "name": "Полное обследование врачом-сурдологом (с подбором слухового аппарата)",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "540"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                }
            }
        },
        "1e86b93c-0b31-11e4-87f8-002618dcef2c": {
            "name": "Исследование бинокулярного зрения",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ad51347d-42f3-11e4-8253-0cd29229b33b": {
            "name": "Восстановление цвета и формы зуба при некариозных поражениях твердых тканей зубо",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                }
            }
        },
        "1056e9e9-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Определение группы крови и резус-фактора",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "45"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                }
            }
        },
        "210cf9d2-fd07-11e3-87f5-002618dcef2c": {
            "name": "Прием врача-стоматолога при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "13",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "ca040350-9f39-11e6-8221-985fd33a0f52": {
            "name": "Лечение аппаратом Симпатокор-01",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "ad51345c-42f3-11e4-8253-0cd29229b33b": {
            "name": "Консультация по имплантации с участием хирурга, ортопеда с определением плана и ",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Общие виды работ",
            "specialtyUid": "ad513456-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ca04034f-9f39-11e6-8221-985fd33a0f52": {
            "name": "Туалет носа с использованием ЛОР-установки EUROPA-MODULA",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Оториноларингология",
            "specialtyUid": "eebe3e98-233d-11e2-9496-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "5210c9dc-65a2-11e9-936d-1856809fe650": {
            "name": "Наложение косметических швов на одну нить без учета анестезии",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Онкология",
            "specialtyUid": "ce182401-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                }
            }
        },
        "ca04034e-9f39-11e6-8221-985fd33a0f52": {
            "name": "Осмотр врача-отоневролога в процессе лечения",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Отоневрология",
            "specialtyUid": "ca040330-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "180"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "ce96f496-2340-11e2-9496-1803736d59cd": {
            "name": "Промывание гайморовых пазух",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Оториноларингология",
            "specialtyUid": "eebe3e98-233d-11e2-9496-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "225"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                }
            }
        },
        "dc58bfe3-65b4-11e9-936d-1856809fe650": {
            "name": "Массаж 60 минут",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 400"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 400"
                }
            }
        },
        "ca04034a-9f39-11e6-8221-985fd33a0f52": {
            "name": "Регистрация отоакустической эмиссии на разговорных частотах в процессе лечения в центре",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "180"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "ca040348-9f39-11e6-8221-985fd33a0f52": {
            "name": "Оформление документов для медико-социальной экспертизы (МСЭ)",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Немедицинские услуги",
            "specialtyUid": "9f3d4b09-afc0-11e6-8287-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "315"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "350"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "350"
                }
            }
        },
        "ca040356-9f39-11e6-8221-985fd33a0f52": {
            "name": "Изготовление индивидуальных ушных вкладышей для слуховых аппаратов",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "540"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "600"
                }
            }
        },
        "210cf9c8-fd07-11e3-87f5-002618dcef2c": {
            "name": "Прием врача-дермовенеролога при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "03",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "dc58bfbe-65b4-11e9-936d-1856809fe650": {
            "name": "Наложение полиуретановой повязки малой (кисть-предплечье, стопа-голень) без учета расходного материала",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Ортопедия и травматология",
            "specialtyUid": "ce182402-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 800"
                }
            }
        },
        "3252ceb5-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Маммография (для медосмотра)",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "225"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                }
            }
        },
        "ca040347-9f39-11e6-8221-985fd33a0f52": {
            "name": "Регистрация слуховых вызванных потенциалов",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "270"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1056e9fc-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Анализ крови на ртуть",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "45"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                }
            }
        },
        "ca040345-9f39-11e6-8221-985fd33a0f52": {
            "name": "Объективная аудиометрия (ASSR)",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "225"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                }
            }
        },
        "ad51346e-42f3-11e4-8253-0cd29229b33b": {
            "name": "Глубокое фторирование (1 зуб)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "ca040341-9f39-11e6-8221-985fd33a0f52": {
            "name": "Тональная пороговая аудиометрия",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "360"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                }
            }
        },
        "ca04034c-9f39-11e6-8221-985fd33a0f52": {
            "name": "Регистрация отоакустической эмиссии на разговорных частотах (1 измерение)",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "180"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "ad5134a4-42f3-11e4-8253-0cd29229b33b": {
            "name": "Секвестрэктомия",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 500"
                }
            }
        },
        "ca04034b-9f39-11e6-8221-985fd33a0f52": {
            "name": "Регистрация отоакустической эмиссии на разговорных частотах (2 измерения)",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "216"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "240"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "240"
                }
            }
        },
        "ad5134b8-42f3-11e4-8253-0cd29229b33b": {
            "name": "Выполнение работ с использованием лицевой дуги и артикулятора",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Ортопедическая стоматология",
            "specialtyUid": "ad5134b4-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1056e9cd-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Офтальмоскопия глазного дна",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "ca04033f-9f39-11e6-8221-985fd33a0f52": {
            "name": "Тимпанометрия 2 частота",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "ca04033e-9f39-11e6-8221-985fd33a0f52": {
            "name": "Тимпанометрия 1 частота",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "180"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "1056e9e7-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Исследование уровня ТТГ, Т3, Т4",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "189"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "210"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "210"
                }
            }
        },
        "ca04033d-9f39-11e6-8221-985fd33a0f52": {
            "name": "Скрининг слуха новорожденных",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "405"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "450"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "450"
                }
            }
        },
        "6eac2b59-3104-11e3-8b75-80c16e5c9fe3": {
            "name": "Распломбировка одного корневого канала",
            "typeOfItem": "Услуга",
            "duration": 1200,
            "specialty": "Стоматология",
            "specialtyUid": "37382f1d-d6dd-11e1-b361-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 050"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 050"
                }
            }
        },
        "ca04034d-9f39-11e6-8221-985fd33a0f52": {
            "name": "Магнитолазеротерапия (1 сеанс)",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "1056ea00-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Свинец в крови",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "54"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "60"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "60"
                }
            }
        },
        "dc58bfcf-65b4-11e9-936d-1856809fe650": {
            "name": "Первичный консультация дерматовенеролога ",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                }
            }
        },
        "ad51347e-42f3-11e4-8253-0cd29229b33b": {
            "name": "Наложение изолирующей прокладки",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "ad5134c9-42f3-11e4-8253-0cd29229b33b": {
            "name": "Снятие 1- го слепка",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Ортодонтия",
            "specialtyUid": "ad5134c5-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ca04033c-9f39-11e6-8221-985fd33a0f52": {
            "name": "Санация лакун небных миндалин",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Оториноларингология",
            "specialtyUid": "eebe3e98-233d-11e2-9496-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "180"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "1e86b941-0b31-11e4-87f8-002618dcef2c": {
            "name": "Микологические исследования",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ca040336-9f39-11e6-8221-985fd33a0f52": {
            "name": "Настройка слухового аппарата",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "315"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "350"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "350"
                }
            }
        },
        "1056e9f8-fc4b-11e3-87f5-002618dcef2c": {
            "name": "УЗИ щитовидной железы",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Диагностика",
            "specialtyUid": "3252ceb7-fc4b-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "ad51346b-42f3-11e4-8253-0cd29229b33b": {
            "name": "Обучение правилам и навыкам чистки зубов. Индивидуальный подбор ср-в",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "ca04032f-9f39-11e6-8221-985fd33a0f52": {
            "name": "Консультация врача-оториноларинголога повторная",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Оториноларингология",
            "specialtyUid": "eebe3e98-233d-11e2-9496-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "360"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                }
            }
        },
        "ca040343-9f39-11e6-8221-985fd33a0f52": {
            "name": "Надпороговые тесты (тест Фоулера, тест СИСИ, тест Люшера)",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "153"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "170"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "170"
                }
            }
        },
        "ca040337-9f39-11e6-8221-985fd33a0f52": {
            "name": "Пневмомассаж барабанных перепонок",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "ca040340-9f39-11e6-8221-985fd33a0f52": {
            "name": "FTI – тест",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "180"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "ca04032e-9f39-11e6-8221-985fd33a0f52": {
            "name": "Импедансометрия",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "270"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "60bed49e-a4a8-11ea-95ac-54bef7811d01": {
            "name": "Вакцинация от туберкулеза (БЦЖ-М)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Вакцинации",
            "specialtyUid": "70769f2c-8fa7-11ea-82cc-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ca040331-9f39-11e6-8221-985fd33a0f52": {
            "name": "Консультация врача-отоневролога",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Отоневрология",
            "specialtyUid": "ca040330-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "630"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                }
            }
        },
        "ca04033b-9f39-11e6-8221-985fd33a0f52": {
            "name": "Туалет уха с введением лекарственных препаратов в наружный слуховой проход",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "180"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "70769f2d-8fa7-11ea-82cc-40167e631f2e": {
            "name": "Вакцинация от вирусного гепатита В",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Вакцинации",
            "specialtyUid": "70769f2c-8fa7-11ea-82cc-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "dc58bfaf-65b4-11e9-936d-1856809fe650": {
            "name": "Перевязка инфицированной раны малая",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Хирургия",
            "specialtyUid": "ce182405-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                }
            }
        },
        "ad51346c-42f3-11e4-8253-0cd29229b33b": {
            "name": "Проведение профессиональной гигиены одного зуба (снятие наддесневого и поддеснев",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "ca040338-9f39-11e6-8221-985fd33a0f52": {
            "name": "Продувание слуховых труб по Политцеру",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "225"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                }
            }
        },
        "ad51348b-42f3-11e4-8253-0cd29229b33b": {
            "name": "Распломбирование одного корневого канала",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "70769f37-8fa7-11ea-82cc-40167e631f2e": {
            "name": "Вакцинация от коклюша",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Вакцинации",
            "specialtyUid": "70769f2c-8fa7-11ea-82cc-40167e631f2e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "1056e9f3-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Исследование вестибулярной функции",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "126"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "140"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "140"
                }
            }
        },
        "ad513461-42f3-11e4-8253-0cd29229b33b": {
            "name": "Интралигаментарная анестезия",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Общие виды работ",
            "specialtyUid": "ad513456-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                }
            }
        },
        "3252cea6-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Остеоденситометрия длинных трубчатых костей",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Терапия",
            "specialtyUid": "91d8a3f0-d7cf-11e1-bab2-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "270"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ad513472-42f3-11e4-8253-0cd29229b33b": {
            "name": "Одонтометрия 1 зуба",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                }
            }
        },
        "ca040332-9f39-11e6-8221-985fd33a0f52": {
            "name": "Консультация врача-отоневролога повторная",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Отоневрология",
            "specialtyUid": "ca040330-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "360"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                }
            }
        },
        "ad5134af-42f3-11e4-8253-0cd29229b33b": {
            "name": "Перевязка после хирургических вмешательств",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Замещение костного дефект",
            "specialtyUid": "ad5134a8-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                }
            }
        },
        "ca04032c-9f39-11e6-8221-985fd33a0f52": {
            "name": "Консультация врача-оториноларинголога",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Оториноларингология",
            "specialtyUid": "eebe3e98-233d-11e2-9496-1803736d59cd",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "630"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "700"
                }
            }
        },
        "ca04032b-9f39-11e6-8221-985fd33a0f52": {
            "name": "Консультация врача-сурдолога",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "450"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                }
            }
        },
        "dc58bfe9-65b4-11e9-936d-1856809fe650": {
            "name": "Ультразвуковая чистка лица ",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 200"
                }
            }
        },
        "ca040346-9f39-11e6-8221-985fd33a0f52": {
            "name": "Регистрация отоакустической эмиссии задержанной вызванной",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "225"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                }
            }
        },
        "1056e9ff-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Холинэстераза",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "58,5"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "65"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "65"
                }
            }
        },
        "8c9d2b7b-b3cf-11de-8172-001583078ee5": {
            "name": "Тренировка отрицательной аккомодации ",
            "typeOfItem": "Услуга",
            "duration": 1200,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "ad5134c3-42f3-11e4-8253-0cd29229b33b": {
            "name": "Снятие слепков, изготовление моделей, прикусных шаблонов для съемного протезиров",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Съемное протезирование",
            "specialtyUid": "ad5134c1-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                }
            }
        },
        "ad513482-42f3-11e4-8253-0cd29229b33b": {
            "name": "Эстетическая реставрация",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                }
            }
        },
        "dc58bfed-65b4-11e9-936d-1856809fe650": {
            "name": " Мезотерапия Mesoline tight 5 мл",
            "typeOfItem": "Услуга",
            "duration": 5400,
            "specialty": "Дерматология и косметология",
            "specialtyUid": "ce1823fd-5065-11e4-8cb1-c80aa974ec9e",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "4 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "4 000"
                }
            }
        },
        "22d1b486-b34b-11de-8171-001583078ee5": {
            "name": "Исследование цветового зрения",
            "typeOfItem": "Услуга",
            "duration": 1200,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "180"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "200"
                }
            }
        },
        "1056e9e4-fc4b-11e3-87f5-002618dcef2c": {
            "name": "УЗИ органов брюшной полости и почек",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Диагностика",
            "specialtyUid": "3252ceb7-fc4b-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "108"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                }
            }
        },
        "210cf9c7-fd07-11e3-87f5-002618dcef2c": {
            "name": "Прием врача-гинеколога при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "02",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "ad5134b3-42f3-11e4-8253-0cd29229b33b": {
            "name": "Предоперационная премедикация",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Замещение костного дефект",
            "specialtyUid": "ad5134a8-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "320"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "320"
                }
            }
        },
        "ca04033a-9f39-11e6-8221-985fd33a0f52": {
            "name": "Туалет одного уха",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "210cf9cb-fd07-11e3-87f5-002618dcef2c": {
            "name": "Прием врача-невролога при прохождении медосмотра",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "06",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "90"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "100"
                }
            }
        },
        "3252ceae-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Спирометрия с бронходилятационной пробой",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "225"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "250"
                }
            }
        },
        "ad51347a-42f3-11e4-8253-0cd29229b33b": {
            "name": "Постановка одной пломбы из стеклоиномерного цемента по II и III классу по Блеку",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Терапевтическая стоматоло",
            "specialtyUid": "ad513469-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                }
            }
        },
        "ca040335-9f39-11e6-8221-985fd33a0f52": {
            "name": "Подбор слухового аппарата",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "450"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                }
            }
        },
        "9e827020-9f9b-11df-8697-002618dcef2c": {
            "name": "Анализ крови о/с",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "ca040339-9f39-11e6-8221-985fd33a0f52": {
            "name": "Удаление серной пробки из одного уха",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "ca040342-9f39-11e6-8221-985fd33a0f52": {
            "name": "Тональная пороговая аудиометрия в расширенном диапазоне частот",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "315"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "350"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "350"
                }
            }
        },
        "ad5134c0-42f3-11e4-8253-0cd29229b33b": {
            "name": "Изготовление временной коронки из пластмассы клиническим путем",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Несъемное протезирование",
            "specialtyUid": "ad5134bc-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 000"
                }
            }
        },
        "ad5134a2-42f3-11e4-8253-0cd29229b33b": {
            "name": "Гингивэктомия в области одного зуба",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Хирургическая стоматологи",
            "specialtyUid": "ad513492-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 700"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 700"
                }
            }
        },
        "22d1b485-b34b-11de-8171-001583078ee5": {
            "name": "Подбор очков сложной коррекции ",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "1 000"
                }
            }
        },
        "ca040333-9f39-11e6-8221-985fd33a0f52": {
            "name": "Снятие тональной аудиограммы",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "315"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "350"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "350"
                }
            }
        },
        "ad5134ad-42f3-11e4-8253-0cd29229b33b": {
            "name": "Наложение швов",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Замещение костного дефект",
            "specialtyUid": "ad5134a8-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "9fa37e64-4952-11e9-82be-40167e631f2e": {
            "name": "Справка в бассейн (Дети)",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": true,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "500"
                }
            }
        },
        "ca040352-9f39-11e6-8221-985fd33a0f52": {
            "name": "Повторная консультация сурдолога (корректировка настроек слухового аппарата)",
            "typeOfItem": "Услуга",
            "duration": 3600,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "135"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "150"
                }
            }
        },
        "22d1b484-b34b-11de-8171-001583078ee5": {
            "name": "Подбор очков (простая сферическая коррекция)",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                }
            }
        },
        "6e40cc24-4fb5-11e9-82be-40167e631f2e": {
            "name": "Справка 072/у-04 Санаторно-курортная карта",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Профосмотры",
            "specialtyUid": "a1301964-fc33-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": true,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 500"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "2 500"
                }
            }
        },
        "3252cead-fc4b-11e3-87f5-002618dcef2c": {
            "name": "УЗИ  органов-мишеней",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Диагностика",
            "specialtyUid": "3252ceb7-fc4b-11e3-87f5-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "108"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "120"
                }
            }
        },
        "ad51345b-42f3-11e4-8253-0cd29229b33b": {
            "name": "Консультация доктора медицинских наук с определением плана лечения",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Общие виды работ",
            "specialtyUid": "ad513456-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                }
            }
        },
        "9ab45d77-c599-11e1-818b-80c16e5c9fe3": {
            "name": "Анализ мочи",
            "typeOfItem": "Услуга",
            "duration": 600,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "А1",
            "isAnalysis": true,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "300"
                }
            }
        },
        "ca040344-9f39-11e6-8221-985fd33a0f52": {
            "name": "Коротколатентные слуховые вызванные потенциалы (КСВП)",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "162"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "180"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "180"
                }
            }
        },
        "1056e9cc-fc4b-11e3-87f5-002618dcef2c": {
            "name": "Билирубин",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Лабораторные исследования",
            "specialtyUid": "e866ea50-093d-11e2-87b2-002618dcef2c",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "45"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "50"
                }
            }
        },
        "a0230570-3ef7-11de-8086-001485c0d477": {
            "name": "Массаж век",
            "typeOfItem": "Услуга",
            "duration": 1800,
            "specialty": "Офтальмология",
            "specialtyUid": "785d8a6e-5c72-11dd-8423-005056c00008",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "144"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "160"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "160"
                }
            }
        },
        "ad5134ac-42f3-11e4-8253-0cd29229b33b": {
            "name": "Истилляция протока слюнной железы",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Замещение костного дефект",
            "specialtyUid": "ad5134a8-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "800"
                }
            }
        },
        "ca040334-9f39-11e6-8221-985fd33a0f52": {
            "name": "Аудиометрия",
            "typeOfItem": "Услуга",
            "duration": 900,
            "specialty": "Сурдология",
            "specialtyUid": "ca04032a-9f39-11e6-8221-985fd33a0f52",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "4c68deb4-22c3-11df-8618-002618dcef2c": {
                    "priceList": "Прейскурант ДМС",
                    "price": "360"
                },
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "400"
                }
            }
        },
        "ad5134bf-42f3-11e4-8253-0cd29229b33b": {
            "name": "Вкладка культевая разборная",
            "typeOfItem": "Услуга",
            "duration": 0,
            "specialty": "Несъемное протезирование",
            "specialtyUid": "ad5134bc-42f3-11e4-8253-0cd29229b33b",
            "artNumber": "",
            "isAnalysis": false,
            "isMedicalCheck": false,
            "VAT": "",
            "success": true,
            "prices": {
                "f679444a-22b7-11df-8618-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "8 000"
                },
                "66abf7b4-2ff9-11df-8625-002618dcef2c": {
                    "priceList": "Основной прейскурант",
                    "price": "8 000"
                }
            }
        }
    },
    "schedule": [
        {
            "refUid": "ac30e13a-3087-11dc-8594-005056c00008",
            "specialty": "Офтальмология",
            "name": "Барбышева Евгения Петровна",
            "clinicUid": "4c68deb4-22c3-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:30:00",
            "durationInSeconds": 1800,
            "timetable": {
                "free": [],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T18:30:00",
                        "timeEnd": "2022-02-05T21:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T18:30:00",
                        "timeEnd": "2022-02-06T21:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T18:30:00",
                        "timeEnd": "2022-02-09T21:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T18:30:00",
                        "timeEnd": "2022-02-10T21:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T18:30:00",
                        "timeEnd": "2022-02-13T21:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T18:30:00",
                        "timeEnd": "2022-02-14T21:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T18:30:00",
                        "timeEnd": "2022-02-17T21:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T18:30:00",
                        "timeEnd": "2022-02-18T21:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T18:30:00",
                        "timeEnd": "2022-02-21T21:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T18:30:00",
                        "timeEnd": "2022-02-22T21:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T18:30:00",
                        "timeEnd": "2022-02-25T21:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T18:30:00",
                        "timeEnd": "2022-02-26T21:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    }
                ],
                "freeNotFormatted": []
            }
        },
        {
            "refUid": "ac30e13a-3087-11dc-8594-005056c00008",
            "specialty": "Офтальмология",
            "name": "Барбышева Евгения Петровна",
            "clinicUid": "66abf7b4-2ff9-11df-8625-002618dcef2c",
            "duration": "0001-01-01T00:30:00",
            "durationInSeconds": 1800,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T09:00:00",
                        "timeEnd": "2022-02-05T09:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T09:30:00",
                        "timeEnd": "2022-02-05T10:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T10:00:00",
                        "timeEnd": "2022-02-05T10:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T10:30:00",
                        "timeEnd": "2022-02-05T11:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T11:00:00",
                        "timeEnd": "2022-02-05T11:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T11:30:00",
                        "timeEnd": "2022-02-05T12:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T12:00:00",
                        "timeEnd": "2022-02-05T12:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T12:30:00",
                        "timeEnd": "2022-02-05T13:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T09:00:00",
                        "timeEnd": "2022-02-06T09:30:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T09:30:00",
                        "timeEnd": "2022-02-06T10:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T10:00:00",
                        "timeEnd": "2022-02-06T10:30:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T10:30:00",
                        "timeEnd": "2022-02-06T11:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T11:00:00",
                        "timeEnd": "2022-02-06T11:30:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T11:30:00",
                        "timeEnd": "2022-02-06T12:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T12:00:00",
                        "timeEnd": "2022-02-06T12:30:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T12:30:00",
                        "timeEnd": "2022-02-06T13:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T09:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:30:00",
                        "timeEnd": "2022-02-09T10:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T10:00:00",
                        "timeEnd": "2022-02-09T10:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T10:30:00",
                        "timeEnd": "2022-02-09T11:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T11:00:00",
                        "timeEnd": "2022-02-09T11:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T11:30:00",
                        "timeEnd": "2022-02-09T12:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T12:00:00",
                        "timeEnd": "2022-02-09T12:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T12:30:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T09:00:00",
                        "timeEnd": "2022-02-10T09:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T09:30:00",
                        "timeEnd": "2022-02-10T10:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T10:00:00",
                        "timeEnd": "2022-02-10T10:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T10:30:00",
                        "timeEnd": "2022-02-10T11:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T11:00:00",
                        "timeEnd": "2022-02-10T11:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T11:30:00",
                        "timeEnd": "2022-02-10T12:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T12:00:00",
                        "timeEnd": "2022-02-10T12:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T12:30:00",
                        "timeEnd": "2022-02-10T13:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:00:00",
                        "timeEnd": "2022-02-14T09:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:30:00",
                        "timeEnd": "2022-02-14T10:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T10:00:00",
                        "timeEnd": "2022-02-14T10:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T10:30:00",
                        "timeEnd": "2022-02-14T11:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T11:00:00",
                        "timeEnd": "2022-02-14T11:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T11:30:00",
                        "timeEnd": "2022-02-14T12:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T12:00:00",
                        "timeEnd": "2022-02-14T12:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T12:30:00",
                        "timeEnd": "2022-02-14T13:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:00:00",
                        "timeEnd": "2022-02-17T09:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:30:00",
                        "timeEnd": "2022-02-17T10:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T10:00:00",
                        "timeEnd": "2022-02-17T10:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T10:30:00",
                        "timeEnd": "2022-02-17T11:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T11:00:00",
                        "timeEnd": "2022-02-17T11:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T11:30:00",
                        "timeEnd": "2022-02-17T12:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T12:00:00",
                        "timeEnd": "2022-02-17T12:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T12:30:00",
                        "timeEnd": "2022-02-17T13:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T09:00:00",
                        "timeEnd": "2022-02-18T09:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T09:30:00",
                        "timeEnd": "2022-02-18T10:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T10:00:00",
                        "timeEnd": "2022-02-18T10:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T10:30:00",
                        "timeEnd": "2022-02-18T11:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T11:00:00",
                        "timeEnd": "2022-02-18T11:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T11:30:00",
                        "timeEnd": "2022-02-18T12:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T12:00:00",
                        "timeEnd": "2022-02-18T12:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T12:30:00",
                        "timeEnd": "2022-02-18T13:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T09:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:30:00",
                        "timeEnd": "2022-02-21T10:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T10:00:00",
                        "timeEnd": "2022-02-21T10:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T10:30:00",
                        "timeEnd": "2022-02-21T11:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T11:00:00",
                        "timeEnd": "2022-02-21T11:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T11:30:00",
                        "timeEnd": "2022-02-21T12:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T12:00:00",
                        "timeEnd": "2022-02-21T12:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T12:30:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T09:00:00",
                        "timeEnd": "2022-02-25T09:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T09:30:00",
                        "timeEnd": "2022-02-25T10:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T10:00:00",
                        "timeEnd": "2022-02-25T10:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T10:30:00",
                        "timeEnd": "2022-02-25T11:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T11:00:00",
                        "timeEnd": "2022-02-25T11:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T11:30:00",
                        "timeEnd": "2022-02-25T12:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T12:00:00",
                        "timeEnd": "2022-02-25T12:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T12:30:00",
                        "timeEnd": "2022-02-25T13:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T09:00:00",
                        "timeEnd": "2022-02-13T13:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T09:00:00",
                        "timeEnd": "2022-02-22T13:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T09:00:00",
                        "timeEnd": "2022-02-26T13:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T09:00:00",
                        "timeEnd": "2022-02-05T13:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T09:00:00",
                        "timeEnd": "2022-02-06T13:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T09:00:00",
                        "timeEnd": "2022-02-10T13:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:00:00",
                        "timeEnd": "2022-02-14T13:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:00:00",
                        "timeEnd": "2022-02-17T13:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T09:00:00",
                        "timeEnd": "2022-02-18T13:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T09:00:00",
                        "timeEnd": "2022-02-25T13:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ]
            }
        },
        {
            "refUid": "ac30e13a-3087-11dc-8594-005056c00008",
            "specialty": "Офтальмология",
            "name": "Барбышева Евгения Петровна",
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:30:00",
            "durationInSeconds": 1800,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T14:00:00",
                        "timeEnd": "2022-02-05T14:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T14:30:00",
                        "timeEnd": "2022-02-05T15:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T15:00:00",
                        "timeEnd": "2022-02-05T15:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T15:30:00",
                        "timeEnd": "2022-02-05T16:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T16:00:00",
                        "timeEnd": "2022-02-05T16:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T16:30:00",
                        "timeEnd": "2022-02-05T17:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T17:00:00",
                        "timeEnd": "2022-02-05T17:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T17:30:00",
                        "timeEnd": "2022-02-05T18:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T14:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:30:00",
                        "timeEnd": "2022-02-10T15:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T15:00:00",
                        "timeEnd": "2022-02-10T15:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T15:30:00",
                        "timeEnd": "2022-02-10T16:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T16:00:00",
                        "timeEnd": "2022-02-10T16:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T16:30:00",
                        "timeEnd": "2022-02-10T17:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T17:00:00",
                        "timeEnd": "2022-02-10T17:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T17:30:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T14:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:30:00",
                        "timeEnd": "2022-02-21T15:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:00:00",
                        "timeEnd": "2022-02-21T15:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:30:00",
                        "timeEnd": "2022-02-21T16:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:00:00",
                        "timeEnd": "2022-02-21T16:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:30:00",
                        "timeEnd": "2022-02-21T17:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:00:00",
                        "timeEnd": "2022-02-21T17:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:30:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T14:00:00",
                        "timeEnd": "2022-02-06T18:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T14:00:00",
                        "timeEnd": "2022-02-13T18:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T16:20:00",
                        "timeEnd": "2022-02-13T16:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "16:20",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:00:00",
                        "timeEnd": "2022-02-14T18:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:00:00",
                        "timeEnd": "2022-02-18T18:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:00:00",
                        "timeEnd": "2022-02-22T18:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T14:00:00",
                        "timeEnd": "2022-02-26T18:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T14:00:00",
                        "timeEnd": "2022-02-05T18:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ]
            }
        },
        {
            "refUid": "2eb1f97b-6a3c-11e9-936d-1856809fe650",
            "specialty": "Хирургия",
            "name": "Безногов Юрий Сергеевич",
            "clinicUid": "4c68deb4-22c3-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T18:30:00",
                        "timeEnd": "2022-02-05T21:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T18:30:00",
                        "timeEnd": "2022-02-07T21:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T18:30:00",
                        "timeEnd": "2022-02-09T21:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T18:30:00",
                        "timeEnd": "2022-02-11T21:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T18:30:00",
                        "timeEnd": "2022-02-13T21:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T18:30:00",
                        "timeEnd": "2022-02-15T21:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T18:30:00",
                        "timeEnd": "2022-02-17T21:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T18:30:00",
                        "timeEnd": "2022-02-19T21:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T18:30:00",
                        "timeEnd": "2022-02-21T21:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T18:30:00",
                        "timeEnd": "2022-02-23T21:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T18:30:00",
                        "timeEnd": "2022-02-25T21:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T18:30:00",
                        "timeEnd": "2022-02-27T21:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    }
                ],
                "freeNotFormatted": []
            }
        },
        {
            "refUid": "2eb1f97b-6a3c-11e9-936d-1856809fe650",
            "specialty": "Хирургия",
            "name": "Безногов Юрий Сергеевич",
            "clinicUid": "66abf7b4-2ff9-11df-8625-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T09:00:00",
                        "timeEnd": "2022-02-07T09:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T09:30:00",
                        "timeEnd": "2022-02-07T10:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T10:00:00",
                        "timeEnd": "2022-02-07T10:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T10:30:00",
                        "timeEnd": "2022-02-07T11:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T11:00:00",
                        "timeEnd": "2022-02-07T11:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T11:30:00",
                        "timeEnd": "2022-02-07T12:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T12:00:00",
                        "timeEnd": "2022-02-07T12:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T12:30:00",
                        "timeEnd": "2022-02-07T13:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:00:00",
                        "timeEnd": "2022-02-11T09:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:30:00",
                        "timeEnd": "2022-02-11T10:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T10:00:00",
                        "timeEnd": "2022-02-11T10:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T10:30:00",
                        "timeEnd": "2022-02-11T11:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T11:00:00",
                        "timeEnd": "2022-02-11T11:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T11:30:00",
                        "timeEnd": "2022-02-11T12:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T12:00:00",
                        "timeEnd": "2022-02-11T12:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T12:30:00",
                        "timeEnd": "2022-02-11T13:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T09:00:00",
                        "timeEnd": "2022-02-19T09:30:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T09:30:00",
                        "timeEnd": "2022-02-19T10:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T10:00:00",
                        "timeEnd": "2022-02-19T10:30:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T10:30:00",
                        "timeEnd": "2022-02-19T11:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T11:00:00",
                        "timeEnd": "2022-02-19T11:30:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T11:30:00",
                        "timeEnd": "2022-02-19T12:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T12:00:00",
                        "timeEnd": "2022-02-19T12:30:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T12:30:00",
                        "timeEnd": "2022-02-19T13:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T09:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:30:00",
                        "timeEnd": "2022-02-21T10:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T10:00:00",
                        "timeEnd": "2022-02-21T10:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T10:30:00",
                        "timeEnd": "2022-02-21T11:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T11:00:00",
                        "timeEnd": "2022-02-21T11:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T11:30:00",
                        "timeEnd": "2022-02-21T12:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T12:00:00",
                        "timeEnd": "2022-02-21T12:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T12:30:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T09:00:00",
                        "timeEnd": "2022-02-23T09:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T09:30:00",
                        "timeEnd": "2022-02-23T10:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T10:00:00",
                        "timeEnd": "2022-02-23T10:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T10:30:00",
                        "timeEnd": "2022-02-23T11:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T11:00:00",
                        "timeEnd": "2022-02-23T11:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T11:30:00",
                        "timeEnd": "2022-02-23T12:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T12:00:00",
                        "timeEnd": "2022-02-23T12:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T12:30:00",
                        "timeEnd": "2022-02-23T13:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T09:00:00",
                        "timeEnd": "2022-02-25T09:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T09:30:00",
                        "timeEnd": "2022-02-25T10:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T10:00:00",
                        "timeEnd": "2022-02-25T10:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T10:30:00",
                        "timeEnd": "2022-02-25T11:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T11:00:00",
                        "timeEnd": "2022-02-25T11:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T11:30:00",
                        "timeEnd": "2022-02-25T12:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T12:00:00",
                        "timeEnd": "2022-02-25T12:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T12:30:00",
                        "timeEnd": "2022-02-25T13:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T09:00:00",
                        "timeEnd": "2022-02-27T09:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T09:30:00",
                        "timeEnd": "2022-02-27T10:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T10:00:00",
                        "timeEnd": "2022-02-27T10:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T10:30:00",
                        "timeEnd": "2022-02-27T11:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T11:00:00",
                        "timeEnd": "2022-02-27T11:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T11:30:00",
                        "timeEnd": "2022-02-27T12:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T12:00:00",
                        "timeEnd": "2022-02-27T12:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T12:30:00",
                        "timeEnd": "2022-02-27T13:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T09:00:00",
                        "timeEnd": "2022-02-05T13:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T09:00:00",
                        "timeEnd": "2022-02-13T13:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:00:00",
                        "timeEnd": "2022-02-15T13:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:00:00",
                        "timeEnd": "2022-02-17T13:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T09:00:00",
                        "timeEnd": "2022-02-07T13:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:00:00",
                        "timeEnd": "2022-02-11T13:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T09:00:00",
                        "timeEnd": "2022-02-19T13:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T09:00:00",
                        "timeEnd": "2022-02-23T13:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T09:00:00",
                        "timeEnd": "2022-02-25T13:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T09:00:00",
                        "timeEnd": "2022-02-27T13:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ]
            }
        },
        {
            "refUid": "2eb1f97b-6a3c-11e9-936d-1856809fe650",
            "specialty": "Хирургия",
            "name": "Безногов Юрий Сергеевич",
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T14:00:00",
                        "timeEnd": "2022-02-05T14:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T14:30:00",
                        "timeEnd": "2022-02-05T15:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T15:00:00",
                        "timeEnd": "2022-02-05T15:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T15:30:00",
                        "timeEnd": "2022-02-05T16:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T16:00:00",
                        "timeEnd": "2022-02-05T16:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T16:30:00",
                        "timeEnd": "2022-02-05T17:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T17:00:00",
                        "timeEnd": "2022-02-05T17:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T17:30:00",
                        "timeEnd": "2022-02-05T18:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T14:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:30:00",
                        "timeEnd": "2022-02-07T15:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T15:00:00",
                        "timeEnd": "2022-02-07T15:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T15:30:00",
                        "timeEnd": "2022-02-07T16:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:30:00",
                        "timeEnd": "2022-02-07T17:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T17:00:00",
                        "timeEnd": "2022-02-07T17:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T17:30:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T14:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:30:00",
                        "timeEnd": "2022-02-09T15:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:00:00",
                        "timeEnd": "2022-02-09T15:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:30:00",
                        "timeEnd": "2022-02-09T16:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T16:00:00",
                        "timeEnd": "2022-02-09T16:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T16:30:00",
                        "timeEnd": "2022-02-09T17:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:00:00",
                        "timeEnd": "2022-02-09T17:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:30:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T14:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:30:00",
                        "timeEnd": "2022-02-11T15:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T15:00:00",
                        "timeEnd": "2022-02-11T15:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T15:30:00",
                        "timeEnd": "2022-02-11T16:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T16:00:00",
                        "timeEnd": "2022-02-11T16:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T16:30:00",
                        "timeEnd": "2022-02-11T17:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T17:00:00",
                        "timeEnd": "2022-02-11T17:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T17:30:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T14:00:00",
                        "timeEnd": "2022-02-13T14:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T14:30:00",
                        "timeEnd": "2022-02-13T15:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T15:00:00",
                        "timeEnd": "2022-02-13T15:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T15:30:00",
                        "timeEnd": "2022-02-13T16:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T16:00:00",
                        "timeEnd": "2022-02-13T16:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T16:30:00",
                        "timeEnd": "2022-02-13T17:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T17:00:00",
                        "timeEnd": "2022-02-13T17:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T17:30:00",
                        "timeEnd": "2022-02-13T18:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T14:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:30:00",
                        "timeEnd": "2022-02-21T15:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:00:00",
                        "timeEnd": "2022-02-21T15:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:30:00",
                        "timeEnd": "2022-02-21T16:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:00:00",
                        "timeEnd": "2022-02-21T16:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:30:00",
                        "timeEnd": "2022-02-21T17:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:00:00",
                        "timeEnd": "2022-02-21T17:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:30:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:00:00",
                        "timeEnd": "2022-02-23T14:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:30:00",
                        "timeEnd": "2022-02-23T15:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T15:00:00",
                        "timeEnd": "2022-02-23T15:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T15:30:00",
                        "timeEnd": "2022-02-23T16:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T16:00:00",
                        "timeEnd": "2022-02-23T16:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T16:30:00",
                        "timeEnd": "2022-02-23T17:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T17:00:00",
                        "timeEnd": "2022-02-23T17:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T17:30:00",
                        "timeEnd": "2022-02-23T18:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T14:00:00",
                        "timeEnd": "2022-02-27T14:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T14:30:00",
                        "timeEnd": "2022-02-27T15:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T15:00:00",
                        "timeEnd": "2022-02-27T15:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T15:30:00",
                        "timeEnd": "2022-02-27T16:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T16:00:00",
                        "timeEnd": "2022-02-27T16:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T16:30:00",
                        "timeEnd": "2022-02-27T17:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T17:00:00",
                        "timeEnd": "2022-02-27T17:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T17:30:00",
                        "timeEnd": "2022-02-27T18:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:00:00",
                        "timeEnd": "2022-02-07T16:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T14:00:00",
                        "timeEnd": "2022-02-19T18:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T14:00:00",
                        "timeEnd": "2022-02-05T18:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T16:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:30:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T14:00:00",
                        "timeEnd": "2022-02-13T18:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:00:00",
                        "timeEnd": "2022-02-23T18:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T14:00:00",
                        "timeEnd": "2022-02-27T18:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ]
            }
        },
        {
            "refUid": "99868528-0928-11dc-93d1-0004614ae652",
            "specialty": "Терапия",
            "name": "Денисов Дмитрий Алексеевич",
            "clinicUid": "4c68deb4-22c3-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T18:30:00",
                        "timeEnd": "2022-02-04T21:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T18:30:00",
                        "timeEnd": "2022-02-05T21:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T18:30:00",
                        "timeEnd": "2022-02-06T21:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T18:30:00",
                        "timeEnd": "2022-02-07T21:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T18:30:00",
                        "timeEnd": "2022-02-08T21:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T18:30:00",
                        "timeEnd": "2022-02-09T21:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T18:30:00",
                        "timeEnd": "2022-02-10T21:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T18:30:00",
                        "timeEnd": "2022-02-11T21:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T18:30:00",
                        "timeEnd": "2022-02-12T21:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T18:30:00",
                        "timeEnd": "2022-02-13T21:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T18:30:00",
                        "timeEnd": "2022-02-14T21:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T18:30:00",
                        "timeEnd": "2022-02-15T21:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T18:30:00",
                        "timeEnd": "2022-02-16T21:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T18:30:00",
                        "timeEnd": "2022-02-17T21:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T18:30:00",
                        "timeEnd": "2022-02-18T21:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T18:30:00",
                        "timeEnd": "2022-02-19T21:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T18:30:00",
                        "timeEnd": "2022-02-20T21:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T18:30:00",
                        "timeEnd": "2022-02-21T21:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T18:30:00",
                        "timeEnd": "2022-02-22T21:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T18:30:00",
                        "timeEnd": "2022-02-23T21:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T18:30:00",
                        "timeEnd": "2022-02-24T21:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T18:30:00",
                        "timeEnd": "2022-02-25T21:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T18:30:00",
                        "timeEnd": "2022-02-26T21:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T18:30:00",
                        "timeEnd": "2022-02-27T21:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T18:30:00",
                        "timeEnd": "2022-02-28T21:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    }
                ],
                "freeNotFormatted": []
            }
        },
        {
            "refUid": "99868528-0928-11dc-93d1-0004614ae652",
            "specialty": "Терапия",
            "name": "Денисов Дмитрий Алексеевич",
            "clinicUid": "66abf7b4-2ff9-11df-8625-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T09:00:00",
                        "timeEnd": "2022-02-04T09:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T09:30:00",
                        "timeEnd": "2022-02-04T10:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T10:00:00",
                        "timeEnd": "2022-02-04T10:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T10:30:00",
                        "timeEnd": "2022-02-04T11:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T11:00:00",
                        "timeEnd": "2022-02-04T11:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T11:30:00",
                        "timeEnd": "2022-02-04T12:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T12:00:00",
                        "timeEnd": "2022-02-04T12:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T12:30:00",
                        "timeEnd": "2022-02-04T13:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T09:00:00",
                        "timeEnd": "2022-02-05T09:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T09:30:00",
                        "timeEnd": "2022-02-05T10:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T10:00:00",
                        "timeEnd": "2022-02-05T10:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T10:30:00",
                        "timeEnd": "2022-02-05T11:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T11:00:00",
                        "timeEnd": "2022-02-05T11:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T11:30:00",
                        "timeEnd": "2022-02-05T12:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T12:00:00",
                        "timeEnd": "2022-02-05T12:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T12:30:00",
                        "timeEnd": "2022-02-05T13:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T09:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:30:00",
                        "timeEnd": "2022-02-09T10:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T10:00:00",
                        "timeEnd": "2022-02-09T10:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T10:30:00",
                        "timeEnd": "2022-02-09T11:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T11:00:00",
                        "timeEnd": "2022-02-09T11:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T11:30:00",
                        "timeEnd": "2022-02-09T12:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T12:00:00",
                        "timeEnd": "2022-02-09T12:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T12:30:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T09:00:00",
                        "timeEnd": "2022-02-13T09:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T09:30:00",
                        "timeEnd": "2022-02-13T10:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T10:00:00",
                        "timeEnd": "2022-02-13T10:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T10:30:00",
                        "timeEnd": "2022-02-13T11:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T11:00:00",
                        "timeEnd": "2022-02-13T11:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T11:30:00",
                        "timeEnd": "2022-02-13T12:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T12:00:00",
                        "timeEnd": "2022-02-13T12:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T12:30:00",
                        "timeEnd": "2022-02-13T13:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:00:00",
                        "timeEnd": "2022-02-15T09:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:30:00",
                        "timeEnd": "2022-02-15T10:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T10:00:00",
                        "timeEnd": "2022-02-15T10:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T10:30:00",
                        "timeEnd": "2022-02-15T11:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T11:00:00",
                        "timeEnd": "2022-02-15T11:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T11:30:00",
                        "timeEnd": "2022-02-15T12:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T12:00:00",
                        "timeEnd": "2022-02-15T12:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T12:30:00",
                        "timeEnd": "2022-02-15T13:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T09:00:00",
                        "timeEnd": "2022-02-18T09:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T09:30:00",
                        "timeEnd": "2022-02-18T10:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T10:00:00",
                        "timeEnd": "2022-02-18T10:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T10:30:00",
                        "timeEnd": "2022-02-18T11:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T11:00:00",
                        "timeEnd": "2022-02-18T11:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T11:30:00",
                        "timeEnd": "2022-02-18T12:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T12:00:00",
                        "timeEnd": "2022-02-18T12:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T12:30:00",
                        "timeEnd": "2022-02-18T13:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T09:00:00",
                        "timeEnd": "2022-02-20T09:30:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T09:30:00",
                        "timeEnd": "2022-02-20T10:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T10:00:00",
                        "timeEnd": "2022-02-20T10:30:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T10:30:00",
                        "timeEnd": "2022-02-20T11:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T11:00:00",
                        "timeEnd": "2022-02-20T11:30:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T11:30:00",
                        "timeEnd": "2022-02-20T12:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T12:00:00",
                        "timeEnd": "2022-02-20T12:30:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T12:30:00",
                        "timeEnd": "2022-02-20T13:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T09:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:30:00",
                        "timeEnd": "2022-02-21T10:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T10:00:00",
                        "timeEnd": "2022-02-21T10:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T10:30:00",
                        "timeEnd": "2022-02-21T11:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T11:00:00",
                        "timeEnd": "2022-02-21T11:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T11:30:00",
                        "timeEnd": "2022-02-21T12:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T12:00:00",
                        "timeEnd": "2022-02-21T12:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T12:30:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T09:00:00",
                        "timeEnd": "2022-02-22T09:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T09:30:00",
                        "timeEnd": "2022-02-22T10:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T10:00:00",
                        "timeEnd": "2022-02-22T10:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T10:30:00",
                        "timeEnd": "2022-02-22T11:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T11:00:00",
                        "timeEnd": "2022-02-22T11:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T11:30:00",
                        "timeEnd": "2022-02-22T12:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T12:00:00",
                        "timeEnd": "2022-02-22T12:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T12:30:00",
                        "timeEnd": "2022-02-22T13:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T09:00:00",
                        "timeEnd": "2022-02-27T09:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T09:30:00",
                        "timeEnd": "2022-02-27T10:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T10:00:00",
                        "timeEnd": "2022-02-27T10:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T10:30:00",
                        "timeEnd": "2022-02-27T11:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T11:00:00",
                        "timeEnd": "2022-02-27T11:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T11:30:00",
                        "timeEnd": "2022-02-27T12:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T12:00:00",
                        "timeEnd": "2022-02-27T12:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T12:30:00",
                        "timeEnd": "2022-02-27T13:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T09:00:00",
                        "timeEnd": "2022-02-28T09:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T09:30:00",
                        "timeEnd": "2022-02-28T10:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T10:00:00",
                        "timeEnd": "2022-02-28T10:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T10:30:00",
                        "timeEnd": "2022-02-28T11:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T11:00:00",
                        "timeEnd": "2022-02-28T11:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T11:30:00",
                        "timeEnd": "2022-02-28T12:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T12:00:00",
                        "timeEnd": "2022-02-28T12:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T12:30:00",
                        "timeEnd": "2022-02-28T13:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T09:00:00",
                        "timeEnd": "2022-02-06T13:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T09:00:00",
                        "timeEnd": "2022-02-07T13:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T09:00:00",
                        "timeEnd": "2022-02-08T13:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T09:00:00",
                        "timeEnd": "2022-02-10T13:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:00:00",
                        "timeEnd": "2022-02-11T13:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T09:00:00",
                        "timeEnd": "2022-02-12T13:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:00:00",
                        "timeEnd": "2022-02-14T13:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T09:00:00",
                        "timeEnd": "2022-02-16T13:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:00:00",
                        "timeEnd": "2022-02-17T13:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T09:00:00",
                        "timeEnd": "2022-02-19T13:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T09:00:00",
                        "timeEnd": "2022-02-23T13:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T09:00:00",
                        "timeEnd": "2022-02-24T13:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T09:00:00",
                        "timeEnd": "2022-02-25T13:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T09:00:00",
                        "timeEnd": "2022-02-26T13:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T09:00:00",
                        "timeEnd": "2022-02-04T13:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T09:00:00",
                        "timeEnd": "2022-02-05T13:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T09:00:00",
                        "timeEnd": "2022-02-13T13:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:00:00",
                        "timeEnd": "2022-02-15T13:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T09:00:00",
                        "timeEnd": "2022-02-18T13:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T09:00:00",
                        "timeEnd": "2022-02-20T13:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T09:00:00",
                        "timeEnd": "2022-02-22T13:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T09:00:00",
                        "timeEnd": "2022-02-27T13:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T09:00:00",
                        "timeEnd": "2022-02-28T13:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ]
            }
        },
        {
            "refUid": "99868528-0928-11dc-93d1-0004614ae652",
            "specialty": "Терапия",
            "name": "Денисов Дмитрий Алексеевич",
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T14:00:00",
                        "timeEnd": "2022-02-06T14:30:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T14:30:00",
                        "timeEnd": "2022-02-06T15:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T15:00:00",
                        "timeEnd": "2022-02-06T15:30:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T15:30:00",
                        "timeEnd": "2022-02-06T16:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T16:00:00",
                        "timeEnd": "2022-02-06T16:30:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T16:30:00",
                        "timeEnd": "2022-02-06T17:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T17:00:00",
                        "timeEnd": "2022-02-06T17:30:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T17:30:00",
                        "timeEnd": "2022-02-06T18:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T14:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:30:00",
                        "timeEnd": "2022-02-07T15:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T15:00:00",
                        "timeEnd": "2022-02-07T15:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T15:30:00",
                        "timeEnd": "2022-02-07T16:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:00:00",
                        "timeEnd": "2022-02-07T16:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:30:00",
                        "timeEnd": "2022-02-07T17:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T17:00:00",
                        "timeEnd": "2022-02-07T17:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T17:30:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T14:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:30:00",
                        "timeEnd": "2022-02-10T15:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T15:00:00",
                        "timeEnd": "2022-02-10T15:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T15:30:00",
                        "timeEnd": "2022-02-10T16:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T16:00:00",
                        "timeEnd": "2022-02-10T16:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T16:30:00",
                        "timeEnd": "2022-02-10T17:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T17:00:00",
                        "timeEnd": "2022-02-10T17:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T17:30:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T14:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:30:00",
                        "timeEnd": "2022-02-11T15:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T15:00:00",
                        "timeEnd": "2022-02-11T15:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T15:30:00",
                        "timeEnd": "2022-02-11T16:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T16:00:00",
                        "timeEnd": "2022-02-11T16:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T16:30:00",
                        "timeEnd": "2022-02-11T17:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T17:00:00",
                        "timeEnd": "2022-02-11T17:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T17:30:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T14:00:00",
                        "timeEnd": "2022-02-12T14:30:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T14:30:00",
                        "timeEnd": "2022-02-12T15:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T15:00:00",
                        "timeEnd": "2022-02-12T15:30:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T15:30:00",
                        "timeEnd": "2022-02-12T16:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T16:00:00",
                        "timeEnd": "2022-02-12T16:30:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T16:30:00",
                        "timeEnd": "2022-02-12T17:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T17:00:00",
                        "timeEnd": "2022-02-12T17:30:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T17:30:00",
                        "timeEnd": "2022-02-12T18:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T14:00:00",
                        "timeEnd": "2022-02-13T14:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T14:30:00",
                        "timeEnd": "2022-02-13T15:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T15:00:00",
                        "timeEnd": "2022-02-13T15:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T15:30:00",
                        "timeEnd": "2022-02-13T16:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T16:00:00",
                        "timeEnd": "2022-02-13T16:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T16:30:00",
                        "timeEnd": "2022-02-13T17:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T17:00:00",
                        "timeEnd": "2022-02-13T17:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T17:30:00",
                        "timeEnd": "2022-02-13T18:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T14:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:30:00",
                        "timeEnd": "2022-02-15T15:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T15:00:00",
                        "timeEnd": "2022-02-15T15:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T15:30:00",
                        "timeEnd": "2022-02-15T16:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T16:00:00",
                        "timeEnd": "2022-02-15T16:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T16:30:00",
                        "timeEnd": "2022-02-15T17:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T17:00:00",
                        "timeEnd": "2022-02-15T17:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T17:30:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:00:00",
                        "timeEnd": "2022-02-16T14:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:30:00",
                        "timeEnd": "2022-02-16T15:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T15:00:00",
                        "timeEnd": "2022-02-16T15:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T15:30:00",
                        "timeEnd": "2022-02-16T16:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T16:00:00",
                        "timeEnd": "2022-02-16T16:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T16:30:00",
                        "timeEnd": "2022-02-16T17:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T17:00:00",
                        "timeEnd": "2022-02-16T17:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T17:30:00",
                        "timeEnd": "2022-02-16T18:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:00:00",
                        "timeEnd": "2022-02-18T14:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:30:00",
                        "timeEnd": "2022-02-18T15:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T15:00:00",
                        "timeEnd": "2022-02-18T15:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T15:30:00",
                        "timeEnd": "2022-02-18T16:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T16:00:00",
                        "timeEnd": "2022-02-18T16:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T16:30:00",
                        "timeEnd": "2022-02-18T17:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T17:00:00",
                        "timeEnd": "2022-02-18T17:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T17:30:00",
                        "timeEnd": "2022-02-18T18:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T14:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:30:00",
                        "timeEnd": "2022-02-21T15:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:00:00",
                        "timeEnd": "2022-02-21T15:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:30:00",
                        "timeEnd": "2022-02-21T16:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:00:00",
                        "timeEnd": "2022-02-21T16:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:30:00",
                        "timeEnd": "2022-02-21T17:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:00:00",
                        "timeEnd": "2022-02-21T17:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:30:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T14:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:30:00",
                        "timeEnd": "2022-02-25T15:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T15:00:00",
                        "timeEnd": "2022-02-25T15:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T15:30:00",
                        "timeEnd": "2022-02-25T16:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T16:00:00",
                        "timeEnd": "2022-02-25T16:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T16:30:00",
                        "timeEnd": "2022-02-25T17:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T17:00:00",
                        "timeEnd": "2022-02-25T17:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T17:30:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T14:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:30:00",
                        "timeEnd": "2022-02-28T15:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T15:00:00",
                        "timeEnd": "2022-02-28T15:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T15:30:00",
                        "timeEnd": "2022-02-28T16:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T16:00:00",
                        "timeEnd": "2022-02-28T16:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T16:30:00",
                        "timeEnd": "2022-02-28T17:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T17:00:00",
                        "timeEnd": "2022-02-28T17:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T17:30:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:00:00",
                        "timeEnd": "2022-02-04T18:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T14:00:00",
                        "timeEnd": "2022-02-05T18:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:00:00",
                        "timeEnd": "2022-02-08T18:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:00:00",
                        "timeEnd": "2022-02-14T18:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T14:00:00",
                        "timeEnd": "2022-02-19T18:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T14:00:00",
                        "timeEnd": "2022-02-20T18:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:00:00",
                        "timeEnd": "2022-02-22T18:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:00:00",
                        "timeEnd": "2022-02-23T18:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:00:00",
                        "timeEnd": "2022-02-24T18:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T14:00:00",
                        "timeEnd": "2022-02-26T18:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T14:00:00",
                        "timeEnd": "2022-02-27T18:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T14:00:00",
                        "timeEnd": "2022-02-06T18:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T14:00:00",
                        "timeEnd": "2022-02-12T18:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T14:00:00",
                        "timeEnd": "2022-02-13T18:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:00:00",
                        "timeEnd": "2022-02-16T18:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:00:00",
                        "timeEnd": "2022-02-18T18:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ]
            }
        },
        {
            "refUid": "ac30e139-3087-11dc-8594-005056c00008",
            "specialty": "Диагностика",
            "name": "Иванова Ксения Андреевна",
            "clinicUid": "4c68deb4-22c3-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T18:30:00",
                        "timeEnd": "2022-02-04T21:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T18:30:00",
                        "timeEnd": "2022-02-05T21:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T18:30:00",
                        "timeEnd": "2022-02-06T21:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T18:30:00",
                        "timeEnd": "2022-02-09T21:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T18:30:00",
                        "timeEnd": "2022-02-10T21:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T18:30:00",
                        "timeEnd": "2022-02-11T21:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T18:30:00",
                        "timeEnd": "2022-02-12T21:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T18:30:00",
                        "timeEnd": "2022-02-13T21:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T18:30:00",
                        "timeEnd": "2022-02-16T21:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T18:30:00",
                        "timeEnd": "2022-02-17T21:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T18:30:00",
                        "timeEnd": "2022-02-18T21:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T18:30:00",
                        "timeEnd": "2022-02-19T21:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T18:30:00",
                        "timeEnd": "2022-02-20T21:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T18:30:00",
                        "timeEnd": "2022-02-23T21:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T18:30:00",
                        "timeEnd": "2022-02-24T21:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T18:30:00",
                        "timeEnd": "2022-02-25T21:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T18:30:00",
                        "timeEnd": "2022-02-26T21:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T18:30:00",
                        "timeEnd": "2022-02-27T21:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    }
                ],
                "freeNotFormatted": []
            }
        },
        {
            "refUid": "ac30e139-3087-11dc-8594-005056c00008",
            "specialty": "Диагностика",
            "name": "Иванова Ксения Андреевна",
            "clinicUid": "66abf7b4-2ff9-11df-8625-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T09:00:00",
                        "timeEnd": "2022-02-04T09:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T09:30:00",
                        "timeEnd": "2022-02-04T10:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T10:00:00",
                        "timeEnd": "2022-02-04T10:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T10:30:00",
                        "timeEnd": "2022-02-04T11:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T11:00:00",
                        "timeEnd": "2022-02-04T11:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T11:30:00",
                        "timeEnd": "2022-02-04T12:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T12:00:00",
                        "timeEnd": "2022-02-04T12:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T12:30:00",
                        "timeEnd": "2022-02-04T13:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T09:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:30:00",
                        "timeEnd": "2022-02-09T10:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T10:00:00",
                        "timeEnd": "2022-02-09T10:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T10:30:00",
                        "timeEnd": "2022-02-09T11:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T11:00:00",
                        "timeEnd": "2022-02-09T11:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T11:30:00",
                        "timeEnd": "2022-02-09T12:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T12:00:00",
                        "timeEnd": "2022-02-09T12:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T12:30:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:00:00",
                        "timeEnd": "2022-02-11T09:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:30:00",
                        "timeEnd": "2022-02-11T10:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T10:00:00",
                        "timeEnd": "2022-02-11T10:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T10:30:00",
                        "timeEnd": "2022-02-11T11:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T11:00:00",
                        "timeEnd": "2022-02-11T11:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T11:30:00",
                        "timeEnd": "2022-02-11T12:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T12:00:00",
                        "timeEnd": "2022-02-11T12:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T12:30:00",
                        "timeEnd": "2022-02-11T13:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:00:00",
                        "timeEnd": "2022-02-15T09:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:30:00",
                        "timeEnd": "2022-02-15T10:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T10:00:00",
                        "timeEnd": "2022-02-15T10:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T10:30:00",
                        "timeEnd": "2022-02-15T11:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T11:00:00",
                        "timeEnd": "2022-02-15T11:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T11:30:00",
                        "timeEnd": "2022-02-15T12:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T12:00:00",
                        "timeEnd": "2022-02-15T12:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T12:30:00",
                        "timeEnd": "2022-02-15T13:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:00:00",
                        "timeEnd": "2022-02-17T09:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:30:00",
                        "timeEnd": "2022-02-17T10:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T10:00:00",
                        "timeEnd": "2022-02-17T10:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T10:30:00",
                        "timeEnd": "2022-02-17T11:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T11:00:00",
                        "timeEnd": "2022-02-17T11:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T11:30:00",
                        "timeEnd": "2022-02-17T12:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T12:00:00",
                        "timeEnd": "2022-02-17T12:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T12:30:00",
                        "timeEnd": "2022-02-17T13:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T09:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:30:00",
                        "timeEnd": "2022-02-21T10:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T10:00:00",
                        "timeEnd": "2022-02-21T10:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T10:30:00",
                        "timeEnd": "2022-02-21T11:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T11:00:00",
                        "timeEnd": "2022-02-21T11:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T11:30:00",
                        "timeEnd": "2022-02-21T12:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T12:00:00",
                        "timeEnd": "2022-02-21T12:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T12:30:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T09:00:00",
                        "timeEnd": "2022-02-28T09:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T09:30:00",
                        "timeEnd": "2022-02-28T10:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T10:00:00",
                        "timeEnd": "2022-02-28T10:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T10:30:00",
                        "timeEnd": "2022-02-28T11:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T11:00:00",
                        "timeEnd": "2022-02-28T11:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T11:30:00",
                        "timeEnd": "2022-02-28T12:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T12:00:00",
                        "timeEnd": "2022-02-28T12:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T12:30:00",
                        "timeEnd": "2022-02-28T13:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T09:00:00",
                        "timeEnd": "2022-02-07T13:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T09:00:00",
                        "timeEnd": "2022-02-08T13:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T09:00:00",
                        "timeEnd": "2022-02-10T13:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:00:00",
                        "timeEnd": "2022-02-14T13:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T09:00:00",
                        "timeEnd": "2022-02-16T13:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T09:00:00",
                        "timeEnd": "2022-02-18T13:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T09:00:00",
                        "timeEnd": "2022-02-22T13:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T09:00:00",
                        "timeEnd": "2022-02-23T13:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T09:00:00",
                        "timeEnd": "2022-02-24T13:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T09:00:00",
                        "timeEnd": "2022-02-25T13:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T09:00:00",
                        "timeEnd": "2022-02-04T13:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:00:00",
                        "timeEnd": "2022-02-11T13:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:00:00",
                        "timeEnd": "2022-02-15T13:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:00:00",
                        "timeEnd": "2022-02-17T13:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T09:00:00",
                        "timeEnd": "2022-02-28T13:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ]
            }
        },
        {
            "refUid": "ac30e139-3087-11dc-8594-005056c00008",
            "specialty": "Диагностика",
            "name": "Иванова Ксения Андреевна",
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:00:00",
                        "timeEnd": "2022-02-04T14:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:30:00",
                        "timeEnd": "2022-02-04T15:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T15:00:00",
                        "timeEnd": "2022-02-04T15:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T15:30:00",
                        "timeEnd": "2022-02-04T16:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T16:00:00",
                        "timeEnd": "2022-02-04T16:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T16:30:00",
                        "timeEnd": "2022-02-04T17:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T17:00:00",
                        "timeEnd": "2022-02-04T17:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T17:30:00",
                        "timeEnd": "2022-02-04T18:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T14:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:30:00",
                        "timeEnd": "2022-02-07T15:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T15:00:00",
                        "timeEnd": "2022-02-07T15:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T15:30:00",
                        "timeEnd": "2022-02-07T16:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:00:00",
                        "timeEnd": "2022-02-07T16:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:30:00",
                        "timeEnd": "2022-02-07T17:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T17:00:00",
                        "timeEnd": "2022-02-07T17:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T17:30:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T14:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:30:00",
                        "timeEnd": "2022-02-09T15:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:00:00",
                        "timeEnd": "2022-02-09T15:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:30:00",
                        "timeEnd": "2022-02-09T16:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T16:00:00",
                        "timeEnd": "2022-02-09T16:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T16:30:00",
                        "timeEnd": "2022-02-09T17:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:00:00",
                        "timeEnd": "2022-02-09T17:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:30:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T14:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:30:00",
                        "timeEnd": "2022-02-10T15:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T15:00:00",
                        "timeEnd": "2022-02-10T15:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T15:30:00",
                        "timeEnd": "2022-02-10T16:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T16:00:00",
                        "timeEnd": "2022-02-10T16:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T16:30:00",
                        "timeEnd": "2022-02-10T17:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T17:00:00",
                        "timeEnd": "2022-02-10T17:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T17:30:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:00:00",
                        "timeEnd": "2022-02-14T14:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:30:00",
                        "timeEnd": "2022-02-14T15:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T15:00:00",
                        "timeEnd": "2022-02-14T15:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T15:30:00",
                        "timeEnd": "2022-02-14T16:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T16:00:00",
                        "timeEnd": "2022-02-14T16:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T16:30:00",
                        "timeEnd": "2022-02-14T17:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T17:00:00",
                        "timeEnd": "2022-02-14T17:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T17:30:00",
                        "timeEnd": "2022-02-14T18:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T14:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:30:00",
                        "timeEnd": "2022-02-15T15:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T15:00:00",
                        "timeEnd": "2022-02-15T15:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T15:30:00",
                        "timeEnd": "2022-02-15T16:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T16:00:00",
                        "timeEnd": "2022-02-15T16:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T16:30:00",
                        "timeEnd": "2022-02-15T17:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T17:00:00",
                        "timeEnd": "2022-02-15T17:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T17:30:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:00:00",
                        "timeEnd": "2022-02-16T14:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:30:00",
                        "timeEnd": "2022-02-16T15:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T15:00:00",
                        "timeEnd": "2022-02-16T15:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T15:30:00",
                        "timeEnd": "2022-02-16T16:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T16:00:00",
                        "timeEnd": "2022-02-16T16:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T16:30:00",
                        "timeEnd": "2022-02-16T17:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T17:00:00",
                        "timeEnd": "2022-02-16T17:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T17:30:00",
                        "timeEnd": "2022-02-16T18:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T14:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:30:00",
                        "timeEnd": "2022-02-17T15:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T15:00:00",
                        "timeEnd": "2022-02-17T15:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T15:30:00",
                        "timeEnd": "2022-02-17T16:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T16:00:00",
                        "timeEnd": "2022-02-17T16:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T16:30:00",
                        "timeEnd": "2022-02-17T17:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T17:00:00",
                        "timeEnd": "2022-02-17T17:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T17:30:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:00:00",
                        "timeEnd": "2022-02-18T14:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:30:00",
                        "timeEnd": "2022-02-18T15:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T15:00:00",
                        "timeEnd": "2022-02-18T15:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T15:30:00",
                        "timeEnd": "2022-02-18T16:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T16:00:00",
                        "timeEnd": "2022-02-18T16:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T16:30:00",
                        "timeEnd": "2022-02-18T17:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T17:00:00",
                        "timeEnd": "2022-02-18T17:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T17:30:00",
                        "timeEnd": "2022-02-18T18:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T14:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:30:00",
                        "timeEnd": "2022-02-21T15:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:00:00",
                        "timeEnd": "2022-02-21T15:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:30:00",
                        "timeEnd": "2022-02-21T16:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:00:00",
                        "timeEnd": "2022-02-21T16:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:30:00",
                        "timeEnd": "2022-02-21T17:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:00:00",
                        "timeEnd": "2022-02-21T17:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:30:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T14:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:30:00",
                        "timeEnd": "2022-02-25T15:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T15:00:00",
                        "timeEnd": "2022-02-25T15:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T15:30:00",
                        "timeEnd": "2022-02-25T16:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T16:00:00",
                        "timeEnd": "2022-02-25T16:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T16:30:00",
                        "timeEnd": "2022-02-25T17:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T17:00:00",
                        "timeEnd": "2022-02-25T17:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T17:30:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T14:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:30:00",
                        "timeEnd": "2022-02-28T15:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T15:00:00",
                        "timeEnd": "2022-02-28T15:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T15:30:00",
                        "timeEnd": "2022-02-28T16:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T16:00:00",
                        "timeEnd": "2022-02-28T16:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T16:30:00",
                        "timeEnd": "2022-02-28T17:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T17:00:00",
                        "timeEnd": "2022-02-28T17:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T17:30:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:00:00",
                        "timeEnd": "2022-02-08T18:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:00:00",
                        "timeEnd": "2022-02-22T18:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:00:00",
                        "timeEnd": "2022-02-23T18:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:00:00",
                        "timeEnd": "2022-02-24T18:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:00:00",
                        "timeEnd": "2022-02-04T18:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:00:00",
                        "timeEnd": "2022-02-14T18:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:00:00",
                        "timeEnd": "2022-02-16T18:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:00:00",
                        "timeEnd": "2022-02-18T18:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ]
            }
        },
        {
            "refUid": "6cf96534-3870-11e2-87ba-002618dcef2c",
            "specialty": "Кардиология",
            "name": "Ковалёва Ирина Михайловна",
            "clinicUid": "4c68deb4-22c3-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T18:30:00",
                        "timeEnd": "2022-02-04T21:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T18:30:00",
                        "timeEnd": "2022-02-05T21:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T18:30:00",
                        "timeEnd": "2022-02-06T21:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T18:30:00",
                        "timeEnd": "2022-02-07T21:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T18:30:00",
                        "timeEnd": "2022-02-08T21:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T18:30:00",
                        "timeEnd": "2022-02-09T21:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T18:30:00",
                        "timeEnd": "2022-02-10T21:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T18:30:00",
                        "timeEnd": "2022-02-11T21:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T18:30:00",
                        "timeEnd": "2022-02-12T21:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T18:30:00",
                        "timeEnd": "2022-02-13T21:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T18:30:00",
                        "timeEnd": "2022-02-14T21:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T18:30:00",
                        "timeEnd": "2022-02-15T21:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T18:30:00",
                        "timeEnd": "2022-02-16T21:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T18:30:00",
                        "timeEnd": "2022-02-17T21:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T18:30:00",
                        "timeEnd": "2022-02-18T21:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T18:30:00",
                        "timeEnd": "2022-02-19T21:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T18:30:00",
                        "timeEnd": "2022-02-20T21:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T18:30:00",
                        "timeEnd": "2022-02-21T21:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T18:30:00",
                        "timeEnd": "2022-02-22T21:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T18:30:00",
                        "timeEnd": "2022-02-23T21:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T18:30:00",
                        "timeEnd": "2022-02-24T21:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T18:30:00",
                        "timeEnd": "2022-02-25T21:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T18:30:00",
                        "timeEnd": "2022-02-26T21:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T18:30:00",
                        "timeEnd": "2022-02-27T21:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T18:30:00",
                        "timeEnd": "2022-02-28T21:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    }
                ],
                "freeNotFormatted": []
            }
        },
        {
            "refUid": "6cf96534-3870-11e2-87ba-002618dcef2c",
            "specialty": "Кардиология",
            "name": "Ковалёва Ирина Михайловна",
            "clinicUid": "66abf7b4-2ff9-11df-8625-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T09:00:00",
                        "timeEnd": "2022-02-04T13:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T09:00:00",
                        "timeEnd": "2022-02-05T13:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T09:00:00",
                        "timeEnd": "2022-02-06T13:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T09:00:00",
                        "timeEnd": "2022-02-07T13:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T09:00:00",
                        "timeEnd": "2022-02-08T13:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T09:00:00",
                        "timeEnd": "2022-02-10T13:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:00:00",
                        "timeEnd": "2022-02-11T13:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T09:00:00",
                        "timeEnd": "2022-02-12T13:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T09:00:00",
                        "timeEnd": "2022-02-13T13:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:00:00",
                        "timeEnd": "2022-02-14T13:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:00:00",
                        "timeEnd": "2022-02-15T13:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T09:00:00",
                        "timeEnd": "2022-02-16T13:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:00:00",
                        "timeEnd": "2022-02-17T13:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T09:00:00",
                        "timeEnd": "2022-02-18T13:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T09:00:00",
                        "timeEnd": "2022-02-19T13:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T09:00:00",
                        "timeEnd": "2022-02-20T13:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T09:00:00",
                        "timeEnd": "2022-02-22T13:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T09:00:00",
                        "timeEnd": "2022-02-23T13:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T09:00:00",
                        "timeEnd": "2022-02-24T13:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T09:00:00",
                        "timeEnd": "2022-02-25T13:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T09:00:00",
                        "timeEnd": "2022-02-26T13:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T09:00:00",
                        "timeEnd": "2022-02-27T13:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T09:00:00",
                        "timeEnd": "2022-02-28T13:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "freeNotFormatted": []
            }
        },
        {
            "refUid": "6cf96534-3870-11e2-87ba-002618dcef2c",
            "specialty": "Кардиология",
            "name": "Ковалёва Ирина Михайловна",
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:00:00",
                        "timeEnd": "2022-02-04T14:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:30:00",
                        "timeEnd": "2022-02-04T15:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T15:00:00",
                        "timeEnd": "2022-02-04T15:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T15:30:00",
                        "timeEnd": "2022-02-04T16:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T16:00:00",
                        "timeEnd": "2022-02-04T16:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T16:30:00",
                        "timeEnd": "2022-02-04T17:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T17:00:00",
                        "timeEnd": "2022-02-04T17:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T17:30:00",
                        "timeEnd": "2022-02-04T18:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T15:00:00",
                        "timeEnd": "2022-02-05T15:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T15:30:00",
                        "timeEnd": "2022-02-05T16:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T16:00:00",
                        "timeEnd": "2022-02-05T16:30:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T16:30:00",
                        "timeEnd": "2022-02-05T17:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T15:00:00",
                        "timeEnd": "2022-02-06T15:30:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T15:30:00",
                        "timeEnd": "2022-02-06T16:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T16:00:00",
                        "timeEnd": "2022-02-06T16:30:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T16:30:00",
                        "timeEnd": "2022-02-06T17:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T14:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:30:00",
                        "timeEnd": "2022-02-07T15:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T15:00:00",
                        "timeEnd": "2022-02-07T15:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T15:30:00",
                        "timeEnd": "2022-02-07T16:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:00:00",
                        "timeEnd": "2022-02-07T16:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:30:00",
                        "timeEnd": "2022-02-07T17:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T17:00:00",
                        "timeEnd": "2022-02-07T17:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T17:30:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:00:00",
                        "timeEnd": "2022-02-08T14:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:30:00",
                        "timeEnd": "2022-02-08T15:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T15:00:00",
                        "timeEnd": "2022-02-08T15:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T15:30:00",
                        "timeEnd": "2022-02-08T16:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T16:00:00",
                        "timeEnd": "2022-02-08T16:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T16:30:00",
                        "timeEnd": "2022-02-08T17:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T17:00:00",
                        "timeEnd": "2022-02-08T17:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T17:30:00",
                        "timeEnd": "2022-02-08T18:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T14:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:30:00",
                        "timeEnd": "2022-02-09T15:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:00:00",
                        "timeEnd": "2022-02-09T15:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:30:00",
                        "timeEnd": "2022-02-09T16:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T16:00:00",
                        "timeEnd": "2022-02-09T16:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T16:30:00",
                        "timeEnd": "2022-02-09T17:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:00:00",
                        "timeEnd": "2022-02-09T17:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:30:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T14:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:30:00",
                        "timeEnd": "2022-02-10T15:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T15:00:00",
                        "timeEnd": "2022-02-10T15:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T15:30:00",
                        "timeEnd": "2022-02-10T16:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T16:00:00",
                        "timeEnd": "2022-02-10T16:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T16:30:00",
                        "timeEnd": "2022-02-10T17:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T17:00:00",
                        "timeEnd": "2022-02-10T17:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T17:30:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T14:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:30:00",
                        "timeEnd": "2022-02-11T15:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T15:00:00",
                        "timeEnd": "2022-02-11T15:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T15:30:00",
                        "timeEnd": "2022-02-11T16:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T16:00:00",
                        "timeEnd": "2022-02-11T16:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T16:30:00",
                        "timeEnd": "2022-02-11T17:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T17:00:00",
                        "timeEnd": "2022-02-11T17:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T17:30:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T15:00:00",
                        "timeEnd": "2022-02-12T15:30:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T15:30:00",
                        "timeEnd": "2022-02-12T16:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T16:00:00",
                        "timeEnd": "2022-02-12T16:30:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T16:30:00",
                        "timeEnd": "2022-02-12T17:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T15:00:00",
                        "timeEnd": "2022-02-13T15:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T15:30:00",
                        "timeEnd": "2022-02-13T16:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T16:00:00",
                        "timeEnd": "2022-02-13T16:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T16:30:00",
                        "timeEnd": "2022-02-13T17:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T14:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:30:00",
                        "timeEnd": "2022-02-15T15:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T15:00:00",
                        "timeEnd": "2022-02-15T15:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T15:30:00",
                        "timeEnd": "2022-02-15T16:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T16:00:00",
                        "timeEnd": "2022-02-15T16:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T16:30:00",
                        "timeEnd": "2022-02-15T17:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T17:00:00",
                        "timeEnd": "2022-02-15T17:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T17:30:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:00:00",
                        "timeEnd": "2022-02-16T14:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:30:00",
                        "timeEnd": "2022-02-16T15:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T15:00:00",
                        "timeEnd": "2022-02-16T15:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T15:30:00",
                        "timeEnd": "2022-02-16T16:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T16:00:00",
                        "timeEnd": "2022-02-16T16:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T16:30:00",
                        "timeEnd": "2022-02-16T17:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T17:00:00",
                        "timeEnd": "2022-02-16T17:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T17:30:00",
                        "timeEnd": "2022-02-16T18:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:00:00",
                        "timeEnd": "2022-02-18T14:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:30:00",
                        "timeEnd": "2022-02-18T15:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T15:00:00",
                        "timeEnd": "2022-02-18T15:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T15:30:00",
                        "timeEnd": "2022-02-18T16:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T16:00:00",
                        "timeEnd": "2022-02-18T16:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T16:30:00",
                        "timeEnd": "2022-02-18T17:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T17:00:00",
                        "timeEnd": "2022-02-18T17:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T17:30:00",
                        "timeEnd": "2022-02-18T18:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T15:00:00",
                        "timeEnd": "2022-02-19T15:30:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T15:30:00",
                        "timeEnd": "2022-02-19T16:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T16:00:00",
                        "timeEnd": "2022-02-19T16:30:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T16:30:00",
                        "timeEnd": "2022-02-19T17:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T15:00:00",
                        "timeEnd": "2022-02-20T15:30:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T15:30:00",
                        "timeEnd": "2022-02-20T16:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T16:00:00",
                        "timeEnd": "2022-02-20T16:30:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T16:30:00",
                        "timeEnd": "2022-02-20T17:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T14:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:30:00",
                        "timeEnd": "2022-02-21T15:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:00:00",
                        "timeEnd": "2022-02-21T15:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:30:00",
                        "timeEnd": "2022-02-21T16:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:00:00",
                        "timeEnd": "2022-02-21T16:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:30:00",
                        "timeEnd": "2022-02-21T17:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:00:00",
                        "timeEnd": "2022-02-21T17:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:30:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T15:00:00",
                        "timeEnd": "2022-02-26T15:30:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T15:30:00",
                        "timeEnd": "2022-02-26T16:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T16:00:00",
                        "timeEnd": "2022-02-26T16:30:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T16:30:00",
                        "timeEnd": "2022-02-26T17:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T15:00:00",
                        "timeEnd": "2022-02-27T15:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T15:30:00",
                        "timeEnd": "2022-02-27T16:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T16:00:00",
                        "timeEnd": "2022-02-27T16:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T16:30:00",
                        "timeEnd": "2022-02-27T17:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T14:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:30:00",
                        "timeEnd": "2022-02-28T15:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T15:00:00",
                        "timeEnd": "2022-02-28T15:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T15:30:00",
                        "timeEnd": "2022-02-28T16:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T16:00:00",
                        "timeEnd": "2022-02-28T16:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T16:30:00",
                        "timeEnd": "2022-02-28T17:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T17:00:00",
                        "timeEnd": "2022-02-28T17:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T17:30:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T14:00:00",
                        "timeEnd": "2022-02-05T15:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T17:00:00",
                        "timeEnd": "2022-02-05T18:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T14:00:00",
                        "timeEnd": "2022-02-06T15:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T17:00:00",
                        "timeEnd": "2022-02-06T18:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T14:00:00",
                        "timeEnd": "2022-02-12T15:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T17:00:00",
                        "timeEnd": "2022-02-12T18:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T14:00:00",
                        "timeEnd": "2022-02-13T15:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T17:00:00",
                        "timeEnd": "2022-02-13T18:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:00:00",
                        "timeEnd": "2022-02-14T18:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T14:00:00",
                        "timeEnd": "2022-02-19T15:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T17:00:00",
                        "timeEnd": "2022-02-19T18:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T14:00:00",
                        "timeEnd": "2022-02-20T15:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T17:00:00",
                        "timeEnd": "2022-02-20T18:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:00:00",
                        "timeEnd": "2022-02-22T18:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:00:00",
                        "timeEnd": "2022-02-23T18:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:00:00",
                        "timeEnd": "2022-02-24T18:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T14:00:00",
                        "timeEnd": "2022-02-26T15:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T17:00:00",
                        "timeEnd": "2022-02-26T18:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T14:00:00",
                        "timeEnd": "2022-02-27T15:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T17:00:00",
                        "timeEnd": "2022-02-27T18:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:00:00",
                        "timeEnd": "2022-02-04T18:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T15:00:00",
                        "timeEnd": "2022-02-05T17:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T15:00:00",
                        "timeEnd": "2022-02-06T17:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:00:00",
                        "timeEnd": "2022-02-08T18:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T15:00:00",
                        "timeEnd": "2022-02-12T17:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T15:00:00",
                        "timeEnd": "2022-02-13T17:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:00:00",
                        "timeEnd": "2022-02-16T18:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:00:00",
                        "timeEnd": "2022-02-18T18:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T15:00:00",
                        "timeEnd": "2022-02-19T17:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T15:00:00",
                        "timeEnd": "2022-02-20T17:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T15:00:00",
                        "timeEnd": "2022-02-26T17:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T15:00:00",
                        "timeEnd": "2022-02-27T17:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ]
            }
        },
        {
            "refUid": "e7005e6d-65c9-11e9-936d-1856809fe650",
            "specialty": "Дерматология и косметология",
            "name": "Красильникова Наталья Семеновна",
            "clinicUid": "4c68deb4-22c3-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:30:00",
            "durationInSeconds": 1800,
            "timetable": {
                "free": [],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T18:30:00",
                        "timeEnd": "2022-02-04T21:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T18:30:00",
                        "timeEnd": "2022-02-05T21:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T18:30:00",
                        "timeEnd": "2022-02-06T21:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T18:30:00",
                        "timeEnd": "2022-02-09T21:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T18:30:00",
                        "timeEnd": "2022-02-10T21:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T18:30:00",
                        "timeEnd": "2022-02-11T21:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T18:30:00",
                        "timeEnd": "2022-02-12T21:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T18:30:00",
                        "timeEnd": "2022-02-13T21:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T18:30:00",
                        "timeEnd": "2022-02-16T21:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T18:30:00",
                        "timeEnd": "2022-02-17T21:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T18:30:00",
                        "timeEnd": "2022-02-18T21:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T18:30:00",
                        "timeEnd": "2022-02-19T21:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T18:30:00",
                        "timeEnd": "2022-02-20T21:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T18:30:00",
                        "timeEnd": "2022-02-23T21:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T18:30:00",
                        "timeEnd": "2022-02-24T21:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T18:30:00",
                        "timeEnd": "2022-02-25T21:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T18:30:00",
                        "timeEnd": "2022-02-26T21:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T18:30:00",
                        "timeEnd": "2022-02-27T21:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    }
                ],
                "freeNotFormatted": []
            }
        },
        {
            "refUid": "e7005e6d-65c9-11e9-936d-1856809fe650",
            "specialty": "Дерматология и косметология",
            "name": "Красильникова Наталья Семеновна",
            "clinicUid": "66abf7b4-2ff9-11df-8625-002618dcef2c",
            "duration": "0001-01-01T00:30:00",
            "durationInSeconds": 1800,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T09:00:00",
                        "timeEnd": "2022-02-08T09:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T09:30:00",
                        "timeEnd": "2022-02-08T10:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T10:00:00",
                        "timeEnd": "2022-02-08T10:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T10:30:00",
                        "timeEnd": "2022-02-08T11:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T11:00:00",
                        "timeEnd": "2022-02-08T11:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T11:30:00",
                        "timeEnd": "2022-02-08T12:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T12:00:00",
                        "timeEnd": "2022-02-08T12:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T12:30:00",
                        "timeEnd": "2022-02-08T13:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:00:00",
                        "timeEnd": "2022-02-11T09:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:30:00",
                        "timeEnd": "2022-02-11T10:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T10:00:00",
                        "timeEnd": "2022-02-11T10:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T10:30:00",
                        "timeEnd": "2022-02-11T11:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T11:00:00",
                        "timeEnd": "2022-02-11T11:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T11:30:00",
                        "timeEnd": "2022-02-11T12:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T12:00:00",
                        "timeEnd": "2022-02-11T12:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T12:30:00",
                        "timeEnd": "2022-02-11T13:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:00:00",
                        "timeEnd": "2022-02-14T09:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:30:00",
                        "timeEnd": "2022-02-14T10:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T10:00:00",
                        "timeEnd": "2022-02-14T10:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T10:30:00",
                        "timeEnd": "2022-02-14T11:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T11:00:00",
                        "timeEnd": "2022-02-14T11:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T11:30:00",
                        "timeEnd": "2022-02-14T12:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T12:00:00",
                        "timeEnd": "2022-02-14T12:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T12:30:00",
                        "timeEnd": "2022-02-14T13:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T09:00:00",
                        "timeEnd": "2022-02-16T09:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T09:30:00",
                        "timeEnd": "2022-02-16T10:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T10:00:00",
                        "timeEnd": "2022-02-16T10:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T10:30:00",
                        "timeEnd": "2022-02-16T11:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T11:00:00",
                        "timeEnd": "2022-02-16T11:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T11:30:00",
                        "timeEnd": "2022-02-16T12:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T12:00:00",
                        "timeEnd": "2022-02-16T12:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T12:30:00",
                        "timeEnd": "2022-02-16T13:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T09:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:30:00",
                        "timeEnd": "2022-02-21T10:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T10:00:00",
                        "timeEnd": "2022-02-21T10:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T10:30:00",
                        "timeEnd": "2022-02-21T11:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T11:00:00",
                        "timeEnd": "2022-02-21T11:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T11:30:00",
                        "timeEnd": "2022-02-21T12:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T12:00:00",
                        "timeEnd": "2022-02-21T12:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T12:30:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T09:00:00",
                        "timeEnd": "2022-02-25T09:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T09:30:00",
                        "timeEnd": "2022-02-25T10:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T10:00:00",
                        "timeEnd": "2022-02-25T10:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T10:30:00",
                        "timeEnd": "2022-02-25T11:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T11:00:00",
                        "timeEnd": "2022-02-25T11:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T11:30:00",
                        "timeEnd": "2022-02-25T12:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T12:00:00",
                        "timeEnd": "2022-02-25T12:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T12:30:00",
                        "timeEnd": "2022-02-25T13:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T09:00:00",
                        "timeEnd": "2022-02-28T09:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T09:30:00",
                        "timeEnd": "2022-02-28T10:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T10:00:00",
                        "timeEnd": "2022-02-28T10:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T10:30:00",
                        "timeEnd": "2022-02-28T11:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T11:00:00",
                        "timeEnd": "2022-02-28T11:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T11:30:00",
                        "timeEnd": "2022-02-28T12:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T12:00:00",
                        "timeEnd": "2022-02-28T12:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T12:30:00",
                        "timeEnd": "2022-02-28T13:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T09:00:00",
                        "timeEnd": "2022-02-04T13:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T09:00:00",
                        "timeEnd": "2022-02-07T13:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T09:00:00",
                        "timeEnd": "2022-02-10T13:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:00:00",
                        "timeEnd": "2022-02-15T13:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:00:00",
                        "timeEnd": "2022-02-17T13:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T09:00:00",
                        "timeEnd": "2022-02-18T13:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T09:00:00",
                        "timeEnd": "2022-02-22T13:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T09:00:00",
                        "timeEnd": "2022-02-23T13:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T09:00:00",
                        "timeEnd": "2022-02-24T13:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T09:00:00",
                        "timeEnd": "2022-02-08T13:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:00:00",
                        "timeEnd": "2022-02-11T13:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:00:00",
                        "timeEnd": "2022-02-14T13:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T09:00:00",
                        "timeEnd": "2022-02-16T13:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T09:00:00",
                        "timeEnd": "2022-02-25T13:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T09:00:00",
                        "timeEnd": "2022-02-28T13:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ]
            }
        },
        {
            "refUid": "e7005e6d-65c9-11e9-936d-1856809fe650",
            "specialty": "Дерматология и косметология",
            "name": "Красильникова Наталья Семеновна",
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:30:00",
            "durationInSeconds": 1800,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:00:00",
                        "timeEnd": "2022-02-14T14:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:30:00",
                        "timeEnd": "2022-02-14T15:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T15:00:00",
                        "timeEnd": "2022-02-14T15:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T15:30:00",
                        "timeEnd": "2022-02-14T16:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T16:00:00",
                        "timeEnd": "2022-02-14T16:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T16:30:00",
                        "timeEnd": "2022-02-14T17:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T17:00:00",
                        "timeEnd": "2022-02-14T17:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T17:30:00",
                        "timeEnd": "2022-02-14T18:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T14:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:30:00",
                        "timeEnd": "2022-02-17T15:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T15:00:00",
                        "timeEnd": "2022-02-17T15:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T15:30:00",
                        "timeEnd": "2022-02-17T16:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T16:00:00",
                        "timeEnd": "2022-02-17T16:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T16:30:00",
                        "timeEnd": "2022-02-17T17:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T17:00:00",
                        "timeEnd": "2022-02-17T17:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T17:30:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:00:00",
                        "timeEnd": "2022-02-22T14:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:30:00",
                        "timeEnd": "2022-02-22T15:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T15:00:00",
                        "timeEnd": "2022-02-22T15:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T15:30:00",
                        "timeEnd": "2022-02-22T16:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T16:00:00",
                        "timeEnd": "2022-02-22T16:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T16:30:00",
                        "timeEnd": "2022-02-22T17:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T17:00:00",
                        "timeEnd": "2022-02-22T17:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T17:30:00",
                        "timeEnd": "2022-02-22T18:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:00:00",
                        "timeEnd": "2022-02-24T14:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:30:00",
                        "timeEnd": "2022-02-24T15:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T15:00:00",
                        "timeEnd": "2022-02-24T15:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T15:30:00",
                        "timeEnd": "2022-02-24T16:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T16:00:00",
                        "timeEnd": "2022-02-24T16:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T16:30:00",
                        "timeEnd": "2022-02-24T17:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T17:00:00",
                        "timeEnd": "2022-02-24T17:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T17:30:00",
                        "timeEnd": "2022-02-24T18:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T14:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:30:00",
                        "timeEnd": "2022-02-25T15:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T15:00:00",
                        "timeEnd": "2022-02-25T15:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T15:30:00",
                        "timeEnd": "2022-02-25T16:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T16:00:00",
                        "timeEnd": "2022-02-25T16:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T16:30:00",
                        "timeEnd": "2022-02-25T17:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T17:00:00",
                        "timeEnd": "2022-02-25T17:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T17:30:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T14:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:30:00",
                        "timeEnd": "2022-02-28T15:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T15:00:00",
                        "timeEnd": "2022-02-28T15:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T15:30:00",
                        "timeEnd": "2022-02-28T16:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T16:00:00",
                        "timeEnd": "2022-02-28T16:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T16:30:00",
                        "timeEnd": "2022-02-28T17:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T17:00:00",
                        "timeEnd": "2022-02-28T17:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T17:30:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:00:00",
                        "timeEnd": "2022-02-04T18:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:00:00",
                        "timeEnd": "2022-02-08T18:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:00:00",
                        "timeEnd": "2022-02-16T18:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:00:00",
                        "timeEnd": "2022-02-18T18:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:00:00",
                        "timeEnd": "2022-02-23T18:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:00:00",
                        "timeEnd": "2022-02-14T18:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:00:00",
                        "timeEnd": "2022-02-22T18:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:00:00",
                        "timeEnd": "2022-02-24T18:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ]
            }
        },
        {
            "refUid": "d273f6d8-b037-11e8-82b2-40167e631f2e",
            "specialty": "Сурдология",
            "name": "Крылатова Елена Геннадиевна",
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:00:00",
                        "timeEnd": "2022-02-04T14:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:30:00",
                        "timeEnd": "2022-02-04T15:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T15:00:00",
                        "timeEnd": "2022-02-04T15:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T15:30:00",
                        "timeEnd": "2022-02-04T16:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T16:00:00",
                        "timeEnd": "2022-02-04T16:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T16:30:00",
                        "timeEnd": "2022-02-04T17:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T17:00:00",
                        "timeEnd": "2022-02-04T17:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T17:30:00",
                        "timeEnd": "2022-02-04T18:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T14:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:30:00",
                        "timeEnd": "2022-02-07T15:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T15:00:00",
                        "timeEnd": "2022-02-07T15:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T15:30:00",
                        "timeEnd": "2022-02-07T16:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:00:00",
                        "timeEnd": "2022-02-07T16:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:30:00",
                        "timeEnd": "2022-02-07T17:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T17:00:00",
                        "timeEnd": "2022-02-07T17:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T17:30:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:00:00",
                        "timeEnd": "2022-02-08T14:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:30:00",
                        "timeEnd": "2022-02-08T15:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T15:00:00",
                        "timeEnd": "2022-02-08T15:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T15:30:00",
                        "timeEnd": "2022-02-08T16:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T16:00:00",
                        "timeEnd": "2022-02-08T16:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T16:30:00",
                        "timeEnd": "2022-02-08T17:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T17:00:00",
                        "timeEnd": "2022-02-08T17:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T17:30:00",
                        "timeEnd": "2022-02-08T18:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T14:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:30:00",
                        "timeEnd": "2022-02-09T15:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:00:00",
                        "timeEnd": "2022-02-09T15:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:30:00",
                        "timeEnd": "2022-02-09T16:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T16:00:00",
                        "timeEnd": "2022-02-09T16:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T16:30:00",
                        "timeEnd": "2022-02-09T17:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:00:00",
                        "timeEnd": "2022-02-09T17:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:30:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T14:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:30:00",
                        "timeEnd": "2022-02-10T15:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T15:00:00",
                        "timeEnd": "2022-02-10T15:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T15:30:00",
                        "timeEnd": "2022-02-10T16:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T16:00:00",
                        "timeEnd": "2022-02-10T16:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T16:30:00",
                        "timeEnd": "2022-02-10T17:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T17:00:00",
                        "timeEnd": "2022-02-10T17:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T17:30:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T14:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:30:00",
                        "timeEnd": "2022-02-11T15:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T15:00:00",
                        "timeEnd": "2022-02-11T15:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T15:30:00",
                        "timeEnd": "2022-02-11T16:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T16:00:00",
                        "timeEnd": "2022-02-11T16:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T16:30:00",
                        "timeEnd": "2022-02-11T17:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T17:00:00",
                        "timeEnd": "2022-02-11T17:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T17:30:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:00:00",
                        "timeEnd": "2022-02-14T14:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:30:00",
                        "timeEnd": "2022-02-14T15:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T15:00:00",
                        "timeEnd": "2022-02-14T15:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T15:30:00",
                        "timeEnd": "2022-02-14T16:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T16:00:00",
                        "timeEnd": "2022-02-14T16:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T16:30:00",
                        "timeEnd": "2022-02-14T17:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T17:00:00",
                        "timeEnd": "2022-02-14T17:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T17:30:00",
                        "timeEnd": "2022-02-14T18:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T14:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:30:00",
                        "timeEnd": "2022-02-15T15:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T15:00:00",
                        "timeEnd": "2022-02-15T15:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T15:30:00",
                        "timeEnd": "2022-02-15T16:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T16:00:00",
                        "timeEnd": "2022-02-15T16:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T16:30:00",
                        "timeEnd": "2022-02-15T17:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T17:00:00",
                        "timeEnd": "2022-02-15T17:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T17:30:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:00:00",
                        "timeEnd": "2022-02-16T14:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:30:00",
                        "timeEnd": "2022-02-16T15:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T15:00:00",
                        "timeEnd": "2022-02-16T15:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T15:30:00",
                        "timeEnd": "2022-02-16T16:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T16:00:00",
                        "timeEnd": "2022-02-16T16:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T16:30:00",
                        "timeEnd": "2022-02-16T17:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T17:00:00",
                        "timeEnd": "2022-02-16T17:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T17:30:00",
                        "timeEnd": "2022-02-16T18:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T14:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:30:00",
                        "timeEnd": "2022-02-17T15:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T15:00:00",
                        "timeEnd": "2022-02-17T15:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T15:30:00",
                        "timeEnd": "2022-02-17T16:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T16:00:00",
                        "timeEnd": "2022-02-17T16:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T16:30:00",
                        "timeEnd": "2022-02-17T17:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T17:00:00",
                        "timeEnd": "2022-02-17T17:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T17:30:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:00:00",
                        "timeEnd": "2022-02-18T14:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:30:00",
                        "timeEnd": "2022-02-18T15:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T15:00:00",
                        "timeEnd": "2022-02-18T15:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T15:30:00",
                        "timeEnd": "2022-02-18T16:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T16:00:00",
                        "timeEnd": "2022-02-18T16:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T16:30:00",
                        "timeEnd": "2022-02-18T17:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T17:00:00",
                        "timeEnd": "2022-02-18T17:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T17:30:00",
                        "timeEnd": "2022-02-18T18:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T14:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:30:00",
                        "timeEnd": "2022-02-21T15:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:00:00",
                        "timeEnd": "2022-02-21T15:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:30:00",
                        "timeEnd": "2022-02-21T16:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:00:00",
                        "timeEnd": "2022-02-21T16:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:30:00",
                        "timeEnd": "2022-02-21T17:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:00:00",
                        "timeEnd": "2022-02-21T17:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:30:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:00:00",
                        "timeEnd": "2022-02-22T14:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:30:00",
                        "timeEnd": "2022-02-22T15:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T15:00:00",
                        "timeEnd": "2022-02-22T15:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T15:30:00",
                        "timeEnd": "2022-02-22T16:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T16:00:00",
                        "timeEnd": "2022-02-22T16:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T16:30:00",
                        "timeEnd": "2022-02-22T17:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T17:00:00",
                        "timeEnd": "2022-02-22T17:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T17:30:00",
                        "timeEnd": "2022-02-22T18:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:00:00",
                        "timeEnd": "2022-02-23T14:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:30:00",
                        "timeEnd": "2022-02-23T15:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T15:00:00",
                        "timeEnd": "2022-02-23T15:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T15:30:00",
                        "timeEnd": "2022-02-23T16:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T16:00:00",
                        "timeEnd": "2022-02-23T16:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T16:30:00",
                        "timeEnd": "2022-02-23T17:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T17:00:00",
                        "timeEnd": "2022-02-23T17:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T17:30:00",
                        "timeEnd": "2022-02-23T18:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:00:00",
                        "timeEnd": "2022-02-24T14:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:30:00",
                        "timeEnd": "2022-02-24T15:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T15:00:00",
                        "timeEnd": "2022-02-24T15:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T15:30:00",
                        "timeEnd": "2022-02-24T16:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T16:00:00",
                        "timeEnd": "2022-02-24T16:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T16:30:00",
                        "timeEnd": "2022-02-24T17:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T17:00:00",
                        "timeEnd": "2022-02-24T17:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T17:30:00",
                        "timeEnd": "2022-02-24T18:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T14:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:30:00",
                        "timeEnd": "2022-02-25T15:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T15:00:00",
                        "timeEnd": "2022-02-25T15:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T15:30:00",
                        "timeEnd": "2022-02-25T16:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T16:00:00",
                        "timeEnd": "2022-02-25T16:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T16:30:00",
                        "timeEnd": "2022-02-25T17:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T17:00:00",
                        "timeEnd": "2022-02-25T17:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T17:30:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T14:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:30:00",
                        "timeEnd": "2022-02-28T15:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T15:00:00",
                        "timeEnd": "2022-02-28T15:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T15:30:00",
                        "timeEnd": "2022-02-28T16:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T16:00:00",
                        "timeEnd": "2022-02-28T16:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T16:30:00",
                        "timeEnd": "2022-02-28T17:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T17:00:00",
                        "timeEnd": "2022-02-28T17:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T17:30:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "busy": [],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:00:00",
                        "timeEnd": "2022-02-04T18:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:00:00",
                        "timeEnd": "2022-02-08T18:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:00:00",
                        "timeEnd": "2022-02-14T18:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:00:00",
                        "timeEnd": "2022-02-16T18:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:00:00",
                        "timeEnd": "2022-02-18T18:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:00:00",
                        "timeEnd": "2022-02-22T18:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:00:00",
                        "timeEnd": "2022-02-23T18:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:00:00",
                        "timeEnd": "2022-02-24T18:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ]
            }
        },
        {
            "refUid": "e7005e6f-65c9-11e9-936d-1856809fe650",
            "specialty": "Оториноларингология",
            "name": "Малоухов Семен Семенович",
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:00:00",
                        "timeEnd": "2022-02-04T14:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:30:00",
                        "timeEnd": "2022-02-04T15:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T15:00:00",
                        "timeEnd": "2022-02-04T15:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T15:30:00",
                        "timeEnd": "2022-02-04T16:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T16:00:00",
                        "timeEnd": "2022-02-04T16:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T16:30:00",
                        "timeEnd": "2022-02-04T17:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T17:00:00",
                        "timeEnd": "2022-02-04T17:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T17:30:00",
                        "timeEnd": "2022-02-04T18:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T14:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:30:00",
                        "timeEnd": "2022-02-07T15:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T15:00:00",
                        "timeEnd": "2022-02-07T15:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T15:30:00",
                        "timeEnd": "2022-02-07T16:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:00:00",
                        "timeEnd": "2022-02-07T16:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:30:00",
                        "timeEnd": "2022-02-07T17:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T17:00:00",
                        "timeEnd": "2022-02-07T17:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T17:30:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:00:00",
                        "timeEnd": "2022-02-08T14:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:30:00",
                        "timeEnd": "2022-02-08T15:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T15:00:00",
                        "timeEnd": "2022-02-08T15:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T15:30:00",
                        "timeEnd": "2022-02-08T16:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T16:00:00",
                        "timeEnd": "2022-02-08T16:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T16:30:00",
                        "timeEnd": "2022-02-08T17:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T17:00:00",
                        "timeEnd": "2022-02-08T17:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T17:30:00",
                        "timeEnd": "2022-02-08T18:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T14:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:30:00",
                        "timeEnd": "2022-02-09T15:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:00:00",
                        "timeEnd": "2022-02-09T15:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:00:00",
                        "timeEnd": "2022-02-09T17:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:30:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T14:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:30:00",
                        "timeEnd": "2022-02-10T15:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T15:00:00",
                        "timeEnd": "2022-02-10T15:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T15:30:00",
                        "timeEnd": "2022-02-10T16:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T16:00:00",
                        "timeEnd": "2022-02-10T16:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T16:30:00",
                        "timeEnd": "2022-02-10T17:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T17:00:00",
                        "timeEnd": "2022-02-10T17:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T17:30:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T14:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:30:00",
                        "timeEnd": "2022-02-11T15:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T15:00:00",
                        "timeEnd": "2022-02-11T15:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T15:30:00",
                        "timeEnd": "2022-02-11T16:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T16:00:00",
                        "timeEnd": "2022-02-11T16:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T16:30:00",
                        "timeEnd": "2022-02-11T17:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T17:00:00",
                        "timeEnd": "2022-02-11T17:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T17:30:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:00:00",
                        "timeEnd": "2022-02-14T14:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:30:00",
                        "timeEnd": "2022-02-14T15:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T15:00:00",
                        "timeEnd": "2022-02-14T15:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T15:30:00",
                        "timeEnd": "2022-02-14T16:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T16:00:00",
                        "timeEnd": "2022-02-14T16:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T16:30:00",
                        "timeEnd": "2022-02-14T17:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T17:00:00",
                        "timeEnd": "2022-02-14T17:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T17:30:00",
                        "timeEnd": "2022-02-14T18:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T14:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:30:00",
                        "timeEnd": "2022-02-15T15:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T15:00:00",
                        "timeEnd": "2022-02-15T15:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T15:30:00",
                        "timeEnd": "2022-02-15T16:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T16:00:00",
                        "timeEnd": "2022-02-15T16:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T16:30:00",
                        "timeEnd": "2022-02-15T17:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T17:00:00",
                        "timeEnd": "2022-02-15T17:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T17:30:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:00:00",
                        "timeEnd": "2022-02-16T14:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:30:00",
                        "timeEnd": "2022-02-16T15:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T15:00:00",
                        "timeEnd": "2022-02-16T15:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T15:30:00",
                        "timeEnd": "2022-02-16T16:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T16:00:00",
                        "timeEnd": "2022-02-16T16:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T16:30:00",
                        "timeEnd": "2022-02-16T17:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T17:00:00",
                        "timeEnd": "2022-02-16T17:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T17:30:00",
                        "timeEnd": "2022-02-16T18:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T14:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:30:00",
                        "timeEnd": "2022-02-17T15:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T15:00:00",
                        "timeEnd": "2022-02-17T15:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T15:30:00",
                        "timeEnd": "2022-02-17T16:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T16:00:00",
                        "timeEnd": "2022-02-17T16:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T16:30:00",
                        "timeEnd": "2022-02-17T17:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T17:00:00",
                        "timeEnd": "2022-02-17T17:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T17:30:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:00:00",
                        "timeEnd": "2022-02-18T14:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:30:00",
                        "timeEnd": "2022-02-18T15:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T15:00:00",
                        "timeEnd": "2022-02-18T15:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T15:30:00",
                        "timeEnd": "2022-02-18T16:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T16:00:00",
                        "timeEnd": "2022-02-18T16:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T16:30:00",
                        "timeEnd": "2022-02-18T17:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T17:00:00",
                        "timeEnd": "2022-02-18T17:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T17:30:00",
                        "timeEnd": "2022-02-18T18:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T14:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:30:00",
                        "timeEnd": "2022-02-21T15:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:00:00",
                        "timeEnd": "2022-02-21T15:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:30:00",
                        "timeEnd": "2022-02-21T16:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:00:00",
                        "timeEnd": "2022-02-21T16:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:30:00",
                        "timeEnd": "2022-02-21T17:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:00:00",
                        "timeEnd": "2022-02-21T17:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:30:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:00:00",
                        "timeEnd": "2022-02-22T14:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:30:00",
                        "timeEnd": "2022-02-22T15:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T15:00:00",
                        "timeEnd": "2022-02-22T15:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T15:30:00",
                        "timeEnd": "2022-02-22T16:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T16:00:00",
                        "timeEnd": "2022-02-22T16:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T16:30:00",
                        "timeEnd": "2022-02-22T17:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T17:00:00",
                        "timeEnd": "2022-02-22T17:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T17:30:00",
                        "timeEnd": "2022-02-22T18:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:00:00",
                        "timeEnd": "2022-02-23T14:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:30:00",
                        "timeEnd": "2022-02-23T15:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T15:00:00",
                        "timeEnd": "2022-02-23T15:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T15:30:00",
                        "timeEnd": "2022-02-23T16:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T16:00:00",
                        "timeEnd": "2022-02-23T16:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T16:30:00",
                        "timeEnd": "2022-02-23T17:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T17:00:00",
                        "timeEnd": "2022-02-23T17:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T17:30:00",
                        "timeEnd": "2022-02-23T18:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:00:00",
                        "timeEnd": "2022-02-24T14:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:30:00",
                        "timeEnd": "2022-02-24T15:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T15:00:00",
                        "timeEnd": "2022-02-24T15:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T15:30:00",
                        "timeEnd": "2022-02-24T16:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T16:00:00",
                        "timeEnd": "2022-02-24T16:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T16:30:00",
                        "timeEnd": "2022-02-24T17:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T17:00:00",
                        "timeEnd": "2022-02-24T17:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T17:30:00",
                        "timeEnd": "2022-02-24T18:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T14:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:30:00",
                        "timeEnd": "2022-02-25T15:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T15:00:00",
                        "timeEnd": "2022-02-25T15:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T15:30:00",
                        "timeEnd": "2022-02-25T16:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T16:00:00",
                        "timeEnd": "2022-02-25T16:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T16:30:00",
                        "timeEnd": "2022-02-25T17:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T17:00:00",
                        "timeEnd": "2022-02-25T17:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T17:30:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T14:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:30:00",
                        "timeEnd": "2022-02-28T15:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T15:00:00",
                        "timeEnd": "2022-02-28T15:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T15:30:00",
                        "timeEnd": "2022-02-28T16:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T16:00:00",
                        "timeEnd": "2022-02-28T16:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T16:30:00",
                        "timeEnd": "2022-02-28T17:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T17:00:00",
                        "timeEnd": "2022-02-28T17:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T17:30:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:30:00",
                        "timeEnd": "2022-02-09T17:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T14:00:00",
                        "timeEnd": "2022-02-26T14:15:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:15"
                    },
                    {
                        "typeOfTimeUid": "",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T14:45:00",
                        "timeEnd": "2022-02-26T15:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "14:45",
                        "formattedTimeEnd": "15:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:00:00",
                        "timeEnd": "2022-02-04T18:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:00:00",
                        "timeEnd": "2022-02-08T18:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T15:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:00:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:00:00",
                        "timeEnd": "2022-02-14T18:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:00:00",
                        "timeEnd": "2022-02-16T18:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:00:00",
                        "timeEnd": "2022-02-18T18:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:00:00",
                        "timeEnd": "2022-02-22T18:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:00:00",
                        "timeEnd": "2022-02-23T18:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:00:00",
                        "timeEnd": "2022-02-24T18:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ]
            }
        },
        {
            "refUid": "22582080-c3d7-11e9-9380-50af73235947",
            "specialty": "Гинекология",
            "name": "Никонова Елена Павловна",
            "clinicUid": "66abf7b4-2ff9-11df-8625-002618dcef2c",
            "duration": "0001-01-01T00:30:00",
            "durationInSeconds": 1800,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T09:00:00",
                        "timeEnd": "2022-02-06T09:30:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T09:30:00",
                        "timeEnd": "2022-02-06T10:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T10:00:00",
                        "timeEnd": "2022-02-06T10:30:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T10:30:00",
                        "timeEnd": "2022-02-06T11:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T11:00:00",
                        "timeEnd": "2022-02-06T11:30:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T11:30:00",
                        "timeEnd": "2022-02-06T12:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T12:00:00",
                        "timeEnd": "2022-02-06T12:30:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T12:30:00",
                        "timeEnd": "2022-02-06T13:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T09:00:00",
                        "timeEnd": "2022-02-07T09:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T09:30:00",
                        "timeEnd": "2022-02-07T10:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T10:00:00",
                        "timeEnd": "2022-02-07T10:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T10:30:00",
                        "timeEnd": "2022-02-07T11:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T11:00:00",
                        "timeEnd": "2022-02-07T11:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T11:30:00",
                        "timeEnd": "2022-02-07T12:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T12:00:00",
                        "timeEnd": "2022-02-07T12:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T12:30:00",
                        "timeEnd": "2022-02-07T13:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T09:00:00",
                        "timeEnd": "2022-02-08T09:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T09:30:00",
                        "timeEnd": "2022-02-08T10:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T10:00:00",
                        "timeEnd": "2022-02-08T10:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T10:30:00",
                        "timeEnd": "2022-02-08T11:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T11:00:00",
                        "timeEnd": "2022-02-08T11:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T11:30:00",
                        "timeEnd": "2022-02-08T12:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T12:00:00",
                        "timeEnd": "2022-02-08T12:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T12:30:00",
                        "timeEnd": "2022-02-08T13:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T09:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:30:00",
                        "timeEnd": "2022-02-09T10:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T10:00:00",
                        "timeEnd": "2022-02-09T10:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T10:30:00",
                        "timeEnd": "2022-02-09T11:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T11:00:00",
                        "timeEnd": "2022-02-09T11:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T11:30:00",
                        "timeEnd": "2022-02-09T12:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T12:00:00",
                        "timeEnd": "2022-02-09T12:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T12:30:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T09:00:00",
                        "timeEnd": "2022-02-10T09:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T09:30:00",
                        "timeEnd": "2022-02-10T10:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T10:00:00",
                        "timeEnd": "2022-02-10T10:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T10:30:00",
                        "timeEnd": "2022-02-10T11:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T11:00:00",
                        "timeEnd": "2022-02-10T11:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T11:30:00",
                        "timeEnd": "2022-02-10T12:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T12:00:00",
                        "timeEnd": "2022-02-10T12:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T12:30:00",
                        "timeEnd": "2022-02-10T13:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T09:00:00",
                        "timeEnd": "2022-02-13T09:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T09:30:00",
                        "timeEnd": "2022-02-13T10:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T10:00:00",
                        "timeEnd": "2022-02-13T10:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T10:30:00",
                        "timeEnd": "2022-02-13T11:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T11:00:00",
                        "timeEnd": "2022-02-13T11:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T11:30:00",
                        "timeEnd": "2022-02-13T12:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T12:00:00",
                        "timeEnd": "2022-02-13T12:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T12:30:00",
                        "timeEnd": "2022-02-13T13:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:00:00",
                        "timeEnd": "2022-02-14T09:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:30:00",
                        "timeEnd": "2022-02-14T10:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T10:00:00",
                        "timeEnd": "2022-02-14T10:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T10:30:00",
                        "timeEnd": "2022-02-14T11:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T11:00:00",
                        "timeEnd": "2022-02-14T11:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T11:30:00",
                        "timeEnd": "2022-02-14T12:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T12:00:00",
                        "timeEnd": "2022-02-14T12:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T12:30:00",
                        "timeEnd": "2022-02-14T13:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:00:00",
                        "timeEnd": "2022-02-15T09:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:30:00",
                        "timeEnd": "2022-02-15T10:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T10:00:00",
                        "timeEnd": "2022-02-15T10:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T10:30:00",
                        "timeEnd": "2022-02-15T11:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T11:00:00",
                        "timeEnd": "2022-02-15T11:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T11:30:00",
                        "timeEnd": "2022-02-15T12:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T12:00:00",
                        "timeEnd": "2022-02-15T12:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T12:30:00",
                        "timeEnd": "2022-02-15T13:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T09:00:00",
                        "timeEnd": "2022-02-16T09:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T09:30:00",
                        "timeEnd": "2022-02-16T10:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T10:00:00",
                        "timeEnd": "2022-02-16T10:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T10:30:00",
                        "timeEnd": "2022-02-16T11:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T11:00:00",
                        "timeEnd": "2022-02-16T11:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T11:30:00",
                        "timeEnd": "2022-02-16T12:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T12:00:00",
                        "timeEnd": "2022-02-16T12:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T12:30:00",
                        "timeEnd": "2022-02-16T13:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:00:00",
                        "timeEnd": "2022-02-17T09:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:30:00",
                        "timeEnd": "2022-02-17T10:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T10:00:00",
                        "timeEnd": "2022-02-17T10:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T10:30:00",
                        "timeEnd": "2022-02-17T11:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T11:00:00",
                        "timeEnd": "2022-02-17T11:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T11:30:00",
                        "timeEnd": "2022-02-17T12:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T12:00:00",
                        "timeEnd": "2022-02-17T12:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T12:30:00",
                        "timeEnd": "2022-02-17T13:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T09:00:00",
                        "timeEnd": "2022-02-20T09:30:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T09:30:00",
                        "timeEnd": "2022-02-20T10:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T10:00:00",
                        "timeEnd": "2022-02-20T10:30:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T10:30:00",
                        "timeEnd": "2022-02-20T11:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T11:00:00",
                        "timeEnd": "2022-02-20T11:30:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T11:30:00",
                        "timeEnd": "2022-02-20T12:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T12:00:00",
                        "timeEnd": "2022-02-20T12:30:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T12:30:00",
                        "timeEnd": "2022-02-20T13:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T09:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:30:00",
                        "timeEnd": "2022-02-21T10:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T10:00:00",
                        "timeEnd": "2022-02-21T10:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T10:30:00",
                        "timeEnd": "2022-02-21T11:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T11:00:00",
                        "timeEnd": "2022-02-21T11:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T11:30:00",
                        "timeEnd": "2022-02-21T12:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T12:00:00",
                        "timeEnd": "2022-02-21T12:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T12:30:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T09:00:00",
                        "timeEnd": "2022-02-22T09:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T09:30:00",
                        "timeEnd": "2022-02-22T10:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T10:00:00",
                        "timeEnd": "2022-02-22T10:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T10:30:00",
                        "timeEnd": "2022-02-22T11:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T11:00:00",
                        "timeEnd": "2022-02-22T11:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T11:30:00",
                        "timeEnd": "2022-02-22T12:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T12:00:00",
                        "timeEnd": "2022-02-22T12:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T12:30:00",
                        "timeEnd": "2022-02-22T13:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T09:00:00",
                        "timeEnd": "2022-02-23T09:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T09:30:00",
                        "timeEnd": "2022-02-23T10:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T10:00:00",
                        "timeEnd": "2022-02-23T10:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T10:30:00",
                        "timeEnd": "2022-02-23T11:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T11:00:00",
                        "timeEnd": "2022-02-23T11:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T11:30:00",
                        "timeEnd": "2022-02-23T12:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T12:00:00",
                        "timeEnd": "2022-02-23T12:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T12:30:00",
                        "timeEnd": "2022-02-23T13:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T09:00:00",
                        "timeEnd": "2022-02-24T09:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T09:30:00",
                        "timeEnd": "2022-02-24T10:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T10:00:00",
                        "timeEnd": "2022-02-24T10:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T10:30:00",
                        "timeEnd": "2022-02-24T11:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T11:00:00",
                        "timeEnd": "2022-02-24T11:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T11:30:00",
                        "timeEnd": "2022-02-24T12:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T12:00:00",
                        "timeEnd": "2022-02-24T12:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T12:30:00",
                        "timeEnd": "2022-02-24T13:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T09:00:00",
                        "timeEnd": "2022-02-27T09:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T09:30:00",
                        "timeEnd": "2022-02-27T10:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T10:00:00",
                        "timeEnd": "2022-02-27T10:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T10:30:00",
                        "timeEnd": "2022-02-27T11:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T11:00:00",
                        "timeEnd": "2022-02-27T11:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T11:30:00",
                        "timeEnd": "2022-02-27T12:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T12:00:00",
                        "timeEnd": "2022-02-27T12:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T12:30:00",
                        "timeEnd": "2022-02-27T13:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T09:00:00",
                        "timeEnd": "2022-02-28T09:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T09:30:00",
                        "timeEnd": "2022-02-28T10:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T10:00:00",
                        "timeEnd": "2022-02-28T10:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T10:30:00",
                        "timeEnd": "2022-02-28T11:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T11:00:00",
                        "timeEnd": "2022-02-28T11:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T11:30:00",
                        "timeEnd": "2022-02-28T12:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T12:00:00",
                        "timeEnd": "2022-02-28T12:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T12:30:00",
                        "timeEnd": "2022-02-28T13:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "busy": [],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T09:00:00",
                        "timeEnd": "2022-02-06T13:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T09:00:00",
                        "timeEnd": "2022-02-07T13:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T09:00:00",
                        "timeEnd": "2022-02-08T13:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T09:00:00",
                        "timeEnd": "2022-02-10T13:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T09:00:00",
                        "timeEnd": "2022-02-13T13:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:00:00",
                        "timeEnd": "2022-02-14T13:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:00:00",
                        "timeEnd": "2022-02-15T13:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T09:00:00",
                        "timeEnd": "2022-02-16T13:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:00:00",
                        "timeEnd": "2022-02-17T13:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T09:00:00",
                        "timeEnd": "2022-02-20T13:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T09:00:00",
                        "timeEnd": "2022-02-22T13:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T09:00:00",
                        "timeEnd": "2022-02-23T13:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T09:00:00",
                        "timeEnd": "2022-02-24T13:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T09:00:00",
                        "timeEnd": "2022-02-27T13:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T09:00:00",
                        "timeEnd": "2022-02-28T13:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ]
            }
        },
        {
            "refUid": "22582080-c3d7-11e9-9380-50af73235947",
            "specialty": "Гинекология",
            "name": "Никонова Елена Павловна",
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:30:00",
            "durationInSeconds": 1800,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:00:00",
                        "timeEnd": "2022-02-04T14:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:30:00",
                        "timeEnd": "2022-02-04T15:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T15:00:00",
                        "timeEnd": "2022-02-04T15:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T15:30:00",
                        "timeEnd": "2022-02-04T16:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T16:00:00",
                        "timeEnd": "2022-02-04T16:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T16:30:00",
                        "timeEnd": "2022-02-04T17:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T17:00:00",
                        "timeEnd": "2022-02-04T17:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T17:30:00",
                        "timeEnd": "2022-02-04T18:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T14:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:30:00",
                        "timeEnd": "2022-02-07T15:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T15:00:00",
                        "timeEnd": "2022-02-07T15:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T15:30:00",
                        "timeEnd": "2022-02-07T16:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:00:00",
                        "timeEnd": "2022-02-07T16:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:30:00",
                        "timeEnd": "2022-02-07T17:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T17:00:00",
                        "timeEnd": "2022-02-07T17:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T17:30:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:00:00",
                        "timeEnd": "2022-02-08T14:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:30:00",
                        "timeEnd": "2022-02-08T15:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T15:00:00",
                        "timeEnd": "2022-02-08T15:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T15:30:00",
                        "timeEnd": "2022-02-08T16:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T16:00:00",
                        "timeEnd": "2022-02-08T16:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T16:30:00",
                        "timeEnd": "2022-02-08T17:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T17:00:00",
                        "timeEnd": "2022-02-08T17:30:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T17:30:00",
                        "timeEnd": "2022-02-08T18:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T14:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:30:00",
                        "timeEnd": "2022-02-09T15:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:00:00",
                        "timeEnd": "2022-02-09T15:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:30:00",
                        "timeEnd": "2022-02-09T16:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T16:00:00",
                        "timeEnd": "2022-02-09T16:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T16:30:00",
                        "timeEnd": "2022-02-09T17:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:00:00",
                        "timeEnd": "2022-02-09T17:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:30:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T14:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:30:00",
                        "timeEnd": "2022-02-10T15:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T15:00:00",
                        "timeEnd": "2022-02-10T15:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T15:30:00",
                        "timeEnd": "2022-02-10T16:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T16:00:00",
                        "timeEnd": "2022-02-10T16:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T16:30:00",
                        "timeEnd": "2022-02-10T17:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T17:00:00",
                        "timeEnd": "2022-02-10T17:30:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T17:30:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T14:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:30:00",
                        "timeEnd": "2022-02-11T15:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T15:00:00",
                        "timeEnd": "2022-02-11T15:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T15:30:00",
                        "timeEnd": "2022-02-11T16:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T16:00:00",
                        "timeEnd": "2022-02-11T16:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T16:30:00",
                        "timeEnd": "2022-02-11T17:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T17:00:00",
                        "timeEnd": "2022-02-11T17:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T17:30:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:00:00",
                        "timeEnd": "2022-02-14T14:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:30:00",
                        "timeEnd": "2022-02-14T15:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T15:00:00",
                        "timeEnd": "2022-02-14T15:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T15:30:00",
                        "timeEnd": "2022-02-14T16:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T16:00:00",
                        "timeEnd": "2022-02-14T16:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T16:30:00",
                        "timeEnd": "2022-02-14T17:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T17:00:00",
                        "timeEnd": "2022-02-14T17:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T17:30:00",
                        "timeEnd": "2022-02-14T18:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T14:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:30:00",
                        "timeEnd": "2022-02-15T15:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T15:00:00",
                        "timeEnd": "2022-02-15T15:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T15:30:00",
                        "timeEnd": "2022-02-15T16:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T16:00:00",
                        "timeEnd": "2022-02-15T16:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T16:30:00",
                        "timeEnd": "2022-02-15T17:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T17:00:00",
                        "timeEnd": "2022-02-15T17:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T17:30:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:00:00",
                        "timeEnd": "2022-02-16T14:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:30:00",
                        "timeEnd": "2022-02-16T15:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T15:00:00",
                        "timeEnd": "2022-02-16T15:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T15:30:00",
                        "timeEnd": "2022-02-16T16:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T16:00:00",
                        "timeEnd": "2022-02-16T16:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T16:30:00",
                        "timeEnd": "2022-02-16T17:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T17:00:00",
                        "timeEnd": "2022-02-16T17:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T17:30:00",
                        "timeEnd": "2022-02-16T18:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T14:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:30:00",
                        "timeEnd": "2022-02-17T15:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T15:00:00",
                        "timeEnd": "2022-02-17T15:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T15:30:00",
                        "timeEnd": "2022-02-17T16:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T16:00:00",
                        "timeEnd": "2022-02-17T16:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T16:30:00",
                        "timeEnd": "2022-02-17T17:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T17:00:00",
                        "timeEnd": "2022-02-17T17:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T17:30:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:00:00",
                        "timeEnd": "2022-02-18T14:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:30:00",
                        "timeEnd": "2022-02-18T15:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T15:00:00",
                        "timeEnd": "2022-02-18T15:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T15:30:00",
                        "timeEnd": "2022-02-18T16:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T16:00:00",
                        "timeEnd": "2022-02-18T16:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T16:30:00",
                        "timeEnd": "2022-02-18T17:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T17:00:00",
                        "timeEnd": "2022-02-18T17:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T17:30:00",
                        "timeEnd": "2022-02-18T18:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T14:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:30:00",
                        "timeEnd": "2022-02-21T15:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:00:00",
                        "timeEnd": "2022-02-21T15:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:30:00",
                        "timeEnd": "2022-02-21T16:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:00:00",
                        "timeEnd": "2022-02-21T16:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:30:00",
                        "timeEnd": "2022-02-21T17:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:00:00",
                        "timeEnd": "2022-02-21T17:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:30:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:00:00",
                        "timeEnd": "2022-02-22T14:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:30:00",
                        "timeEnd": "2022-02-22T15:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T15:00:00",
                        "timeEnd": "2022-02-22T15:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T15:30:00",
                        "timeEnd": "2022-02-22T16:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T16:00:00",
                        "timeEnd": "2022-02-22T16:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T16:30:00",
                        "timeEnd": "2022-02-22T17:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T17:00:00",
                        "timeEnd": "2022-02-22T17:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T17:30:00",
                        "timeEnd": "2022-02-22T18:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:00:00",
                        "timeEnd": "2022-02-23T14:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:30:00",
                        "timeEnd": "2022-02-23T15:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T15:00:00",
                        "timeEnd": "2022-02-23T15:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T15:30:00",
                        "timeEnd": "2022-02-23T16:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T16:00:00",
                        "timeEnd": "2022-02-23T16:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T16:30:00",
                        "timeEnd": "2022-02-23T17:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T17:00:00",
                        "timeEnd": "2022-02-23T17:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T17:30:00",
                        "timeEnd": "2022-02-23T18:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:00:00",
                        "timeEnd": "2022-02-24T14:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:30:00",
                        "timeEnd": "2022-02-24T15:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T15:00:00",
                        "timeEnd": "2022-02-24T15:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T15:30:00",
                        "timeEnd": "2022-02-24T16:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T16:00:00",
                        "timeEnd": "2022-02-24T16:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T16:30:00",
                        "timeEnd": "2022-02-24T17:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T17:00:00",
                        "timeEnd": "2022-02-24T17:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T17:30:00",
                        "timeEnd": "2022-02-24T18:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T14:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:30:00",
                        "timeEnd": "2022-02-25T15:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T15:00:00",
                        "timeEnd": "2022-02-25T15:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T15:30:00",
                        "timeEnd": "2022-02-25T16:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T16:00:00",
                        "timeEnd": "2022-02-25T16:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T16:30:00",
                        "timeEnd": "2022-02-25T17:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T17:00:00",
                        "timeEnd": "2022-02-25T17:30:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T17:30:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T14:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:30:00",
                        "timeEnd": "2022-02-28T15:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T15:00:00",
                        "timeEnd": "2022-02-28T15:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T15:30:00",
                        "timeEnd": "2022-02-28T16:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T16:00:00",
                        "timeEnd": "2022-02-28T16:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T16:30:00",
                        "timeEnd": "2022-02-28T17:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T17:00:00",
                        "timeEnd": "2022-02-28T17:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T17:30:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "busy": [],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:00:00",
                        "timeEnd": "2022-02-04T18:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:00:00",
                        "timeEnd": "2022-02-08T18:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:00:00",
                        "timeEnd": "2022-02-14T18:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:00:00",
                        "timeEnd": "2022-02-16T18:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:00:00",
                        "timeEnd": "2022-02-18T18:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:00:00",
                        "timeEnd": "2022-02-22T18:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:00:00",
                        "timeEnd": "2022-02-23T18:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:00:00",
                        "timeEnd": "2022-02-24T18:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ]
            }
        },
        {
            "refUid": "eab46ee9-94b1-11e3-87ec-002618dcef2c",
            "specialty": "Кардиология",
            "name": "Сенникова Валентина Семёновна",
            "clinicUid": "4c68deb4-22c3-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T18:30:00",
                        "timeEnd": "2022-02-05T21:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T18:30:00",
                        "timeEnd": "2022-02-07T21:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T18:30:00",
                        "timeEnd": "2022-02-09T21:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T18:30:00",
                        "timeEnd": "2022-02-11T21:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T18:30:00",
                        "timeEnd": "2022-02-13T21:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T18:30:00",
                        "timeEnd": "2022-02-15T21:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T18:30:00",
                        "timeEnd": "2022-02-17T21:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T18:30:00",
                        "timeEnd": "2022-02-19T21:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T18:30:00",
                        "timeEnd": "2022-02-21T21:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T18:30:00",
                        "timeEnd": "2022-02-23T21:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T18:30:00",
                        "timeEnd": "2022-02-25T21:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T18:30:00",
                        "timeEnd": "2022-02-27T21:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    }
                ],
                "freeNotFormatted": []
            }
        },
        {
            "refUid": "eab46ee9-94b1-11e3-87ec-002618dcef2c",
            "specialty": "Кардиология",
            "name": "Сенникова Валентина Семёновна",
            "clinicUid": "66abf7b4-2ff9-11df-8625-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T09:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:30:00",
                        "timeEnd": "2022-02-09T10:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T10:00:00",
                        "timeEnd": "2022-02-09T10:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T10:30:00",
                        "timeEnd": "2022-02-09T11:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T11:00:00",
                        "timeEnd": "2022-02-09T11:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T11:30:00",
                        "timeEnd": "2022-02-09T12:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T12:00:00",
                        "timeEnd": "2022-02-09T12:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T12:30:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T09:00:00",
                        "timeEnd": "2022-02-19T09:30:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T09:30:00",
                        "timeEnd": "2022-02-19T10:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T10:00:00",
                        "timeEnd": "2022-02-19T10:30:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T10:30:00",
                        "timeEnd": "2022-02-19T11:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T11:00:00",
                        "timeEnd": "2022-02-19T11:30:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T11:30:00",
                        "timeEnd": "2022-02-19T12:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T12:00:00",
                        "timeEnd": "2022-02-19T12:30:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T12:30:00",
                        "timeEnd": "2022-02-19T13:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T09:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:30:00",
                        "timeEnd": "2022-02-21T10:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T10:00:00",
                        "timeEnd": "2022-02-21T10:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T10:30:00",
                        "timeEnd": "2022-02-21T11:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T11:00:00",
                        "timeEnd": "2022-02-21T11:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T11:30:00",
                        "timeEnd": "2022-02-21T12:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T12:00:00",
                        "timeEnd": "2022-02-21T12:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T12:30:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T09:00:00",
                        "timeEnd": "2022-02-05T13:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T11:00:00",
                        "timeEnd": "2022-02-05T11:15:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:15"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T09:00:00",
                        "timeEnd": "2022-02-07T13:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:00:00",
                        "timeEnd": "2022-02-11T13:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T09:00:00",
                        "timeEnd": "2022-02-13T13:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:00:00",
                        "timeEnd": "2022-02-15T13:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:00:00",
                        "timeEnd": "2022-02-17T13:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T09:00:00",
                        "timeEnd": "2022-02-23T13:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T09:00:00",
                        "timeEnd": "2022-02-25T13:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T09:00:00",
                        "timeEnd": "2022-02-27T13:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T09:00:00",
                        "timeEnd": "2022-02-19T13:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ]
            }
        },
        {
            "refUid": "eab46ee9-94b1-11e3-87ec-002618dcef2c",
            "specialty": "Кардиология",
            "name": "Сенникова Валентина Семёновна",
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T14:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:30:00",
                        "timeEnd": "2022-02-09T15:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:00:00",
                        "timeEnd": "2022-02-09T15:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:30:00",
                        "timeEnd": "2022-02-09T16:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T16:00:00",
                        "timeEnd": "2022-02-09T16:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T16:30:00",
                        "timeEnd": "2022-02-09T17:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:00:00",
                        "timeEnd": "2022-02-09T17:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:30:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T14:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:30:00",
                        "timeEnd": "2022-02-11T15:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T15:00:00",
                        "timeEnd": "2022-02-11T15:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T15:30:00",
                        "timeEnd": "2022-02-11T16:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T16:00:00",
                        "timeEnd": "2022-02-11T16:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T16:30:00",
                        "timeEnd": "2022-02-11T17:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T17:00:00",
                        "timeEnd": "2022-02-11T17:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T17:30:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T14:00:00",
                        "timeEnd": "2022-02-13T14:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T14:30:00",
                        "timeEnd": "2022-02-13T15:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T15:00:00",
                        "timeEnd": "2022-02-13T15:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T15:30:00",
                        "timeEnd": "2022-02-13T16:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T16:00:00",
                        "timeEnd": "2022-02-13T16:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T16:30:00",
                        "timeEnd": "2022-02-13T17:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T17:00:00",
                        "timeEnd": "2022-02-13T17:30:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T17:30:00",
                        "timeEnd": "2022-02-13T18:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T14:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:30:00",
                        "timeEnd": "2022-02-21T15:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:00:00",
                        "timeEnd": "2022-02-21T15:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:30:00",
                        "timeEnd": "2022-02-21T16:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:00:00",
                        "timeEnd": "2022-02-21T16:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:30:00",
                        "timeEnd": "2022-02-21T17:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:00:00",
                        "timeEnd": "2022-02-21T17:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:30:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T14:00:00",
                        "timeEnd": "2022-02-27T14:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T14:30:00",
                        "timeEnd": "2022-02-27T15:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T15:00:00",
                        "timeEnd": "2022-02-27T15:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T15:30:00",
                        "timeEnd": "2022-02-27T16:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T16:00:00",
                        "timeEnd": "2022-02-27T16:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T16:30:00",
                        "timeEnd": "2022-02-27T17:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T17:00:00",
                        "timeEnd": "2022-02-27T17:30:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T17:30:00",
                        "timeEnd": "2022-02-27T18:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T14:00:00",
                        "timeEnd": "2022-02-05T18:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T14:00:00",
                        "timeEnd": "2022-02-19T18:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:00:00",
                        "timeEnd": "2022-02-23T18:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T14:00:00",
                        "timeEnd": "2022-02-13T18:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T14:00:00",
                        "timeEnd": "2022-02-27T18:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ]
            }
        },
        {
            "refUid": "9e8b672a-9975-11e3-87ec-002618dcef2c",
            "specialty": "Неврология",
            "name": "Скрипко Юлия Александровна",
            "clinicUid": "4c68deb4-22c3-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T18:30:00",
                        "timeEnd": "2022-02-04T21:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-05T00:00:00",
                        "timeBegin": "2022-02-05T18:30:00",
                        "timeEnd": "2022-02-05T21:00:00",
                        "formattedDate": "05-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-06T00:00:00",
                        "timeBegin": "2022-02-06T18:30:00",
                        "timeEnd": "2022-02-06T21:00:00",
                        "formattedDate": "06-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T18:30:00",
                        "timeEnd": "2022-02-09T21:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T18:30:00",
                        "timeEnd": "2022-02-10T21:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T18:30:00",
                        "timeEnd": "2022-02-11T21:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-12T00:00:00",
                        "timeBegin": "2022-02-12T18:30:00",
                        "timeEnd": "2022-02-12T21:00:00",
                        "formattedDate": "12-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-13T00:00:00",
                        "timeBegin": "2022-02-13T18:30:00",
                        "timeEnd": "2022-02-13T21:00:00",
                        "formattedDate": "13-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T18:30:00",
                        "timeEnd": "2022-02-16T21:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T18:30:00",
                        "timeEnd": "2022-02-17T21:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T18:30:00",
                        "timeEnd": "2022-02-18T21:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-19T00:00:00",
                        "timeBegin": "2022-02-19T18:30:00",
                        "timeEnd": "2022-02-19T21:00:00",
                        "formattedDate": "19-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-20T00:00:00",
                        "timeBegin": "2022-02-20T18:30:00",
                        "timeEnd": "2022-02-20T21:00:00",
                        "formattedDate": "20-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T18:30:00",
                        "timeEnd": "2022-02-23T21:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T18:30:00",
                        "timeEnd": "2022-02-24T21:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T18:30:00",
                        "timeEnd": "2022-02-25T21:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-26T00:00:00",
                        "timeBegin": "2022-02-26T18:30:00",
                        "timeEnd": "2022-02-26T21:00:00",
                        "formattedDate": "26-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-27T00:00:00",
                        "timeBegin": "2022-02-27T18:30:00",
                        "timeEnd": "2022-02-27T21:00:00",
                        "formattedDate": "27-02-2022",
                        "formattedTimeBegin": "18:30",
                        "formattedTimeEnd": "21:00"
                    }
                ],
                "freeNotFormatted": []
            }
        },
        {
            "refUid": "9e8b672a-9975-11e3-87ec-002618dcef2c",
            "specialty": "Неврология",
            "name": "Скрипко Юлия Александровна",
            "clinicUid": "66abf7b4-2ff9-11df-8625-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T09:00:00",
                        "timeEnd": "2022-02-04T09:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T09:30:00",
                        "timeEnd": "2022-02-04T10:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T10:00:00",
                        "timeEnd": "2022-02-04T10:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T10:30:00",
                        "timeEnd": "2022-02-04T11:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T11:00:00",
                        "timeEnd": "2022-02-04T11:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T11:30:00",
                        "timeEnd": "2022-02-04T12:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T12:00:00",
                        "timeEnd": "2022-02-04T12:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T12:30:00",
                        "timeEnd": "2022-02-04T13:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T09:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:30:00",
                        "timeEnd": "2022-02-09T10:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T10:00:00",
                        "timeEnd": "2022-02-09T10:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T10:30:00",
                        "timeEnd": "2022-02-09T11:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T11:00:00",
                        "timeEnd": "2022-02-09T11:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T11:30:00",
                        "timeEnd": "2022-02-09T12:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T12:00:00",
                        "timeEnd": "2022-02-09T12:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T12:30:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:00:00",
                        "timeEnd": "2022-02-11T09:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:30:00",
                        "timeEnd": "2022-02-11T10:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T10:00:00",
                        "timeEnd": "2022-02-11T10:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T10:30:00",
                        "timeEnd": "2022-02-11T11:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T11:00:00",
                        "timeEnd": "2022-02-11T11:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T11:30:00",
                        "timeEnd": "2022-02-11T12:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T12:00:00",
                        "timeEnd": "2022-02-11T12:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T12:30:00",
                        "timeEnd": "2022-02-11T13:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:00:00",
                        "timeEnd": "2022-02-14T09:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:30:00",
                        "timeEnd": "2022-02-14T10:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T10:00:00",
                        "timeEnd": "2022-02-14T10:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T10:30:00",
                        "timeEnd": "2022-02-14T11:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T11:00:00",
                        "timeEnd": "2022-02-14T11:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T11:30:00",
                        "timeEnd": "2022-02-14T12:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T12:00:00",
                        "timeEnd": "2022-02-14T12:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T12:30:00",
                        "timeEnd": "2022-02-14T13:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:00:00",
                        "timeEnd": "2022-02-15T09:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:30:00",
                        "timeEnd": "2022-02-15T10:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T10:00:00",
                        "timeEnd": "2022-02-15T10:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T10:30:00",
                        "timeEnd": "2022-02-15T11:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T11:00:00",
                        "timeEnd": "2022-02-15T11:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T11:30:00",
                        "timeEnd": "2022-02-15T12:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T12:00:00",
                        "timeEnd": "2022-02-15T12:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T12:30:00",
                        "timeEnd": "2022-02-15T13:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T09:00:00",
                        "timeEnd": "2022-02-16T09:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T09:30:00",
                        "timeEnd": "2022-02-16T10:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T10:00:00",
                        "timeEnd": "2022-02-16T10:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T10:30:00",
                        "timeEnd": "2022-02-16T11:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T11:00:00",
                        "timeEnd": "2022-02-16T11:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T11:30:00",
                        "timeEnd": "2022-02-16T12:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T12:00:00",
                        "timeEnd": "2022-02-16T12:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T12:30:00",
                        "timeEnd": "2022-02-16T13:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T09:00:00",
                        "timeEnd": "2022-02-07T13:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T09:00:00",
                        "timeEnd": "2022-02-08T13:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T09:00:00",
                        "timeEnd": "2022-02-10T13:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:00:00",
                        "timeEnd": "2022-02-17T13:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T09:00:00",
                        "timeEnd": "2022-02-18T13:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T09:00:00",
                        "timeEnd": "2022-02-22T13:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T09:00:00",
                        "timeEnd": "2022-02-23T13:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T09:00:00",
                        "timeEnd": "2022-02-24T13:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T09:00:00",
                        "timeEnd": "2022-02-25T13:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T09:00:00",
                        "timeEnd": "2022-02-28T13:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T09:00:00",
                        "timeEnd": "2022-02-04T13:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:00:00",
                        "timeEnd": "2022-02-11T13:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:00:00",
                        "timeEnd": "2022-02-14T13:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:00:00",
                        "timeEnd": "2022-02-15T13:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T09:00:00",
                        "timeEnd": "2022-02-16T13:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ]
            }
        },
        {
            "refUid": "9e8b672a-9975-11e3-87ec-002618dcef2c",
            "specialty": "Неврология",
            "name": "Скрипко Юлия Александровна",
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:00:00",
            "durationInSeconds": 0,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:00:00",
                        "timeEnd": "2022-02-04T14:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:30:00",
                        "timeEnd": "2022-02-04T15:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T15:00:00",
                        "timeEnd": "2022-02-04T15:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T15:30:00",
                        "timeEnd": "2022-02-04T16:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T16:00:00",
                        "timeEnd": "2022-02-04T16:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T16:30:00",
                        "timeEnd": "2022-02-04T17:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T17:00:00",
                        "timeEnd": "2022-02-04T17:30:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T17:30:00",
                        "timeEnd": "2022-02-04T18:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T14:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:30:00",
                        "timeEnd": "2022-02-07T15:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T15:00:00",
                        "timeEnd": "2022-02-07T15:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T15:30:00",
                        "timeEnd": "2022-02-07T16:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:00:00",
                        "timeEnd": "2022-02-07T16:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T16:30:00",
                        "timeEnd": "2022-02-07T17:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T17:00:00",
                        "timeEnd": "2022-02-07T17:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T17:30:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T14:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:30:00",
                        "timeEnd": "2022-02-09T15:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:00:00",
                        "timeEnd": "2022-02-09T15:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:30:00",
                        "timeEnd": "2022-02-09T16:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T16:00:00",
                        "timeEnd": "2022-02-09T16:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T16:30:00",
                        "timeEnd": "2022-02-09T17:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:00:00",
                        "timeEnd": "2022-02-09T17:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:30:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:00:00",
                        "timeEnd": "2022-02-14T14:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:30:00",
                        "timeEnd": "2022-02-14T15:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T15:00:00",
                        "timeEnd": "2022-02-14T15:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T15:30:00",
                        "timeEnd": "2022-02-14T16:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T16:00:00",
                        "timeEnd": "2022-02-14T16:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T16:30:00",
                        "timeEnd": "2022-02-14T17:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T17:00:00",
                        "timeEnd": "2022-02-14T17:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T17:30:00",
                        "timeEnd": "2022-02-14T18:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T14:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:30:00",
                        "timeEnd": "2022-02-17T15:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T15:00:00",
                        "timeEnd": "2022-02-17T15:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T15:30:00",
                        "timeEnd": "2022-02-17T16:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T16:00:00",
                        "timeEnd": "2022-02-17T16:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T16:30:00",
                        "timeEnd": "2022-02-17T17:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T17:00:00",
                        "timeEnd": "2022-02-17T17:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T17:30:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T14:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:30:00",
                        "timeEnd": "2022-02-21T15:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:00:00",
                        "timeEnd": "2022-02-21T15:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T15:30:00",
                        "timeEnd": "2022-02-21T16:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:00:00",
                        "timeEnd": "2022-02-21T16:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T16:30:00",
                        "timeEnd": "2022-02-21T17:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:00:00",
                        "timeEnd": "2022-02-21T17:30:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T17:30:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:00:00",
                        "timeEnd": "2022-02-22T14:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:30:00",
                        "timeEnd": "2022-02-22T15:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T15:00:00",
                        "timeEnd": "2022-02-22T15:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T15:30:00",
                        "timeEnd": "2022-02-22T16:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T16:00:00",
                        "timeEnd": "2022-02-22T16:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T16:30:00",
                        "timeEnd": "2022-02-22T17:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T17:00:00",
                        "timeEnd": "2022-02-22T17:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T17:30:00",
                        "timeEnd": "2022-02-22T18:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:00:00",
                        "timeEnd": "2022-02-24T14:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:30:00",
                        "timeEnd": "2022-02-24T15:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T15:00:00",
                        "timeEnd": "2022-02-24T15:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T15:30:00",
                        "timeEnd": "2022-02-24T16:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T16:00:00",
                        "timeEnd": "2022-02-24T16:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T16:30:00",
                        "timeEnd": "2022-02-24T17:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T17:00:00",
                        "timeEnd": "2022-02-24T17:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T17:30:00",
                        "timeEnd": "2022-02-24T18:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:00:00",
                        "timeEnd": "2022-02-08T18:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:00:00",
                        "timeEnd": "2022-02-16T18:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:00:00",
                        "timeEnd": "2022-02-18T18:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:00:00",
                        "timeEnd": "2022-02-23T18:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:00:00",
                        "timeEnd": "2022-02-04T18:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:00:00",
                        "timeEnd": "2022-02-14T18:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:00:00",
                        "timeEnd": "2022-02-22T18:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:00:00",
                        "timeEnd": "2022-02-24T18:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ]
            }
        },
        {
            "refUid": "eab46ee6-94b1-11e3-87ec-002618dcef2c",
            "specialty": "Стоматология",
            "name": "Филатова Мария Сергеевна",
            "clinicUid": "66abf7b4-2ff9-11df-8625-002618dcef2c",
            "duration": "0001-01-01T00:30:00",
            "durationInSeconds": 1800,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T09:00:00",
                        "timeEnd": "2022-02-07T09:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T09:30:00",
                        "timeEnd": "2022-02-07T10:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T10:00:00",
                        "timeEnd": "2022-02-07T10:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T10:30:00",
                        "timeEnd": "2022-02-07T11:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T11:00:00",
                        "timeEnd": "2022-02-07T11:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T11:30:00",
                        "timeEnd": "2022-02-07T12:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T12:00:00",
                        "timeEnd": "2022-02-07T12:30:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T12:30:00",
                        "timeEnd": "2022-02-07T13:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T09:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:30:00",
                        "timeEnd": "2022-02-09T10:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T10:00:00",
                        "timeEnd": "2022-02-09T10:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T10:30:00",
                        "timeEnd": "2022-02-09T11:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T11:00:00",
                        "timeEnd": "2022-02-09T11:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T11:30:00",
                        "timeEnd": "2022-02-09T12:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T12:00:00",
                        "timeEnd": "2022-02-09T12:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T12:30:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:00:00",
                        "timeEnd": "2022-02-11T09:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:30:00",
                        "timeEnd": "2022-02-11T10:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T10:00:00",
                        "timeEnd": "2022-02-11T10:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T10:30:00",
                        "timeEnd": "2022-02-11T11:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T11:00:00",
                        "timeEnd": "2022-02-11T11:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T11:30:00",
                        "timeEnd": "2022-02-11T12:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T12:00:00",
                        "timeEnd": "2022-02-11T12:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T12:30:00",
                        "timeEnd": "2022-02-11T13:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:00:00",
                        "timeEnd": "2022-02-14T09:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:30:00",
                        "timeEnd": "2022-02-14T10:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T10:00:00",
                        "timeEnd": "2022-02-14T10:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T10:30:00",
                        "timeEnd": "2022-02-14T11:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T11:00:00",
                        "timeEnd": "2022-02-14T11:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T11:30:00",
                        "timeEnd": "2022-02-14T12:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T12:00:00",
                        "timeEnd": "2022-02-14T12:30:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T12:30:00",
                        "timeEnd": "2022-02-14T13:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:00:00",
                        "timeEnd": "2022-02-15T09:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:30:00",
                        "timeEnd": "2022-02-15T10:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T10:00:00",
                        "timeEnd": "2022-02-15T10:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T10:30:00",
                        "timeEnd": "2022-02-15T11:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T11:00:00",
                        "timeEnd": "2022-02-15T11:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T11:30:00",
                        "timeEnd": "2022-02-15T12:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T12:00:00",
                        "timeEnd": "2022-02-15T12:30:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T12:30:00",
                        "timeEnd": "2022-02-15T13:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:00:00",
                        "timeEnd": "2022-02-17T09:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:30:00",
                        "timeEnd": "2022-02-17T10:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T10:00:00",
                        "timeEnd": "2022-02-17T10:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T10:30:00",
                        "timeEnd": "2022-02-17T11:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T11:00:00",
                        "timeEnd": "2022-02-17T11:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T11:30:00",
                        "timeEnd": "2022-02-17T12:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T12:00:00",
                        "timeEnd": "2022-02-17T12:30:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T12:30:00",
                        "timeEnd": "2022-02-17T13:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T09:00:00",
                        "timeEnd": "2022-02-18T09:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T09:30:00",
                        "timeEnd": "2022-02-18T10:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T10:00:00",
                        "timeEnd": "2022-02-18T10:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T10:30:00",
                        "timeEnd": "2022-02-18T11:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T11:00:00",
                        "timeEnd": "2022-02-18T11:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T11:30:00",
                        "timeEnd": "2022-02-18T12:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T12:00:00",
                        "timeEnd": "2022-02-18T12:30:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T12:30:00",
                        "timeEnd": "2022-02-18T13:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T09:00:00",
                        "timeEnd": "2022-02-22T09:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "09:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T09:30:00",
                        "timeEnd": "2022-02-22T10:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "09:30",
                        "formattedTimeEnd": "10:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T10:00:00",
                        "timeEnd": "2022-02-22T10:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "10:00",
                        "formattedTimeEnd": "10:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T10:30:00",
                        "timeEnd": "2022-02-22T11:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "10:30",
                        "formattedTimeEnd": "11:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T11:00:00",
                        "timeEnd": "2022-02-22T11:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "11:00",
                        "formattedTimeEnd": "11:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T11:30:00",
                        "timeEnd": "2022-02-22T12:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "11:30",
                        "formattedTimeEnd": "12:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T12:00:00",
                        "timeEnd": "2022-02-22T12:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "12:00",
                        "formattedTimeEnd": "12:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T12:30:00",
                        "timeEnd": "2022-02-22T13:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "12:30",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T09:00:00",
                        "timeEnd": "2022-02-04T13:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T09:00:00",
                        "timeEnd": "2022-02-08T13:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T09:00:00",
                        "timeEnd": "2022-02-10T13:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T09:00:00",
                        "timeEnd": "2022-02-16T13:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T09:00:00",
                        "timeEnd": "2022-02-21T13:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T09:00:00",
                        "timeEnd": "2022-02-23T13:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T09:00:00",
                        "timeEnd": "2022-02-24T13:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T09:00:00",
                        "timeEnd": "2022-02-25T13:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T09:00:00",
                        "timeEnd": "2022-02-28T13:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T09:00:00",
                        "timeEnd": "2022-02-07T13:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T09:00:00",
                        "timeEnd": "2022-02-09T13:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T09:00:00",
                        "timeEnd": "2022-02-11T13:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T09:00:00",
                        "timeEnd": "2022-02-14T13:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T09:00:00",
                        "timeEnd": "2022-02-15T13:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T09:00:00",
                        "timeEnd": "2022-02-17T13:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T09:00:00",
                        "timeEnd": "2022-02-18T13:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T09:00:00",
                        "timeEnd": "2022-02-22T13:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "09:00",
                        "formattedTimeEnd": "13:00"
                    }
                ]
            }
        },
        {
            "refUid": "eab46ee6-94b1-11e3-87ec-002618dcef2c",
            "specialty": "Стоматология",
            "name": "Филатова Мария Сергеевна",
            "clinicUid": "f679444a-22b7-11df-8618-002618dcef2c",
            "duration": "0001-01-01T00:30:00",
            "durationInSeconds": 1800,
            "timetable": {
                "free": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T14:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:30:00",
                        "timeEnd": "2022-02-09T15:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:00:00",
                        "timeEnd": "2022-02-09T15:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T15:30:00",
                        "timeEnd": "2022-02-09T16:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T16:00:00",
                        "timeEnd": "2022-02-09T16:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T16:30:00",
                        "timeEnd": "2022-02-09T17:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:00:00",
                        "timeEnd": "2022-02-09T17:30:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T17:30:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T14:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:30:00",
                        "timeEnd": "2022-02-11T15:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T15:00:00",
                        "timeEnd": "2022-02-11T15:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T15:30:00",
                        "timeEnd": "2022-02-11T16:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T16:00:00",
                        "timeEnd": "2022-02-11T16:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T16:30:00",
                        "timeEnd": "2022-02-11T17:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T17:00:00",
                        "timeEnd": "2022-02-11T17:30:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T17:30:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:00:00",
                        "timeEnd": "2022-02-16T14:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:30:00",
                        "timeEnd": "2022-02-16T15:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T15:00:00",
                        "timeEnd": "2022-02-16T15:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T15:30:00",
                        "timeEnd": "2022-02-16T16:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T16:00:00",
                        "timeEnd": "2022-02-16T16:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T16:30:00",
                        "timeEnd": "2022-02-16T17:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T17:00:00",
                        "timeEnd": "2022-02-16T17:30:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T17:30:00",
                        "timeEnd": "2022-02-16T18:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:00:00",
                        "timeEnd": "2022-02-22T14:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:30:00",
                        "timeEnd": "2022-02-22T15:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T15:00:00",
                        "timeEnd": "2022-02-22T15:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T15:30:00",
                        "timeEnd": "2022-02-22T16:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T16:00:00",
                        "timeEnd": "2022-02-22T16:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T16:30:00",
                        "timeEnd": "2022-02-22T17:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T17:00:00",
                        "timeEnd": "2022-02-22T17:30:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T17:30:00",
                        "timeEnd": "2022-02-22T18:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:00:00",
                        "timeEnd": "2022-02-23T14:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:30:00",
                        "timeEnd": "2022-02-23T15:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T15:00:00",
                        "timeEnd": "2022-02-23T15:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T15:30:00",
                        "timeEnd": "2022-02-23T16:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T16:00:00",
                        "timeEnd": "2022-02-23T16:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T16:30:00",
                        "timeEnd": "2022-02-23T17:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T17:00:00",
                        "timeEnd": "2022-02-23T17:30:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T17:30:00",
                        "timeEnd": "2022-02-23T18:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:00:00",
                        "timeEnd": "2022-02-24T14:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:30:00",
                        "timeEnd": "2022-02-24T15:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T15:00:00",
                        "timeEnd": "2022-02-24T15:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T15:30:00",
                        "timeEnd": "2022-02-24T16:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T16:00:00",
                        "timeEnd": "2022-02-24T16:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T16:30:00",
                        "timeEnd": "2022-02-24T17:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T17:00:00",
                        "timeEnd": "2022-02-24T17:30:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T17:30:00",
                        "timeEnd": "2022-02-24T18:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T14:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "14:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:30:00",
                        "timeEnd": "2022-02-28T15:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:30",
                        "formattedTimeEnd": "15:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T15:00:00",
                        "timeEnd": "2022-02-28T15:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "15:00",
                        "formattedTimeEnd": "15:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T15:30:00",
                        "timeEnd": "2022-02-28T16:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "15:30",
                        "formattedTimeEnd": "16:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T16:00:00",
                        "timeEnd": "2022-02-28T16:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "16:00",
                        "formattedTimeEnd": "16:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T16:30:00",
                        "timeEnd": "2022-02-28T17:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "16:30",
                        "formattedTimeEnd": "17:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T17:00:00",
                        "timeEnd": "2022-02-28T17:30:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "17:00",
                        "formattedTimeEnd": "17:30"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T17:30:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "17:30",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "busy": [
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-04T00:00:00",
                        "timeBegin": "2022-02-04T14:00:00",
                        "timeEnd": "2022-02-04T18:00:00",
                        "formattedDate": "04-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-07T00:00:00",
                        "timeBegin": "2022-02-07T14:00:00",
                        "timeEnd": "2022-02-07T18:00:00",
                        "formattedDate": "07-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-08T00:00:00",
                        "timeBegin": "2022-02-08T14:00:00",
                        "timeEnd": "2022-02-08T18:00:00",
                        "formattedDate": "08-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-10T00:00:00",
                        "timeBegin": "2022-02-10T14:00:00",
                        "timeEnd": "2022-02-10T18:00:00",
                        "formattedDate": "10-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-14T00:00:00",
                        "timeBegin": "2022-02-14T14:00:00",
                        "timeEnd": "2022-02-14T18:00:00",
                        "formattedDate": "14-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-15T00:00:00",
                        "timeBegin": "2022-02-15T14:00:00",
                        "timeEnd": "2022-02-15T18:00:00",
                        "formattedDate": "15-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-17T00:00:00",
                        "timeBegin": "2022-02-17T14:00:00",
                        "timeEnd": "2022-02-17T18:00:00",
                        "formattedDate": "17-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-18T00:00:00",
                        "timeBegin": "2022-02-18T14:00:00",
                        "timeEnd": "2022-02-18T18:00:00",
                        "formattedDate": "18-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-21T00:00:00",
                        "timeBegin": "2022-02-21T14:00:00",
                        "timeEnd": "2022-02-21T18:00:00",
                        "formattedDate": "21-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "624f2a40-5aa8-4f01-83f4-0f38535364bb",
                        "date": "2022-02-25T00:00:00",
                        "timeBegin": "2022-02-25T14:00:00",
                        "timeEnd": "2022-02-25T18:00:00",
                        "formattedDate": "25-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ],
                "freeNotFormatted": [
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-09T00:00:00",
                        "timeBegin": "2022-02-09T14:00:00",
                        "timeEnd": "2022-02-09T18:00:00",
                        "formattedDate": "09-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-11T00:00:00",
                        "timeBegin": "2022-02-11T14:00:00",
                        "timeEnd": "2022-02-11T18:00:00",
                        "formattedDate": "11-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-16T00:00:00",
                        "timeBegin": "2022-02-16T14:00:00",
                        "timeEnd": "2022-02-16T18:00:00",
                        "formattedDate": "16-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-22T00:00:00",
                        "timeBegin": "2022-02-22T14:00:00",
                        "timeEnd": "2022-02-22T18:00:00",
                        "formattedDate": "22-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-23T00:00:00",
                        "timeBegin": "2022-02-23T14:00:00",
                        "timeEnd": "2022-02-23T18:00:00",
                        "formattedDate": "23-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-24T00:00:00",
                        "timeBegin": "2022-02-24T14:00:00",
                        "timeEnd": "2022-02-24T18:00:00",
                        "formattedDate": "24-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    },
                    {
                        "typeOfTimeUid": "56091475-4791-11ec-9bc6-c03eba27318f",
                        "date": "2022-02-28T00:00:00",
                        "timeBegin": "2022-02-28T14:00:00",
                        "timeEnd": "2022-02-28T18:00:00",
                        "formattedDate": "28-02-2022",
                        "formattedTimeBegin": "14:00",
                        "formattedTimeEnd": "18:00"
                    }
                ]
            }
        }
    ]
}

export default demoData;